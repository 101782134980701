import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';



const Notifications = (props) => {
    const navigate = useNavigate();
    const todayNotifications = useSelector(state => state.mainapp.alertSlice?.notifications.today)
    const alltimeNotifications = useSelector(state => state.mainapp.alertSlice?.notifications.alltime)

    useEffect(() => {
        notifications.show({
            title: 'NOTICE: Alert System Unavailable',
            message: 'This feature is currently under development',
            icon: <i style={{backgroundColor: '#F6DE00', color: "black"}} className='material-icons-outlined'>priority_high</i>,
            withCloseButton: false,
            autoClose: 6000,
        })
    } ,[])


    return (
        <>
            <div className='notif-container'>
                <div style={{ paddingBottom: '20px' }} className='alert-strip'>
                    <h3>Notification Center</h3>
                    {/*  <div className='alert-actions'>
                        <button><i className='material-icons'>search</i></button>
                    </div> */}
                </div>

                <div className='notif-feed'>
                    <div className='notif-feed-chunk'>
                        <h4>Today</h4>
                        <div className='n-feed'>

                            {
                                todayNotifications.map((item, index) => {
                                    return (
                                        <>
                                            <div className='n-feed-item vivify popin duration-300'>
                                                <div className='n-feed-item-left'>
                                                    <img className='n-avatar'
                                                        src={"https://api.dicebear.com/7.x/initials/svg?seed=" + item.ticker + "&backgroundColor=" + item.color}
                                                        alt="avatar" />
                                                    <div className='n-feed-item-details'>
                                                        <h3>{item.title} · <span>{item.lastTrigger}</span></h3>
                                                        <p>{item.message}</p>
                                                    </div>
                                                </div>
                                                <div className='n-feed-item-right'>
                                                    <div className='rack-right table-icon'>
                                                        <Menu position='right-start' shadow="md" width={200}>
                                                            <Menu.Target>
                                                                <button><i className='material-icons'>more_horiz</i></button>
                                                            </Menu.Target>
                                                            <Menu.Dropdown>

                                                                <Menu.Label>Actions</Menu.Label>


                                                                <Menu.Item onClick={() =>
                                                                    notifications.show({
                                                                        title: 'Alert Muted',
                                                                        message: 'Your alert has been muted successfully',
                                                                        icon: <i style={{ backgroundColor: 'rgb(0, 187, 212)' }} className='material-icons-outlined'>do_not_disturb_on</i>,
                                                                        withCloseButton: false,
                                                                    })
                                                                } icon={<i className='material-icons colored-icon'>notifications_paused</i>}>Mute Notifications</Menu.Item>


                                                            </Menu.Dropdown>
                                                        </Menu>
                                                    </div>
                                                </div>
                                            </div></>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className='notif-feed-chunk'>
                        <h4>All Time</h4>
                        <div className='n-feed'>
                            {
                                alltimeNotifications.map((item, index) => {
                                    return (
                                        <>
                                            <div className='n-feed-item vivify popin duration-300'>
                                                <div className='n-feed-item-left'>
                                                    <img className='n-avatar'
                                                        src={"https://api.dicebear.com/7.x/initials/svg?seed=" + item.ticker + "&backgroundColor=" + item.color}
                                                        alt="avatar" />
                                                    <div className='n-feed-item-details'>
                                                        <h3>{item.title} · <span>{item.lastTrigger}</span></h3>
                                                        <p>{item.message}</p>
                                                    </div>
                                                </div>
                                                <div className='n-feed-item-right'>
                                                    <div className='rack-right table-icon'>
                                                        <Menu position='right-start' shadow="md" width={200}>
                                                            <Menu.Target>
                                                                <button><i className='material-icons'>more_horiz</i></button>
                                                            </Menu.Target>
                                                            <Menu.Dropdown>
                                                                <Menu.Label>Actions</Menu.Label>


                                                                <Menu.Item icon={<i className='material-icons colored-icon'>notifications_paused</i>}>Mute Notifications</Menu.Item>

                                                            </Menu.Dropdown>
                                                        </Menu>
                                                    </div>
                                                </div>
                                            </div></>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Notifications;