import React, { useRef, useEffect, useState } from 'react';
import { Modal, SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { estimateFeesOpenOptions, estimateFeesOpenStocks, getAccountBalance } from '../../../httpcalls/tastyThunk';
import { calculateMark, calculatePercentageChange } from '../../../helper_functions/stockCalculations';
import { useRefresh } from '../../yourstocks/rack/components/w_refreshcontext';



const E_Stock_Buy_Modal = ({ stockId, modalToggle }) => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const stockData = useSelector((state) => state.mainapp.stockSlice.stocks[stockId].marketData)
    const [shares, setShares] = useState('')
    const [limit, setLimit] = useState('')
    const accountBalance = useSelector(state => state.mainapp.dataSlice.tastyAccountInfo.purchasingPower)
    const {refresh} = useRefresh()

    let bidPrice = stockData?.Quote?.bidPrice || 0
    let askPrice = stockData?.Quote?.askPrice || 0
    let mark = calculateMark(bidPrice, askPrice)
    const defaultValues = {
        shares: 1,
        limit: mark || 0
    }
    let actualLimit = limit == '' ? defaultValues.limit : limit
    let actualShares = shares == '' ? defaultValues.shares : shares


    useEffect(() => {
        dispatch(getAccountBalance())
    }, [])

    const handleForm = (e) => {
        e.preventDefault()

        // currently setup for demo of UX purposes
        if (actualLimit * actualShares  <= parseFloat(accountBalance['equity-buying-power'])) {

            if (actualLimit != 0 && actualShares != 0) {


                setLoading(true)
                setTimeout(() => {
                    dispatch(estimateFeesOpenStocks({ stockId:stockId, quantity: actualShares, price: actualLimit })).then((res) => {
                        if (res.payload == 'success') {
                            setTimeout(() => {
                                modalToggle()
                                refresh()
                                notifications.show({
                                    icon: <i style={{ color: "white" }} className='material-icons'>check</i>,
                                    title: 'Order Placed',
                                    message: 'Your order has been placed. Check the wheel rack for updates.',
                                })


                            }, 2000)
                        }
                        else {
                            setLoading(false)
                        }

                    })
                }, 2000);

            }
            else {

                notifications.show({
                    icon: <i style={{ color: "var(--remove-color)" }} className='material-icons'>priority_high</i>,
                    title: 'Limit Order Invalid',
                    message: 'Order cannot have a $0.00 limit price, or 0 shares.',
                });
            }


        }
        else {

            // @Jacoby make this an error notification
            notifications.show({
                title: 'Buying Power Inssuffiencent',
                message: 'Order cannot be initiated as the account is lacking buying power.',
                icon: <i style={{ color: "var(--remove-color)" }} className='material-icons'>priority_high</i>,
            })
        }

    }

    const colorControl = (stockData?.Trade?.change || 0).toString().includes('-') ? 'red' : 'green'


    return (
        <>
            <Modal centered radius={"lg"} size="auto" withCloseButton={false} title={<div className='modal-header'>
                <button style={
                    {
                        opacity: '0',
                        cursor: 'default'
                    }
                }></button>
                <h3>Place Limit Order: <span>Good For Day</span></h3>
                <button onClick={
                    modalToggle
                }><i className='material-icons'>close</i></button>
            </div>} opened={true} onClose={modalToggle} >
            {
                loading ?
                    <div className='loading-ind'>
                        <div class="indeterminate-progress-bar"><div class="indeterminate-progress-bar__progress"></div></div>
                    </div> :
                    <form onSubmit={
                        (e) => {
                            e.preventDefault()
                            handleForm(e)
                        }
                    }>    <div className='action-form'>
                            <h1>Buy {stockId} </h1>
                            <div className='action-form-inputs'>
                                <div className='action-form-input'>
                                    <div className='action-input-text'>
                                        <label>Shares</label>
                                        <p>
                                            Change <span className={'status-' + colorControl}>
                                                {(stockData?.Trade?.change || 0).toString().includes('-') ? '' : '+'}
                                                {(stockData?.Trade?.change || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' '}

                                                ({
                                                    ((stockData?.Trade?.change || 0).toString().includes('-')
                                                        ? '-' : '+') +

                                                    calculatePercentageChange(stockData?.Trade?.price, stockData?.Trade?.change.toString().includes('-') ? (stockData?.Trade?.price - stockData?.Trade?.change) : (stockData?.Trade?.price + stockData?.Trade?.change)) + '%'
                                                })
                                            </span>
                                         
                                        </p>
                                    </div>
                                    <input type='number' step={'any'} onChange={(e) => { setShares(e.target.value) }} defaultValue={shares || defaultValues.shares} placeholder={defaultValues.shares} />
                                </div>
                                <div className='action-form-input'>
                                <div className='action-input-text'>
                                        <label>Limit Price</label>
                                        <p>Mark {mark.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 }) || "N/A"}</p>
                                        <p style={{ color: "#747474" }}>Bid {bidPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "N/A"} · Ask {askPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "N/A"}</p>
                                    </div>
                                    <input type='number' step={'any'} onChange={(e) => {setLimit(e.target.value) }} defaultValue={limit} placeholder={defaultValues.limit} />
                                </div>
                            </div>
                            <div className='action-form-submit'>
                                <div className='submit-min'>
                                    
                                </div>
                                <button className='lg-btn'>Confirm</button>
                            </div>
                            {accountBalance == null ?
                                <p>Retrieving Buying Power...</p>
                                :
                                <>
                                    <p>{(parseFloat(accountBalance['equity-buying-power']).toLocaleString('en-US', { style: 'currency', currency: 'USD' }))} Equity Buying Power Available</p>

                                    <p style={{ marginTop: "10px" }}>{(actualShares * actualLimit).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} Total Secured</p>
                                </>

                            }

                        </div></form>

            }
        </Modal>
        </>
    );

}

export default E_Stock_Buy_Modal;