import React, { useRef, useEffect, useState } from 'react';
import { Modal, SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { estimateFeesOpenOptions, getAccountBalance } from '../../../httpcalls/tastyThunk';
import { calculateMark } from '../../../helper_functions/stockCalculations';



const E_Option_Buy_Modal = ({optionSymbol, stockSymbol, buySell, callPut, modalToggle }) => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [shares, setShares] = useState('')
    const [limit, setLimit] = useState('')
    const optionData = useSelector(state => state.mainapp.stockSlice.stocks[stockSymbol].stockOptionsMap[optionSymbol])

    let bidPrice = optionData.eventTypes?.Quote?.bidPrice || 0
    let askPrice = optionData.eventTypes?.Quote?.askPrice || 0
    let mark = calculateMark(bidPrice, askPrice)
    const defaultValues = {
        shares: 1,
        limit: mark || 0
    }
    let strikePrice = parseFloat(optionData.strikePrice)
    let tastySymbol = optionData.tastySymbol
    let actualLimit = limit == '' ? defaultValues.limit : limit
    let actualShares = shares == '' ? defaultValues.shares : shares


   
    const accountBalance = useSelector(state => state.mainapp.dataSlice.tastyAccountInfo.purchasingPower)

    useEffect(() => {
        dispatch(getAccountBalance())
    }, [])

    const calculateMinCredit = () => {
        return (actualLimit * actualShares * 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }

    const handleForm = (e) => {
        e.preventDefault()
        if (actualLimit * actualShares * 100 <= parseFloat(accountBalance['derivative-buying-power'])) {

            if (actualLimit != 0 && actualShares != 0) {
                setLoading(true)
                setTimeout(() => {
                    dispatch(estimateFeesOpenOptions({ buySell: buySell, quantity: actualShares, price: actualLimit, symbol: tastySymbol, stockId:stockSymbol, optionsId:optionSymbol })).then((res) => {
                        if (res.payload == 'success') {
                            setTimeout(() => {
                                modalToggle()

                                notifications.show({
                                    icon: <i style={{ color: "white" }} className='material-icons'>check</i>,
                                    title: 'Order Placed',
                                    message: 'Your order has been placed. Check the wheel rack for updates.',
                                })


                            }, 2000)
                        }
                        else {
                            setLoading(false)
                        }

                    })
                }, 2000);

            }
            else {

                notifications.show({
                    icon: <i style={{ color: "var(--remove-color)" }} className='material-icons'>priority_high</i>,
                    title: 'Limit Order Invalid',
                    message: 'Order cannot have a $0.00 limit price, or 0 shares.',
                });
            }


        }
        else {

            // @Jacoby make this an error notification
            notifications.show({
                title: 'Buying Power Inssuffiencent',
                message: 'Order cannot be initiated as the account is lacking buying power.',
                icon: <i style={{ color: "var(--remove-color)" }} className='material-icons'>priority_high</i>,
            })
        }

    }



    return (
        <>
                <Modal centered radius={"lg"} size="auto" withCloseButton={false} title={<div className='modal-header'>
                <button style={
                    {
                        opacity: '0',
                        cursor: 'default'
                    }
                }></button>
                <h3>Place Limit Order: <span>Good For Day</span></h3>
                <button onClick={
                    modalToggle
                }><i className='material-icons'>close</i></button>
            </div>} opened={true} onClose={modalToggle} >
            {
                loading ?
                    <div className='loading-ind'>
                        <div class="indeterminate-progress-bar"><div class="indeterminate-progress-bar__progress"></div></div>
                    </div> :
                    <form onSubmit={
                        (e) => {
                            e.preventDefault()
                            handleForm(e)
                        }
                    }>    <div className='action-form'>
                            <h1><p>{buySell == 'buy' ? 'Buy' : 'Sell'
                            } · {callPut == 'call' ? 'Call' : 'Put'

                                }</p>Limit Order</h1>
                            <div className='action-form-inputs'>
                                <div className='action-form-input'>
                                    <div className='action-input-text'>
                                        <label>Contracts</label>
                                        <p>100 Shares Each</p>
                                    </div>
                                    <input type='number' step={'any'} onChange={(e) => { setShares(e.target.value) }} defaultValue={shares || defaultValues.shares} placeholder={defaultValues.shares} />
                                </div>
                                <div className='action-form-input'>
                                    <div className='action-input-text'>
                                        <label>Limit Price</label>
                                        <p>Mark {mark.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 }) || "N/A"}</p>
                                        <p style={{ color: "#747474" }}>Bid {bidPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "N/A"} · Ask {askPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "N/A"}</p>
                                    </div>
                                    <input type='number' step={'any'} onChange={(e) => {setLimit(e.target.value) }} defaultValue={limit} placeholder={defaultValues.limit} />
                                </div>
                            </div>
                            <div className='action-form-submit'>
                                <div className='submit-min'>
                                    <h3>Min Credit</h3>
                                    <p>{ calculateMinCredit()}</p>
                                </div>
                                <button className='lg-btn'>Confirm</button>
                            </div>
                            {accountBalance == null ?
                                <p>Retrieving Buying Power...</p>
                                :
                                <>
                                    <p>{(parseFloat(accountBalance['derivative-buying-power']).toLocaleString('en-US', { style: 'currency', currency: 'USD' }))} Derivative Buying Power Available</p>

                                    <p style={{ marginTop: "10px" }}>{(strikePrice * 100 * actualShares).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} Total Secured</p>
                                </>

                            }

                        </div></form>

            }
            </Modal>

        </>
    );

}

export default E_Option_Buy_Modal;