export const xirrCalculation = (values, dates) => {
    function generateXirr(transactions) {
        let guess = 0.1;
        const maxIterations = 1000;
        const precision = 1e-7;
        let rate = guess;
      
        function npv(rate) {
          return transactions.reduce(function (accumulator, transaction) {
            const amount = transaction.amount;
            const days = (transaction.date - transactions[0].date) / (24 * 60 * 60 * 1000);
            return accumulator + (amount / Math.pow(1 + rate, days / 365));
          }, 0);
        }
      
        function npvDerivative(rate) {
          return transactions.reduce(function (accumulator, transaction) {
            const amount = transaction.amount;
            const days = (transaction.date - transactions[0].date) / (24 * 60 * 60 * 1000);
            return accumulator - (days / 365) * amount / Math.pow(1 + rate, days / 365 + 1);
          }, 0);
        }
      
        for (let i = 0; i < maxIterations; i++) {
          const fValue = npv(rate);
          const fDerivative = npvDerivative(rate);
          const newRate = rate - fValue / fDerivative;
          if (Math.abs(newRate - rate) <= precision) {
            return newRate;
          }
          rate = newRate;
        }
      
        return 'XIRR did not converge'
      }
      
      const transactions = values.map((amount, i) => ({
        amount,
        date: dates[i]
      }));
      const result = generateXirr(transactions);
      return (result * 100).toFixed(2);
}  