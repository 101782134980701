import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    alertTable: [
        {
            id: 0,
            name: 'Amazon Strike Price Check',
            ticker: 'AMZN',
            lastTrigger: '08/21/2023 11:00 PM CST',
            numberOfTriggers: 1,
            status: 'Active',
            color: 'D82121'
        },
        {
            id: 0,
            name: 'Amazon Strike Price Check',
            ticker: 'AMZN',
            lastTrigger: '08/21/2023 11:00 PM CST',
            numberOfTriggers: 1,
            status: 'Muted',
            color: '2D9379'
        },
        {
            id: 0,
            name: 'Amazon Strike Price Check',
            ticker: 'AMZN',
            lastTrigger: '08/21/2023 11:00 PM CST',
            numberOfTriggers: 1,
            status: 'Muted',
            color: '3B2D93'
        },
        {
            id: 0,
            name: 'Amazon Strike Price Check',
            ticker: 'AMZN',
            lastTrigger: '08/21/2023 11:00 PM CST',
            numberOfTriggers: 1,
            status: 'Active',
            color: '00BBD4'
        },
        {
            id: 0,
            name: 'Amazon Strike Price Check',
            ticker: 'AMZN',
            lastTrigger: '08/21/2023 11:00 PM CST',
            numberOfTriggers: 1,
            status: 'Active',
            color: 'D40099'
        },
    ],
    notifications: {
        today: [
            {
                id: 0,
                title: 'Trigger Occured',
                message: 'Disney Low APR',
                lastTrigger: '7m ago',
                color: '3B2D93',
                ticker: 'DIS',
            },
            {
                id: 0,
                title: 'Trigger Occured',
                message: 'High Microsoft SOP',
                lastTrigger: '7m ago',
                color: '00BBD4',
                ticker: 'AMZN',
            },

        ],
        alltime: [
            {
                id: 0,
                title: 'Trigger Occured',
                message: 'Amazon Strike Price Check',
                lastTrigger: '7m ago',
                color: '2d9379',
                ticker: 'AMZN',
            },
            {
                id: 0,
                title: 'Trigger Occured',
                message: 'Amazon Strike Price Check',
                lastTrigger: '7m ago',
                color: 'd82121',
                ticker: 'AMZN',
            },
            {
                id: 0,
                title: 'Trigger Occured',
                message: 'Amazon Strike Price Check',
                lastTrigger: '7m ago',
                color: 'D40099',
                ticker: 'AMZN',
            }, {
                id: 0,
                title: 'Trigger Occured',
                message: 'Amazon Strike Price Check',
                lastTrigger: '7m ago',
                color: '3B2D93',
                ticker: 'AMZN',
            },
            {
                id: 0,
                title: 'Trigger Occured',
                message: 'Amazon Strike Price Check',
                lastTrigger: '7m ago',
                color: '00BBD4',
                ticker: 'AMZN',
            },
            {
                id: 0,
                title: 'Trigger Occured',
                message: 'Amazon Strike Price Check',
                lastTrigger: '7m ago',
                color: 'D40099',
                ticker: 'AMZN',
            },

        ],
    },
})


const alertSlice = createSlice({
    name: 'Alert Manager',
    initialState: initialState(),
    reducers: {
        resetAlert: state => initialState(),
        updateAlertTable: (state, action) => {
            state.alertTable = action.payload
        },
        updateTodayNotifications: (state, action) => {
            state.notifications.today = action.payload
        },
        updateAllTimeNotifications: (state, action) => {
            state.notifications.alltime = action.payload
        }


    }
})

export const {
    resetAlert,
    updateAlertTable,
    updateTodayNotifications
} = alertSlice.actions


export default alertSlice.reducer