import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Button, Text } from '@mantine/core';
import { Accordion, useMantineTheme, rem } from '@mantine/core';
import { ScrollArea } from '@mantine/core';
import { useSelector } from 'react-redux';
const OrderTracking = () => {
    const orderTracking = useSelector(state => state.mainapp.dataSlice.orderTracking)

    //@Jacoby When there are no orders the menu needs to collapse on start

    return (
        <>
            <div className='order-tracking-dock'>
                <Accordion variant="filled" defaultValue="main-accord">
                    <Accordion.Item value="main-accord">

                        <Accordion.Panel>
                            {
                                orderTracking.length != 0 ? (
                                    <ScrollArea h={145} type="always">
                                        <div className='order-tracking'>
                                            {
                                                orderTracking.map((item, index) => {
                                                    return (
                                                        <div className='order-tracking-item'>
                                                            <div className='order-tracking-item-left'>
                                                                <h3>{item.date}</h3>
                                                                <p>{item.id}</p>
                                                            </div>
                                                            <div className='order-tracking-item-right'>
                                                                {
                                                                    item.status === "Completed" ? <h4>
                                                                        {item.status}
                                                                    </h4> : <h4 className='pending-item'>
                                                                        {item.status}
                                                                    </h4>
                                                                }
                                                                <p>{
                                                                    item.statusTrack ? item.statusTrack[0] + ' of ' + item.statusTrack[1]
                                                                        : ""
                                                                }</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )


                                            }
                                        </div>
                                         </ScrollArea>
                                ) : <p className='no-orders'>No Active Orders</p>
                            }

                        </Accordion.Panel>

                        <Accordion.Control icon={<i className='colored-icon material-icons'>data_saver_off</i>}>
                            Order Tracking
                        </Accordion.Control>


                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );

}

export default OrderTracking;