import { createSlice } from "@reduxjs/toolkit";
/*

    filterLocation: can be {lat:x, long:y} data, or specify the county as a string. This will be managed on the backend.
    filters: [
        current widgets with there respective active filters
        {
            name:"Property Values",
            activeFilters: [
                {
                    name:"face Value",
                    type:"slider",
                    values: {min:0, max: 500}
                }
            ]
        }
    ]

*/



const initialState = () => ({
    widgets: [
        {
            name: "Crypto Market Prices",
            source: "CoinGecko",
            sourceLink: "https://www.coingecko.com/en",
            icon: "https://www.coingecko.com/static/img/coins/64x64/bitcoin.png",
            width: 1,
            height: 2,


        },
    ]
})

const canvasSlice = createSlice({
    name: 'Canvas Manager',
    initialState: initialState(),
    reducers: {
        resetWidgetManger: state => initialState(),

        alterManagedWidgets: (state, action) => {
            state.widgets = action.payload
        },


    }
})

export const {
    resetWidgetManger,
    alterManagedWidgets,
} = canvasSlice.actions

export default canvasSlice.reducer