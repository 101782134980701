import React, { useRef, useEffect, useState, useMemo } from 'react';
import { SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import E_Option_Buy_Modal from './modals/e_option_buy_modal';
import Explore_row from './explore_row';
import { ViewportList } from 'react-viewport-list';
import { Menu } from '@mantine/core';
import { ScrollArea } from '@mantine/core';
import { store } from '../../store/store';
import { ExploreRowProvider, useExploreRowContext } from './explore_row_context';
import E_Buy_Modal from './modals/e_stock_buy_modal';

import { Slider } from '@mantine/core';


import ReactGridLayout from 'react-grid-layout';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useLocalStorage } from 'usehooks-ts';


export     const tableColumns = [
    { columnName: "Strike Price", sizing: "110px" },
    { columnName: "ID #", sizing: "150px" },
    { columnName: "Breakeven", sizing: "110px" },
    { columnName: 'POP', sizing: "110px", buySellCheck: ['sell'] },
    { columnName: 'TBE', sizing: "110px", buySellCheck: ['buy'] },
    { columnName: "% Change", sizing: "110px" },
    { columnName: "Change", sizing: "110px" },
    { columnName: "Intraday Low", sizing: "110px" },
    { columnName: "Intraday High", sizing: "140px" },
    { columnName: "APR at Maturity", sizing: "140px" },
    { columnName: "Mark", sizing: "140px" },
    { columnName: "Volume", sizing: "110px" },
    { columnName: "Volatility", sizing: "110px" },
    { columnName: "Distance", sizing: "110px" },
    { columnName: "All Dates", sizing: "100px", dateCheck: ['All Dates'] }
  ];


const ExploreTable = ({ stockid, buySell, stockView, stockPrice, selectedDate, callPut }) => {

    const [opacity, setOpacity] = useState(localStorage.getItem('opacity') || 0.5)
    const myRef = useRef(null);
    const [counter, setCounter] = useState(0);  // Using counter as a state to force a re-render
    const [hiddenColumns, setHiddenColumns] = useState([
        ...JSON.parse(localStorage.getItem('hiddenColumns') || '[]')
    ])
    const { hasRendered } = useExploreRowContext()
    useEffect(() => {
        localStorage.setItem('hiddenColumns', JSON.stringify(hiddenColumns))
    }, [hiddenColumns])

    useEffect(() => {

        localStorage.setItem('opacity', opacity)
    }, [opacity])



    useEffect(() => {
        const intervalId = setInterval(() => {
            if (counter == 1) {
                setCounter(0);
            }
            else {
                setCounter(1);
            }
        }, 20000);

        return () => clearInterval(intervalId);  // Clean up the interval when the component is unmounted
    }, []);

    const scrollToRef = () => {
        if (myRef.current) {
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

        }
    };




    const ref = useRef(null)

    const binarySearch = (arr, target) => {
        let left = 0;
        let right = arr.length - 1;
        let closestIndex = null;
        let closestDiff = Infinity;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const diff = Math.abs(arr[mid].strikePrice - target);

            if (diff < closestDiff) {
                closestDiff = diff;
                closestIndex = mid;
            }

            if (arr[mid].strikePrice < target) {
                left = mid + 1;
            } else {
                right = mid - 1;
            }
        }

        // Check if left or right boundaries might have closer values to the target.
        if (left < arr.length && Math.abs(arr[left].strikePrice - target) < closestDiff) {
            closestIndex = left;
        } else if (right >= 0 && Math.abs(arr[right].strikePrice - target) < closestDiff) {
            closestIndex = right;
        }

        return closestIndex
    }


    const getStrikePriceRange = (arr, index) => {
        if (index === null || index < 0 || index >= arr.length) {
            return [];
        }

        let leftBoundary = index;
        let rightBoundary = index;

        // Find the left boundary
        while (leftBoundary > 0 && arr[leftBoundary - 1].strikePrice === arr[index].strikePrice) {
            leftBoundary--;
        }

        // Find the right boundary
        while (rightBoundary < arr.length - 1 && arr[rightBoundary + 1].strikePrice === arr[index].strikePrice) {
            rightBoundary++;
        }

        return arr.slice(leftBoundary, rightBoundary + 1);
    }


    const closestItem = useMemo(() => {
        let closestItem;

        const index = binarySearch(stockView, stockPrice)
        const boundary = getStrikePriceRange(stockView, index)

        if (stockPrice <= stockView[index].strikePrice) {
            closestItem = structuredClone(boundary[0])
            closestItem.position = 'top'
        }
        else {
            closestItem = structuredClone(boundary[boundary.length - 1])
            closestItem.position = 'bottom'

        }

        return closestItem
    }, [selectedDate, stockPrice, stockView])





    const [items, setItems] = useLocalStorage('columnLayout', tableColumns.map((col, index) => ({
        id: `item-${index + 1}`,
        content: col.columnName
    })))
    

    let [localStorageCheck, setLocalStorageCheck] = useState(false)

    useEffect(() => {
        if(localStorageCheck == false){
            for(let column of tableColumns){
                if(items.find(item => item.content == column.columnName) == undefined){
                    console.log("Activate")
                    setItems(tableColumns.map((col, index) => ({
                        id: `item-${index + 1}`,
                        content: col.columnName
                    })))
                    return
                }
            }
            console.log("load")
            setLocalStorageCheck(true)
        }
      
    }, [items])
    const [gridLayout, setGridLayout] = useState(null)

    useEffect(() => {
        let gridLayout = ''
        for(let item of items){
            if(!hiddenColumns.includes(item.content) ){
                let tableItemColumn = tableColumns.find(tableCol => tableCol.columnName == item.content)
                if(tableItemColumn != undefined){
                    if(tableItemColumn.dateCheck != undefined){
                        if(selectedDate == 'All Dates'){
                            gridLayout += tableItemColumn.sizing + ' '
                        }
                    }
                    else if(tableItemColumn.buySellCheck != undefined){
                        if(tableItemColumn.buySellCheck.includes(buySell)){
                            gridLayout += tableItemColumn.sizing + ' '
                        }
                    }
                    else{
                        gridLayout += tableItemColumn.sizing + ' '
                    }
                }
            }
            
        }
        setGridLayout(gridLayout)
    
    } ,[items, hiddenColumns, buySell, selectedDate])


  

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);

        setItems(newItems);
    };

    const addItem = () => {
        setItems([...items, { id: `item-${items.length + 1}`, content: `Item ${items.length + 1}` }]);
    };
    return (

        localStorageCheck ? 
        <>

            <div className='table-container'>


                <div className='table-header'>
                    <div className='smart-header'>

                        <Menu position='bottom-start' shadow="md" width={200}>
                            <Menu.Target>
                                <span className='header-action-btn'><button><i style={{ fontSize: "18px" }} className='material-icons'>style</i></button></span>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Inactive Query Opacity</Menu.Label>
                                <div className='slider-menu-container'>
                                    <input defaultValue={
                                        opacity
                                    } style={
                                        {
                                            accentColor: 'var(--main-color)'
                                        }
                                    } type="range" min="0" max="1" step="0.1" onChange={
                                        (e) => {
                                            setOpacity(e.target.value)
                                        }

                                    } />
                                </div>

                                {
                                    hiddenColumns.length > 0 ? <>
                                        <Menu.Label>Add Columns</Menu.Label>
                                        <ScrollArea h={130}>
                                            {
                                                hiddenColumns.map((item, index) => {
                                                    return (
                                                        <Menu.Item onClick={
                                                            () => {
                                                                setHiddenColumns(hiddenColumns.filter((item2) => item2 != item))
                                                            }
                                                        } key={index} >{item}</Menu.Item>
                                                    )
                                                }
                                                )
                                            }
                                        </ScrollArea>
                                    </> : <Menu.Label>No Columns Available To Add</Menu.Label>
                                }


                            </Menu.Dropdown>
                        </Menu>
                       


                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" direction="horizontal">
                                {(provided) => (
                                    <div className='table-row'
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}

                                    >
                                        <span></span>
                                        { 
                                        (() => {
                                            let itemArray = []
                                            items.forEach((item, index) => {

                                                let returnType = false
                                               
                                                
                                                if(item.content != null){
                                                    if(!hiddenColumns.includes(item.content)){
                                                        let tableItemColumn = tableColumns.find(tableCol => tableCol.columnName == item.content)
                                                        if(tableItemColumn.dateCheck != undefined){
                                                            if(selectedDate == 'All Dates'){
                                                                returnType = true
                                                            }
                                                        }
                                                        else if(tableItemColumn.buySellCheck != undefined){
                                                            if(tableItemColumn.buySellCheck.includes(buySell)){
                                                                returnType = true
                                                            }
                                                        }
                                                        else{
                                                            returnType = true
                                                        }
                                                    }
                                                }
                                                    
                                                   
                                                if(returnType == true){
                                                    itemArray.push(
                                                        <Draggable key={item.id} draggableId={item.id} index={itemArray.length}>
                                                        {(provided) => (
                                                            <span
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{ ...provided.draggableProps.style, }}
                                                            >
                                                                
                                                                {
                                                                 <Menu style={
                                                                    {
                                                                        display: hiddenColumns.includes(item.content) ? 'none' : 'flex'
                                                                    }
                                                                } offset={-10} key={index} position='bottom-start' shadow="md" width={200}>
                                                                    <Menu.Target>
                                                                        <span className='vivify fadeIn duration-300 delay-100'> {item.content}</span>
                                                                    </Menu.Target>
                                                                    <Menu.Dropdown>
                                                                        <Menu.Label>Column Options</Menu.Label>
                                                                        <Menu.Item onClick={
                                                                            () => {
                                                                                setHiddenColumns([...hiddenColumns, item.content])
                                                                            }
                                                                        } icon={<i className='material-icons-outlined colored-icon'>visibility_off</i>}>Hide Column</Menu.Item>
                                                                    </Menu.Dropdown>
                                                                </Menu>
                                                                }
                                                            </span>
                                                        )}
                                                    </Draggable>
                                                    )
                                                }
    
                                            })                                                  
                                               
                                                
                                            return itemArray
                                        })()
                                        
                                    }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>



                    </div>
                </div>


                <div className='table-body' ref={ref}>
                    {
                        (selectedDate != null) ?
                            <ViewportList
                                viewportRef={ref}
                                items={stockView}
                                initialPrerender={20}
                            >
                                {(item) => {

                                    return (

                                        <>
                                            {
                                                item.symbol == closestItem.symbol && hasRendered ? (
                                                    (closestItem.position != 'top') ? null
                                                        :
                                                        <>
                                                            <div ref={item.strikePrice == closestItem.strikePrice ? myRef : null} className='stock-bar-line'></div>


                                                            <div className='stock-bar-container vivify popin duration-300 delay-1000'>
                                                                <div className='stock-bar'>
                                                                    <button onClick={scrollToRef} className='stock-pill'>
                                                                        <h3>Share Price: {(stockPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 })}</h3>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </>
                                                ) : null
                                            }



                                            <Explore_row  stockId={stockid} refresh={counter} eventSymbol={item} buySell={buySell} callPut={callPut} stockPrice={stockPrice} selectedDate={selectedDate} hiddenColumns={hiddenColumns} />

                                            {
                                                item.symbol == closestItem.symbol && hasRendered ? (

                                                    (closestItem.position != 'bottom') ? null :
                                                        <>
                                                            <div ref={item.strikePrice == closestItem.strikePrice ? myRef : null} className='stock-bar-line'></div>


                                                            <div className='stock-bar-container vivify popin duration-300 delay-1000'>
                                                                <div className='stock-bar'>
                                                                    <button onClick={scrollToRef} className='stock-pill'>
                                                                        <h3>Share Price: {(stockPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 })}</h3>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>

                                                ) : null
                                            }
                                        </>

                                    )
                                }
                                }
                            </ViewportList>
                            :
                            null


                    }
                </div>
                <style>
                    {`
                    .table-row-inactive > span {
                       filter: opacity(${opacity}) 
                    }

                    .table-row{
                        grid-template-columns: 70px ${gridLayout} !important;    
                    }
                `}
                </style>
            </div>

        </>

        :
        null
    );

}

export default React.memo(ExploreTable);