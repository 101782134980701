import { useEffect, useState } from "react";
import '../App.css';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPasswordSessionGeneration, resetPass } from "../httpcalls/authThunk";

const ResetPassword = () => {
  const [verificationView, setVerificationView] = useState(false)

  const [sentAgain, setSentAgain] = useState(false)
  const dispatch = useDispatch()

  const sendVerification = (e) => {
    const email = e.target.elements['email'].value
    const code = e.target.elements['verifyCode'].value
    dispatch(forgotPasswordSessionGeneration({email:email, code:code}))
 
  }


    return ( 
    
      <>    

        <div className="login-container login-back " >
          <div className="login vivify fadeIn duration-300">
            <div className="login-title vivify fadeIn duration-300">
            <h1>Reset Password</h1>
            <p>Recover your account password</p>
            </div>

            <div className="login-form vivify fadeIn duration-300">
              <form onSubmit={(e) => {e.preventDefault(); sendVerification(e)}}>
                <div className="login-form-input">
                  <label htmlFor="email">Email</label>
                  <input type="email" placeholder="Enter Your Email" name="email" id="email" />
                </div>
                <div className="login-form-input">
                <label htmlFor="verifyCode">Verification Code</label>
                <input type="text" placeholder="Verification Code" name="verifyCode" id="verifyCode" />
              </div>
             

                <div className="verify">
                       <button  id="resend-btn"><i className="material-icons-outlined">email</i>Confirm Password Reset</button>
                </div>

<p>By Clicking “Sign In” You Agree To Our <span>Terms & Conditions</span></p>
              </form>

          </div>

          <div className="login-footer vivify fadeIn duration-300">
            <p>Know Your Password? <Link to={"/signin"}> Sign In</Link></p>
          </div>


        </div>
      </div>
      
      </>
    );
  }
  
  export default ResetPassword;