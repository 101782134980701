import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { getUser } from './httpcalls/authThunk';
import Homepage from './mainapp/homepage';
import MainDirectory from './mainapp/mainDirectory';
import Login from './auth/login';
import Signup from './auth/signup';
import ResetPassword from './auth/resetPassword';
import ConfirmResetPassword from './auth/confirmPasswordReset';
import Verification from './auth/verification';
import Explore from './mainapp/explore/explore';
import Wheel from './mainapp/yourstocks/rack/wheel_grid';
import './css/vivify.min.css'
import ExploreHome from './mainapp/explore/explore_home';
import ExploreWrapper from './mainapp/explore/explore_wrapper';
import Alerts from './mainapp/alerts/alerts_home';
import { RefreshProvider } from './mainapp/yourstocks/rack/components/w_refreshcontext';
import Tracking from './mainapp/yourstocks/tracking/tracking';
import ModalController from './mainapp/user/modalController';
import YourStocks from './mainapp/yourstocks/yourstocks';
import Performance from './mainapp/yourstocks/performance/performance';
import Canvas from './mainapp/canvas/canvas';


const App = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [appLoad, setAppLoad] = useState(true)
  const login = useSelector(state => state.auth.userActive)
  const userInfo = useSelector(state => state.auth.userInfo)
  const location = useLocation()
  window.f_navigate = navigate



  // Remove this line when you're ready to implement the login page
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    dispatch(getUser()).then(res => {
      setAppLoad(false)
      if (userInfo.force_reset_password == true) {
        navigate('/confirmResetPassword', { replace: true })
      }
    })
  }, [])

  useEffect(() => {
    console.log(location)
  }, [location])
  return (


    <>


      {(appLoad) ?
        ""
        :
        <Routes>
          {(login && userInfo.force_reset_password == false || auth) ?
            <>
              <Route path='/' element={<MainDirectory />} >
                <Route path='/' element={<RefreshProvider><Homepage /></RefreshProvider>} >
                  <Route path='/' element={<ExploreHome />} />
                  <Route path='/explore' element={<ExploreHome />} />
                  <Route path='/explore/:stockid' element={<ExploreWrapper />} />

                  <Route path='/notifications' element={<Alerts />} />

                  <Route path='/manager' element={<ModalController />} />

                  <Route path='/canvas' element={<Canvas />} />

                  <Route path='/yourstocks' element={<YourStocks />} >
                    <Route path='/yourstocks/rack' element={<Wheel />} />
                    <Route path='/yourstocks/tracking' element={<Tracking />} />
                    <Route path='/yourstocks' element={<Navigate to="/yourstocks/tracking" replace />} />
                    <Route path='/yourstocks/performance' element={<Performance />} />
                  </Route>
                </Route>

              </Route>

              <Route path='*' element={<Navigate to="/explore" replace />} />
            </>

            :
            <>
              {!login ?
                <>
                  <Route path='/signin' element={<Login />} />
                  <Route path='/reset-password' element={<ResetPassword />} />
                  <Route path='/confirmResetPassword' element={<ConfirmResetPassword />} />
                  <Route path='*' element={<Navigate to="/signin" replace />} />
                </>
                :
                <>
                  <Route path='/confirmResetPassword' element={<ConfirmResetPassword />} />
                  <Route path='*' element={<Navigate to="/confirmResetPassword" replace />} />
                </>

              }

            </>
          }
        </Routes>
      }
    </>



  )
}

export default App;
