import React, { useRef, useEffect, useState } from 'react';
import C_Board from './c_board';
import C_Nav from './c_nav';
import DuneKillerController from '../../components/themes/dunekillerController';

const Canvas = () => {

    return (
        <>
            <DuneKillerController init={false} />

            <C_Board />

        </>
    );

}

export default Canvas;