import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Menu } from '@mantine/core';
import { calculateEstimatedAPRForRack, calculateEstimatedAPRForTable, calculateEstimatedAPRInstanceForRack, calculateMark, truncateToTwoDecimalPlaces } from "../../../../helper_functions/stockCalculations";
import { useDisclosure } from "@mantine/hooks";
import R_Option_Close_Modal from "../modals/r_option_close_modal";
import { calculateTotalBTC } from "../../../../helper_functions/stockCalculations";
import AuthContext from "../../../../components/authContext";

const W_OptionCard = ({orderId, stockId }) => {
    const order = useSelector(state => state.mainapp.stockSlice.stocks[stockId].orderData[orderId])
    const optionData = useSelector(state => state.mainapp.stockSlice.stocks[stockId].stockOptionsMap[order.dxSymbol])
    const [opened, { open, close }] = useDisclosure(false);
    const toggleModal = () => {
        opened ? close() : open();
    };
    // Example: when updating the BTC of this card:
   

    useEffect(() => {
        console.log(order) 
    }
    , [order])

    const calculatedItems = useMemo(() => {
        if(order != undefined && optionData != undefined){
            const placedTime = new Date(order.placedTime).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
            const mark = calculateMark(optionData?.eventTypes?.Quote?.bidPrice || 0, optionData?.eventTypes?.Quote?.askPrice || 0)
            const tastyFee = order.totalFees
            const averagePrice = order.averagePrice == undefined ? parseFloat(order.price) : parseFloat(order.averagePrice)
            let roiNow = (averagePrice * order.quantity * 100 - tastyFee) - (mark * order.quantity * 100)
            let roi = (averagePrice * order.quantity * 100 - tastyFee)
              
            const aprPlaced = calculateEstimatedAPRForRack(averagePrice * order.quantity * 100 - tastyFee, optionData.strikePrice, order.quantity, order.expirationDate, order.placedTime)
           
            const aprNow = calculateEstimatedAPRInstanceForRack(((roi) - (mark * order.quantity * 100)),  order.quantity, optionData.strikePrice, order.placedTime)
          
            const btc = calculateTotalBTC(order.quantity, mark)
          
            return {
                averagePrice:averagePrice,
                aprNow:aprNow,
                aprPlaced:aprPlaced,
                roiNow:roiNow,
                roi:roi,
                mark:mark,
                placedTime:placedTime,
                btc:btc
            }
        }
        else{
            return null
        }
    }, [order, optionData])

    const colorControl = (calculatedItems?.aprNow || 0) > 0 ? 'green' : 'red'
    const colorControlRoi = (calculatedItems?.roiNow || 0) > 0 ? 'green' : 'red'
    return (
        calculatedItems != null ?    
    
        <div className='rack-feed-item'>
            { opened ? <R_Option_Close_Modal  orderId={orderId} stockSymbol={stockId} modalToggle={toggleModal} /> : null}
        <div className='rack-item-header'>
            <div className='rack-item-header-left'>
                <span>Good For Day - {order.action}</span>
                <h3 className={'status-' + colorControl}>{order.strikePrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} {order.expirationDate} {order.callPut} </h3>
                <p>{order.quantity - order.remainingToBeFilled} of {order.quantity} Sold - {calculatedItems.placedTime} at {parseFloat(calculatedItems.averagePrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
            </div>
            <div className='rack-item-header-action'>
            <AuthContext access={['admin', 'trader']}>
                <Menu position='right-start' shadow="md" width={200}>
                    <Menu.Target>
                        <button className='global-more'><i className='material-icons'>more_horiz</i></button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label>Actions</Menu.Label>
                        <Menu.Item onClick={
                            () => {
                                toggleModal()
                            }
                        } icon={<i className='material-icons-outlined colored-icon'>sell</i>}>Close Order</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
                </AuthContext>
            </div>
        </div>

        <div className='rack-item-block-divider'>

        </div>

        <div className='rack-item-block-container'>
            <div className='rack-item-block'>
                <div className='rack-blockling'>
                    <span>Mark: {calculatedItems.mark.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 })}</span>
                    <p>At Expiration:</p>
                    <p>Fee: ${order.totalFees}</p>

                </div>
                <div className='rack-blockling'>
                    <span>ROI: <div className={'status-' + colorControlRoi}>{colorControlRoi == 'green' ? '+' : null}{calculatedItems.roiNow.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div></span>
                    <p>ROI: {calculatedItems.roi.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
                    <p>BTC: {parseFloat(calculatedItems.btc).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
                    

                </div>
                <div className='rack-blockling'>
                    <span>APR: <div className={'status-' + colorControl}>{calculatedItems.aprNow + "%"}</div></span>
                    <p>APR: {calculatedItems.aprPlaced + "%"}</p>
                  
                </div>
            </div>
        </div>

    </div>

    :
    null

    )
}

export default W_OptionCard