import React, { useState, useEffect, useRef } from 'react';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { calculatePercentageChange } from '../../../../helper_functions/stockCalculations';

const W_Cash_Snap_Card = ({stockId, color}) => {
    const accountBalance = useSelector(state => state.mainapp.dataSlice.tastyAccountInfo.purchasingPower)

    return (
        <>
                <div className='widget-list-item-column vivify fadeIn duration-300'>
                    <div className='widget-list-item-column-top'>
                        <h3>Equity</h3>
                        <h4 style={{ color: 'grey', marginLeft:'auto' }}>{(parseFloat(accountBalance['equity-buying-power']).toLocaleString('en-US', { style: 'currency', currency: 'USD' }))}</h4>
                    </div>
                  
                </div>
                <div className='widget-list-item-column vivify fadeIn duration-300'>
                    <div className='widget-list-item-column-top'>
                        <h3>Derivative</h3>
                        <h4 style={{ color: 'grey', marginLeft:'auto' }}>{(parseFloat(accountBalance['derivative-buying-power']).toLocaleString('en-US', { style: 'currency', currency: 'USD' }))}</h4>
                    </div>
                  
                </div>
        </>
    );
};

export default W_Cash_Snap_Card;
