import React, { useState, useEffect, useRef } from 'react';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { calculatePercentageChange } from '../../../../helper_functions/stockCalculations';
import W_RefreshButton from '../../../yourstocks/rack/components/w_refreshbutton';
import { useRefresh } from '../../../yourstocks/rack/components/w_refreshcontext';
import { store } from '../../../../store/store';
import { sendMessage } from '../../../../websocket/websocket';
import { useFetch } from 'usehooks-ts';
import { notifications } from '@mantine/notifications';

import { useLocalStorage } from 'usehooks-ts';
import W_Indices_Card from './w_indices_card';



const W_Indices_Container = ({ id, themeColor, icon, name, removeWidget, featuredStock, custom, actionBtn, canvas, uniqueId, removeCanvasWidget }) => {
    const refreshTimer = useSelector(state => state.mainapp.dataSlice.wheelRack.refresh)
    const { refresh } = useRefresh()
    useEffect(() => {
        getData()
    }, [refreshTimer])


    const permaRemove = () => {
        removeWidget(id);
        localStorage.removeItem(`stockTracking-${uniqueId}`)
    }

    const [stockData, setStockData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const server = process.env.REACT_APP_DOMAIN
    const localStorageKey = `stockTracking-${uniqueId}`;
    const [stockTracking, setStockTracking] = useLocalStorage(localStorageKey, '');


    const getData = async () => {

        try {
            const response = await fetch(`${server}?auth=true&model=widget&path=/getWidgetStocks`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ stockName: (stockTracking.toUpperCase()) })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

            setStockData(results)

        } catch (error) {
            console.log(error)
        }

    }



    useEffect(() => {
        refresh()
    }, [stockTracking])

    const handleEditStock = () => {
        const stockName = window.prompt('Enter Stock Ticker');
        if (stockName && stockName !== '') {
            setStockTracking(stockName);
        }
    };


    return (
        <>
            <div style={{
                gap: "10px",
            }} className='widget vivify fadeIn duration-300 '>

                <div className='widget-title'>
                    <div className='widget-title-left'>

                        <i className='material-icons' style={
                            {
                                color: themeColor || 'var(--main-color)'
                            }
                        }>{icon}</i>

                        {
                            stockData && stockData.stockName ? (
                                <>
                                    <h3>{stockData.stockName}</h3>
                                </>) : <h3>{name}</h3>
                        }
                    </div>
                    <div className='widget-title-right'>
                        <button onClick={() => { refresh() }}><i className='material-icons'>refresh</i></button>
                        <Menu position='top-end' shadow="md" width={200}>
                            <Menu.Target>
                                <button><i className='material-icons'>more_horiz</i></button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>{name} Settings</Menu.Label>
                                <Menu.Item
                                    icon={<i style={
                                        {
                                            color: themeColor || 'var(--main-color)'

                                        }
                                    } className='material-icons'>delete</i>}
                                    onClick={() => {
                                        canvas ? removeCanvasWidget(uniqueId) : permaRemove()
                                    }}
                                >
                                    Remove Widget
                                </Menu.Item>

                                <Menu.Label>Featured</Menu.Label>
                                {
                                    featuredStock.map(stock => (
                                        <Menu.Item

                                            onClick={() => setStockTracking(stock.Symbol)}
                                        >
                                            {stock.Name}
                                        </Menu.Item>
                                    ))
                                }




                                {
                                    custom && custom == false ? (<>
                                        <Menu.Label>Custom Stock</Menu.Label>
                                        <Menu.Item
                                            onClick={handleEditStock} icon={<i style={
                                                {
                                                    color: themeColor || 'var(--main-color)'

                                                }
                                            } className='material-icons'>edit</i>}
                                        >
                                            Custom Stock
                                        </Menu.Item></>

                                    ) : null
                                }

                            </Menu.Dropdown>
                        </Menu>
                    </div>
                </div>
                <div className='widget-contents'>

                    {console.log(stockData)}
                    {
                        stockData ? (
                            stockData.stockName ? (
                                <W_Indices_Card color={themeColor} id={id} name={stockData.stockName == undefined ? 'Name Not Found' : stockData.stockName}
                                    currentPrice={stockData.currentPrice == undefined ? 'N/A' : stockData.currentPrice}
                                    priceChange={stockData.priceChange == undefined ? 'N/A' : stockData.priceChange}
                                    percentChange={stockData.percentChange == undefined ? 'N/A' : stockData.percentChange}
                                />
                            ) : <div className='widget-setup-btn-wrapper'>
                                <Menu position='top-center' shadow="md" offset={20} width={200}>
                                    <Menu.Target>
                                        <button className='widget-setup-btn' ><i style={
                                            {
                                                color: themeColor || 'var(--main-color)'
                                            }
                                        } className='material-icons'>add</i>Add {actionBtn}</button>
                                    </Menu.Target>
                                    <Menu.Dropdown>

                                        <Menu.Label>Featured</Menu.Label>
                                        {
                                            featuredStock.map(stock => (
                                                <Menu.Item

                                                    onClick={() => setStockTracking(stock.Symbol)}
                                                >
                                                    {stock.Name}
                                                </Menu.Item>
                                            ))
                                        }




                                        {
                                            custom && custom == false ? (<>
                                                <Menu.Label>Custom Stock</Menu.Label>
                                                <Menu.Item
                                                    onClick={handleEditStock} icon={<i style={
                                                        {
                                                            color: themeColor || 'var(--main-color)'

                                                        }
                                                    } className='material-icons'>edit</i>}
                                                >
                                                    Custom Stock
                                                </Menu.Item></>

                                            ) : null
                                        }

                                    </Menu.Dropdown>
                                </Menu>

                            </div>
                        ) : <div className='widget-load-container'>
                            <div class="indeterminate-progress-bar"><div style={{ backgroundColor: themeColor, transition: "0.1s ease" }} class="indeterminate-progress-bar__progress"></div></div>
                            <span className='load-subtext' style={{ fontSize: "11px", textAlign: "center" }}>Pulling Data From Yahoo Finance...</span>
                        </div>
                    }

                </div>
                {/*   {
                    stockData && stockData.stockName ? (
                        <>
                            <div className='widget-divider'></div>
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                <W_RefreshButton color={themeColor} style={'immersive'} type={id} />
                            </div>
                        </>

                    ) : null
                } */}
                <style>

                </style>
            </div>
        </>
    );
};

export default W_Indices_Container;
