import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { calculatePercentageChange } from '../../../../helper_functions/stockCalculations';

const W_Single_Card = ({ currentPrice, stockName, stockMarketData }) => {

    const colorControl = (stockMarketData?.Trade?.change || 0).toString().includes('-') ? 'red' : 'var(--main-color)'

    return (
        <>
            <div className='vivify fadeIn duration-300 delay-200'>

                <div className='ltr-widget'>
                    <div>

                        <h1>{(stockMarketData?.Trade?.price || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h1>
                        <p>Updated in real-time</p>
                    </div>

                    <span style={{ color: colorControl }}>
                        {(stockMarketData?.Trade?.change || 0).toString().includes('-') ? '' : '+'}
                        {(stockMarketData?.Trade?.change || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' '}

                        ({
                            ((stockMarketData?.Trade?.change || 0).toString().includes('-')
                                ? '-' : '+') +

                            calculatePercentageChange(stockMarketData?.Trade?.price, stockMarketData?.Trade?.change.toString().includes('-') ? (stockMarketData?.Trade?.price - stockMarketData?.Trade?.change) : (stockMarketData?.Trade?.price + stockMarketData?.Trade?.change)) + '%'
                        })
                    </span>




                </div>

            </div>

        </>
    );
};

export default W_Single_Card
