
import { createAsyncThunk } from '@reduxjs/toolkit'
import { notifications } from '@mantine/notifications';
import { navigate } from '../helper_functions/thunkNavigate';
import { updateStockExpiration } from '../store/schemas/stockSlice';
import { alterMaturity, alterTastyPurchasePower } from '../store/schemas/dataSlice';
import { sendMessage } from '../websocket/websocket';
import { updateTrackingOrders } from '../store/schemas/trackingSlice';
import { Finance } from 'financejs'
import { updateDeposit, updateReport, updateWithdraw } from '../store/schemas/performanceSlice';
import { truncateToTwoDecimalPlaces } from '../helper_functions/stockCalculations';
import { xirrCalculation } from '../helper_functions/reportCalculations';
const server = process.env.REACT_APP_DOMAIN
const model = (process.env.REACT_APP_DOMAIN.includes('localhost') ? 'tasty_dev' : 'tasty')

function formatTimestamp(date) {
    const month = date.toLocaleString('en-US', { month: '2-digit' });
    const day = date.toLocaleString('en-US', { day: '2-digit' });
    const year = date.toLocaleString('en-US', { year: 'numeric' });

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    console.log(hours, minutes, seconds)
    return `${month}/${day}/${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

}

export const getPerformanceData = createAsyncThunk(
    'auth/getPerformanceData',
    async (data, thunkApi) => {
        try {
            let maxDate = data?.maxDate 
            let minDate = data?.minDate 

            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate());
            currentDate.setHours(0, 0, 0, 0);

            if(maxDate == undefined){
                maxDate = currentDate
            }



            const response = await fetch(`${server}?auth=true&model=${model}&path=/getPerformanceData`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({minDate:minDate, maxDate:maxDate})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            let dates = []
            let numbers = []
            let predictedWithdrawal = 0
            
            for(let item of results.message.trade_profits){
                dates.push(new Date(item.date))

                // Data already preformatted with negative and positive
                numbers.push(item.value)
                predictedWithdrawal += item.value
            }


            for(let item of results.message.money_transfers){
                dates.push(new Date(item.date))
                if(item.type == 'Deposit' || item.type == 'Balance Adjustment'){
                    //Deposits and balance Adjustments Data are positive, must be negative
                    numbers.push(item.value * -1)


                    if( item.type == 'Balance Adjustment'){
                        //Balance Adjustment Data positive, must be negative
                        predictedWithdrawal -= item.value
                    }
                    if(item.type == 'Deposit'){
                        //Deposit Data positive, must be postive
                        predictedWithdrawal += item.value
                    }
                }   
                else{
                    //Withdraws are positive, must be negative
                    numbers.push(item.value)
                    predictedWithdrawal -= item.value
                }
            }

            for(let item of results.message.receive_transfers){
                dates.push(new Date(item.date))
                // Receive Transfers are positive, must be negative
                numbers.push(item.value * -1)
                predictedWithdrawal -= item.value
            }


            let maxDateFromData = maxDate ? (new Date(maxDate) > currentDate ? currentDate : new Date(maxDate) ) : currentDate
            numbers.push(predictedWithdrawal)
            dates.push(maxDateFromData)

            console.log(numbers, dates)

            const xirr = xirrCalculation(numbers, dates)

            const oldestDate = new Date(Math.min(...dates));
            thunkApi.dispatch(updateReport([
                {
                    amount: predictedWithdrawal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                    date: `${formatTimestamp(oldestDate)} - ${formatTimestamp(maxDateFromData)}`,
                    description: 'Total Estimated Withdraw'
                },
                {
                    amount: String(truncateToTwoDecimalPlaces(xirr)) == 'NaN' ? 'No Converge' : truncateToTwoDecimalPlaces(xirr)  + '%',
                    date: `${formatTimestamp(oldestDate)} - ${formatTimestamp(maxDateFromData)}`,
                    description: 'Total Portfolio XIRR'
                }
            ]))

            const deposits = results.message.money_transfers.filter((item) => item.type == 'Deposit')
            const withdraws = results.message.money_transfers.filter((item) => item.type != 'Deposit' && item.type != 'Balance Adjustment')
            thunkApi.dispatch(updateDeposit(
                deposits.map((item) => {
                    return {
                        amount: item.value,
                        date: formatTimestamp(new Date(item.date)),
                    }
                }
            )))

            thunkApi.dispatch(updateWithdraw(
                withdraws.map((item) => {
                    return {
                        amount: item.value,
                        date: item.date,
                    }
                }
            )))


            // console.log(results)


            return 'success'
        } catch (error) {
            console.log(error)
        }
    }
  )
