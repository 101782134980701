import React, { useRef, useEffect, useState } from 'react';
import W_RackCard from '../components/w_rackcard';


const R_ViewAll_Modal = ({ stockId, onBtcChange}) => {



    return (
        <>
            {
                <W_RackCard style="immersive" stockId={stockId} onBtcChange={onBtcChange}  />
            }

        </>
    );

}

export default R_ViewAll_Modal;