import React, { useState, useEffect, useRef } from 'react';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { calculatePercentageChange } from '../../../../helper_functions/stockCalculations';
import W_RefreshButton from '../../../yourstocks/rack/components/w_refreshbutton';
import { useRefresh } from '../../../yourstocks/rack/components/w_refreshcontext';
import { store } from '../../../../store/store';
import { sendMessage } from '../../../../websocket/websocket';
import { useFetch } from 'usehooks-ts';
import { notifications } from '@mantine/notifications';

import { useLocalStorage } from 'usehooks-ts';
import W_Cash_Card from './w_cash_card';



const W_Cash_Container = ({ id, themeColor, icon, name, removeWidget, featuredStock, custom, actionBtn, canvas, uniqueId, removeCanvasWidget }) => {
    const refreshTimer = useSelector(state => state.mainapp.dataSlice.wheelRack.refresh)
    const [isCashAvailableLoaded, setIsCashAvailableLoaded] = useState(false);

    const cashAvailable = useSelector(state => {
        // Ensure that every level of the state is checked for null or undefined
        const purchasingPower = state?.mainapp?.dataSlice?.tastyAccountInfo?.purchasingPower;
        // Only attempt to access 'cash-available-to-withdraw' if purchasingPower is not null
        return purchasingPower ? purchasingPower['cash-available-to-withdraw'] : null;
    }) || 0; // Default to 0 if cash-available-to-withdraw is not found


    useEffect(() => {
        if (cashAvailable !== null) {
            setIsCashAvailableLoaded(true);
        }
    }, [cashAvailable]); // Add cashAvailable as a dependency

    // Use useEffect to set isCashAvailableLoaded to true once cashAvailable is loaded
    useEffect(() => {
        if (cashAvailable !== undefined) {
            setIsCashAvailableLoaded(true);
        }
    }, [cashAvailable]);
    const { refresh } = useRefresh()
    useEffect(() => {
        getData()
    }, [refreshTimer])


    const permaRemove = () => {
        removeWidget(id);
        localStorage.removeItem(`stockTracking-${uniqueId}`)
    }

    const [stockData, setStockData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const server = process.env.REACT_APP_DOMAIN
    const localStorageKey = `stockTracking-${uniqueId}`;
    const [stockTracking, setStockTracking] = useLocalStorage(localStorageKey, '');


    const getData = async () => {

        try {
            const response = await fetch(`${server}?auth=true&model=widget&path=/getWidgetStocks`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ stockName: (stockTracking.toUpperCase()) })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

            setStockData(results)

        } catch (error) {
            console.log(error)
        }

    }



    useEffect(() => {
        refresh()
    }, [stockTracking])

    const handleEditStock = () => {
        const stockName = window.prompt('Enter Stock Ticker');
        if (stockName && stockName !== '') {
            setStockTracking(stockName);
        }
    };


    return (
        <>
            <div style={{
                gap: "10px",
            }} className='widget vivify fadeIn duration-300 '>

                <div className='widget-title'>
                    <div className='widget-title-left'>

                        <i className='material-icons' style={
                            {
                                color: themeColor || 'var(--main-color)'
                            }
                        }>{icon}</i>

                        {
                            stockData && stockData.stockName ? (
                                <>
                                    <h3>{stockData.stockName}</h3>
                                </>) : <h3>{name}</h3>
                        }
                    </div>
                    <div className='widget-title-right'>
                        <button onClick={() => { refresh() }}><i className='material-icons'>refresh</i></button>
                        <Menu position='top-end' shadow="md" width={200}>
                            <Menu.Target>
                                <button><i className='material-icons'>more_horiz</i></button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Settings</Menu.Label>
                                <Menu.Item
                                    icon={<i style={
                                        {
                                            color: themeColor || 'var(--main-color)'

                                        }
                                    } className='material-icons'>delete</i>}
                                    onClick={() => {
                                        canvas ? removeCanvasWidget(uniqueId) : permaRemove()
                                    }}
                                >
                                    Remove Widget
                                </Menu.Item>





                            </Menu.Dropdown>
                        </Menu>
                    </div>
                </div>
                <div className='widget-contents'>
                    {
                        isCashAvailableLoaded ? (
                            <W_Cash_Card color={themeColor} id={id} />
                        ) : (
                            <h1> Loading... </h1> // or any other placeholder you wish to show
                        )
                    }
                </div>
                {/*   {
                    stockData && stockData.stockName ? (
                        <>
                            <div className='widget-divider'></div>
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                <W_RefreshButton color={themeColor} style={'immersive'} type={id} />
                            </div>
                        </>

                    ) : null
                } */}
                <style>

                </style>
            </div>
        </>
    );
};

export default W_Cash_Container;
