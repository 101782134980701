import { useSelector } from "react-redux";
import { useRefresh } from "./w_refreshcontext";
import React, { useEffect } from "react";

const W_RefreshButton = ({ color, style, type, }) => {
    const { days, hours, minutes, seconds } = useSelector(state => state.mainapp.dataSlice.wheelRack.time);
    const { refresh } = useRefresh()
    const renderElapsedTime = () => {
        if (days > 0) return `${days} day(s) ago`;
        if (hours > 0) return `${hours} hour(s) ago`;
        if (minutes > 0) return `${minutes} minute(s) ago`;
        return `${seconds} second(s) ago`;
    };

    useEffect(() => {
        if (days >= 0 &&  hours >= 0 && minutes >= 1 && seconds >= 0) {
            refresh(type);
        }
    }, [minutes]);

    return (
        style === 'immersive' ? (
            <div style={{ justifyContent: "flex-start" }} className='activity-bar'>
                <div className='activity-status'>
                    <h3>Last Updated {renderElapsedTime()}
                        <button style={{color: (color || 'var(--main-color)')}} onClick={() => { refresh(type) }}>Refresh</button>
                    </h3>
                </div>
            </div>
        ) : (
            <div className='activity-bar'>
                <div className='left-activity'></div>
                <div className='activity-status'>
                    <h3>Last Updated {renderElapsedTime()}
                        <button style={{color: (color || 'var(--main-color)')}} onClick={() => { refresh(type) }}>Refresh</button>
                    </h3>
                </div>
                <div className='activity-icon-btn'></div>
            </div>
        )
    );
};

export default React.memo(W_RefreshButton);
