import React, { useRef, useEffect, useState } from 'react';
import { Modal, SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { estimateFeesCloseOptions, getAccountBalance } from '../../../../httpcalls/tastyThunk';
import { useRefresh } from '../components/w_refreshcontext';
import { calculateEstimatedAPRForRack, calculateEstimatedAPRInstanceForRack, calculateMark, truncateToTwoDecimalPlaces } from '../../../../helper_functions/stockCalculations';



const R_Option_Close_Modal = ({orderId, stockSymbol, modalToggle }) => {

    const order = useSelector(state => state.mainapp.stockSlice.stocks[stockSymbol].orderData[orderId])
    const optionSymbol = order.dxSymbol
    const sto = order.averagePrice || order.price
    const buySell = order.action
    const quantity = order.quantity
    const tastyFee = order.totalFees

    const optionData = useSelector(state => state.mainapp.stockSlice.stocks[stockSymbol].stockOptionsMap[optionSymbol])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [shares, setShares] = useState('')
    const [limit, setLimit] = useState('')


    let bidPrice = optionData.eventTypes?.Quote?.bidPrice || 0
    let askPrice = optionData.eventTypes?.Quote?.askPrice || 0
    let mark = calculateMark(bidPrice, askPrice)
    const defaultValues = {
        shares: parseInt(quantity),
        limit: parseFloat(mark) || 0
    }
    let strikePrice = parseFloat(optionData.strikePrice)
    let tastySymbol = optionData.tastySymbol
    let actualLimit = limit == '' ? defaultValues.limit : parseFloat(limit)
    let actualShares = shares == '' ? defaultValues.shares : parseInt(shares)
    
    let roiNow = (sto * quantity * 100 - tastyFee) - (actualLimit * actualShares * 100)
    const colorControl = roiNow > 0 ? 'green' : 'red'
    
    const aprChange = calculateEstimatedAPRInstanceForRack(((sto * quantity * 100 - tastyFee) - (actualLimit * actualShares * 100)), optionData.strikePrice, actualShares, order.placedTime)
    const colorControlApr = (aprChange || 0) > 0 ? 'green' : 'red'


    const {refresh} = useRefresh()
    
    const handleForm = (e) => {
        e.preventDefault()
        // currently setup for demo of UX purposes
        if( actualLimit != 0 && actualShares != 0){  
            setLoading(true)
            setTimeout(() => {
                dispatch(estimateFeesCloseOptions({ orderId:orderId, stockId:stockSymbol, optionsId:optionSymbol, buySell:buySell, quantity: actualShares, price:actualLimit, symbol:tastySymbol })).then((res) => {
                    if(res.payload == 'success'){
                        setTimeout(() => {
                            modalToggle()
                            refresh("Rack")
                            notifications.show({
                                icon: <i style={{ color: "white" }} className='material-icons'>check</i>,
                                title: 'Order Placed',
                                message: 'Your order has been placed. Check the wheel rack for updates.',
                            })
                            
            
                        }, 2000)
                    }
                    else{
                        setLoading(false)
                    }
                    
                })
            }, 2000);
            
        }
        else{
            
            notifications.show({
                icon: <i style={{ color: "var(--remove-color)" }} className='material-icons'>priority_high</i>,
                title: 'Limit Order Invalid',
                message: 'Order cannot have a $0.00 limit price, or 0 shares.',
            });
        }
    }

    

    return (
        <>
        
          <Modal centered radius={"lg"} size="auto" withCloseButton={false} title={<div className='modal-header'>
                <button style={
                    {
                        opacity: '0',
                        cursor: 'default'
                    }
                }></button>
                <h3>Place Limit Order: <span>Good For Day</span></h3>
                <button onClick={
                    modalToggle
                }><i className='material-icons'>close</i></button>
            </div>} opened={true} onClose={modalToggle} >
            {
                loading ?
                    <div className='loading-ind'>
                        <div class="indeterminate-progress-bar"><div class="indeterminate-progress-bar__progress"></div></div>
                    </div> :
                    <form onSubmit={
                        (e) => {
                            e.preventDefault()
                            handleForm(e)
                        }
                    }>    <div className='action-form'>
                            <h1><p>{buySell == 'Sell to Open' ? "Buy to Close" : "Sell to Close"} · {order.callPut == 'call' ? 'Call' : 'Put'

                                }</p>Close Option</h1>
                            <div className='action-form-inputs'>
                                <div className='action-form-input'>
                                    <div className='action-input-text'>
                                        <label>Contracts</label>
                                        <p>100 Shares Each</p>
                                    </div>
                                    <input type='number' step={'any'} onChange={(e) => { setShares(e.target.value) }} defaultValue={shares || defaultValues.shares} placeholder={defaultValues.shares} />
                                </div>
                                <div className='action-form-input'>
                                    <div className='action-input-text'>
                                        <label>Limit Price</label>
                                        <p>Mark {mark.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 }) || "N/A"}</p>
                                        
                                    </div>
                                    <input type='number' step={'any'} onChange={(e) => {setLimit(e.target.value) }} defaultValue={limit} placeholder={defaultValues.limit} />
                                    
                                </div>
                                <p className='modal-live-text' style={{color:"#747474", marginTop:'-10px'}}>STO {sto.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "N/A"} · Bid {bidPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "N/A"} · Ask {askPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "N/A"}</p>
                                
                            </div>
                            <div className='action-form-submit'>
                                <div className='submit-min'>
                                    <h3>ROI: <p style={{display:"inline-block", fontWeight:'600'}} className={'status-' + colorControl}>{colorControl == 'green' ? '+' : null}{roiNow.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p></h3>
                                    <h3>APR: <p style={{display:"inline-block", fontWeight:'600'}} className={'status-' + colorControlApr}>{aprChange + "%"}</p></h3>
                                </div>
                                <button  className='lg-btn'>Confirm</button>
                            </div>
                            
                                <p>Projected Buying Power Increase: {(actualShares * strikePrice * 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }</p>
                               
                                
                            
                            
                        </div></form>

            }
            </Modal>
        </>
    );

}

export default R_Option_Close_Modal;