import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    nav:null
})


const helperFunctionsSlice = createSlice({
    name:'helperFunctionsSlice',
    initialState:initialState(),
    reducers: {
        resetHelperFunction: state => initialState(),
        alterNavigate:(state, action)=>{   
         
            state.nav = {url:action.payload.url, replace: ("replace" in action.payload ? true : false)}
        }
    }
})

export const {resetHelperFunction, alterNavigate} = helperFunctionsSlice.actions

export default helperFunctionsSlice.reducer