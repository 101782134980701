import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    userTable: [
     {
        email: 'jacoby@eldrbreak.com',
        uid: '6b1642f0-4329-4635-a3e1-60c4793e0bbb',
        permissions: ['admin', 'trader', 'auditor'],
        exchange: 'tasty',
        operating: 'cash'
     },
     {
        email: 'damiono32@yahoo.com',
        uid: '6b1642f0-4329-4635-a3e1-60c4793e0bbb',
        permissions: ['trader', 'auditor'],
        exchange: 'tasty',
        operating: 'margin'
     }
    ],

})


const managerSlice = createSlice({
    name: 'Manager Manager LOL',
    initialState: initialState(),
    reducers: {
        resetUserTable: state => initialState(),
        updateUsers: (state, action) => {
            state.userTable = action.payload
        }
    }
})

export const {
    resetUserTable,
    updateUsers
} = managerSlice.actions


export default managerSlice.reducer