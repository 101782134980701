import React, { useRef, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const YourStocks = () => {

    const [currentNav, setCurrentNav] = useState('rack')

    return (
        <>
            <div>
                <nav resizable className='side-navbar horizontal-navbar'>

                    <div className='side-navbar-sub horizonal-nav-sub'>

                        <div className='side-nav-btns'>
                            <div className='nav-btn-items horizontal-navs'>
                                <NavLink to="/yourstocks/tracking" className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>
                                    <button className='nav-btn hori-nav-btn'><i className='material-icons'>published_with_changes</i><span>Order Tracking<p>Under Development</p></span></button>
                                </NavLink>

                                <NavLink to="/yourstocks/rack" className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>
                                    <button className='nav-btn hori-nav-btn'><i className='material-icons'>track_changes</i><span>Wheel Rack<p>Under Development</p></span></button>
                                </NavLink>

                           <NavLink to="/yourstocks/performance" className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>
                                    <button className='nav-btn hori-nav-btn'><i className='material-icons'>insights</i><span>Performance<p >Under Development</p></span></button>
                                </NavLink> 

                            </div>

                        </div>
                    </div>

                </nav>

                <Outlet />
            </div>
        </>
    );

}

export default YourStocks;