import React, { useRef, useEffect, useState } from 'react';
import Transactions from './p_transactions/transactions';
import Report from './p_report/report';
import { useDispatch } from 'react-redux';
import { getPerformanceData } from '../../../httpcalls/reportThunk';

const Performance = () => {
    const dispatch = useDispatch()



    return (
        <>
            <div className='performance-container'>

            <Report />
            <div className='alert-vert-divider'></div>
            <Transactions />
            </div>
        </>
    );

}

export default Performance;