import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    report : [
        // {
        //     amount: 6.43,
        //     date: '2024-01-19 - 2025-01-19',
        //     description: 'Total Portfolio Performance'
        // }
    ],
    deposit: [],
    withdraw: [
        // {
        //     id: 0,
        //     amount: 10,
        //     date: '08/21/2023 11:00 PM CST',
        // }
    ],

})


const performanceSlice = createSlice({
    name: 'Performance Manager',
    initialState: initialState(),
    reducers: {
        resetPerformance: state => initialState(),
        updateReport: (state, action) => {
            state.report = action.payload
        },
        updateDeposit: (state, action) => {
            state.deposit = action.payload
        },
        updateWithdraw: (state, action) => {
            state.withdraw = action.payload
        },
    }
})

export const {
    resetAlert,
    updateReport,
    updateDeposit,
    updateWithdraw,
} = performanceSlice.actions


export default performanceSlice.reducer