
import React, { useRef, useEffect, useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { cancelOrder } from '../../../httpcalls/tastyThunk';
import { ScrollArea } from '@mantine/core';
import Tracking_Row from './tracking_row';
import { Accordion } from '@mantine/core';
import Tracking_Accordion from './tracking_accordion';



const Tracking_Table = () => {
    const [hiddenColumns, setHiddenColumns] = useState([
        ...JSON.parse(localStorage.getItem('OrderHiddenColumns') || '[]')
    ])
    useEffect(() => {
        localStorage.setItem('OrderHiddenColumns', JSON.stringify(hiddenColumns))
    }, [hiddenColumns])

    const trackingData = useSelector(state => state.mainapp.trackingSlice.orders)
    const dispatch = useDispatch()


    const tableColumns = [
        { item: "status", text: "Status" },
        { item: "orderId", text: "Order ID" },
        { item: "symbol", text: "Symbol" },
        { item: "type", text: "Type" },
        { item: "action", text: "Action" },
        { item: "price", text: "Price" },
        { item: "closedBy", text: "Closed By" },
        { item: "contracts", text: "Contracts" },
        { item: "fees", text: "Fee" },
        { item: "date", text: "Date" },
    ];

    const cancel = (orderId) => {
        dispatch(cancelOrder({ orderId: orderId }))
    }




    return (
        <>

            {
                hiddenColumns.map((item, index) => {
                    return (
                        <>
                            <style>
                                {`
            .${tableColumns.find((item2) => item2.text == item).item
                                    }{
                display: none !important;
            }
        `}
                            </style>
                        </>
                    )
                }
                )
            }

            <div className='table-container' style={{ padding: "0px" }}>
                <div className='table-header'>
                    <div className='table-row'>

                        {
                            // TABLE COLUMNS EDITOR BUTTON
                        }
                        <Menu position='bottom-start' shadow="md" width={200}>
                            <Menu.Target>
                                <span className='header-action-btn'><button><i style={{ fontSize: "18px" }} className='material-icons'>style</i></button></span>
                            </Menu.Target>
                            <Menu.Dropdown>


                                {
                                    hiddenColumns.length > 0 ? <>
                                        <Menu.Label>Add Columns</Menu.Label>
                                        <ScrollArea h={130}>
                                            {
                                                hiddenColumns.map((item, index) => {
                                                    return (
                                                        <Menu.Item onClick={
                                                            () => {
                                                                setHiddenColumns(hiddenColumns.filter((item2) => item2 != item))
                                                            }
                                                        } key={index} >{item}</Menu.Item>
                                                    )
                                                }
                                                )
                                            }
                                        </ScrollArea>

                                    </> : <Menu.Label>No Columns Available To Add</Menu.Label>
                                }
                            </Menu.Dropdown>
                        </Menu>

                        {
                            // TABLE COLUMN OUTPUT
                        }
                        {
                            tableColumns.map((item, index) => {
                                return (

                                    <Menu style={
                                        {
                                            display: hiddenColumns.includes(item.text) ? 'none' : 'flex'
                                        }
                                    } offset={-10} key={index} position='bottom-start' shadow="md" width={200}>
                                        <Menu.Target>
                                            <span className='vivify fadeIn duration-300 delay-100'> {item.text}</span>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Label>Column Options</Menu.Label>
                                            <Menu.Item onClick={
                                                () => {
                                                    setHiddenColumns([...hiddenColumns, item.text])
                                                }
                                            } icon={<i className='material-icons-outlined colored-icon'>visibility_off</i>}>Hide Column</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>

                                )
                            })
                        }
                    </div>
                </div>
                <div className='table-body'>
                    <Accordion variant="filled">
                        {
                            trackingData.map((item, index) => {
                                return (
                                    <>
                                        <Tracking_Row item={item} />

                                    </>



                                )
                            })
                        }
                    </Accordion>
                </div>

            </div>

            <style>
                {
                    `
                .mantine-ecft5f {padding: 0px !important}

                .mantine-Accordion-label {
                    padding: 0px !important;
                }

                .mantine-p2utts {position: sticky ; right:0px;display:none}

          
                `
                }
            </style>
        </>
    );

}

export default React.memo(Tracking_Table);