import React, { useRef, useEffect, useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ExploreLearn from '../../explore/explore_learn';
import { Drawer } from '@mantine/core';
import { ScrollArea } from '@mantine/core';
import { updateUser } from '../../../httpcalls/managerThunk';
import { addNewUser } from '../../../httpcalls/managerThunk';
import { notifications } from '@mantine/notifications';

const Manage_Modal = (props) => {
    const [loading, setLoading] = useState(false)
    const [formResults, setFormResults] = useState()
    const dispatch = useDispatch()
    const [opened, { open, close }] = useDisclosure(false);
    const [shares, setShares] = useState(1)
    const [limit, setLimit] = useState(0)

    const handleForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formValues = Object.fromEntries(formData.entries());

        const rolesArray = formData.getAll('roles');
        formValues.roles = rolesArray;

        if (props.edit) {
            dispatch(updateUser(formValues));
            notifications.show({
                title: 'User Updated Successfully',
                message: 'Your user has been updated successfully',
                icon: <i style={{ backgroundColor: 'var(--main-color)' }} className='material-icons-outlined'>
                    check_circle
                </i>,
                withCloseButton: false,
            })
        } else {
            dispatch(addNewUser(formValues));
            notifications.show({
                title: 'User Added Successfully',
                message: formData.get('first_name') + ' ' + formData.get('last_name') + ' has been added successfully',

                icon: <i style={{ backgroundColor: 'var(--main-color)' }} className='material-icons-outlined'>
                    check_circle
                </i>,
                withCloseButton: false,
            })
        }
        props.onClose()
    };

    return (
        <>
            {loading ?
                <div className='loading-ind'>
                    <div class="indeterminate-progress-bar"><div class="indeterminate-progress-bar__progress"></div></div>
                </div> : <>

                    <form onSubmit={(e) => {
                        e.preventDefault()
                        handleForm(e)
                    }}>

                        <div className='action-form'>

                            <h1 style={{ paddingTop: '0px', marginTop: '-10px' }}>
                                {props.edit ? 'Edit User' : 'New User'}
                            </h1>
                            <ScrollArea h={350} type="always">

                                <div style={{ marginBottom: "30px", padding: "5px" }} className='action-form-inputs'>
                                    <input type="hidden" name={'uid'} defaultValue={props.edit ? props.user.uid : ''} />
                                    <div style={{ flexDirection: 'column', gap: '10px' }} className='action-form-input'>
                                        <label>First Name</label>
                                        <input name='first_name' defaultValue={props.edit ? props.user.first_name : ''} placeholder='EX: John' type='text' required={!props.edit} />
                                    </div>
                                    <div style={{ flexDirection: 'column', gap: '10px' }} className='action-form-input'>
                                        <label>Last Name</label>
                                        <input name='last_name' defaultValue={props.edit ? props.user.last_name : ''} placeholder='EX: Doe' type='text' required={!props.edit} />
                                    </div>
                                    <div style={{ flexDirection: 'column', gap: '10px' }} className='action-form-input'>
                                        <label>Email</label>
                                        <input name='email' defaultValue={props.edit ? props.user.email : ''} placeholder='EX: example@aspiria.tech' type='email' required={!props.edit} />
                                    </div>
                                    <div style={{ flexDirection: 'column', gap: '10px' }} className='action-form-input'>
                                        <label>Organization</label>
                                        <input name='org' defaultValue={props.edit ? props.user.org : ''} placeholder='EX: Aspiria' type='text' required={!props.edit} />
                                    </div>
                                    <div style={{ flexDirection: 'column', gap: '10px' }} className='action-form-input'>
                                        <label>Roles</label>
                                        <div className='action-form-checks'>
                                            <div>
                                                <input defaultChecked={props.edit ? props.user.roles.includes('admin') ? true : false : false} id='admin' type='checkbox' name='roles' value='admin' />
                                                <label htmlFor='admin'>Admin</label>
                                            </div>
                                            <div>
                                                <input defaultChecked={props.edit ? props.user.roles.includes('trader') ? true : false : false} id='trader' type='checkbox' name='roles' value='trader' />
                                                <label htmlFor='trader'>Trader</label>
                                            </div>
                                            <div>
                                                <input defaultChecked={props.edit ? props.user.roles.includes('auditor') ? true : false : false} id='auditor' type='checkbox' name='roles' value='auditor' />
                                                <label htmlFor='auditor'>Auditor</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: 'column', gap: '10px' }} className='action-form-input'>
                                        <label>Exchange</label>
                                        <div className='action-form-checks'>
                                            <div>
                                                <input defaultChecked={props.edit ? props.user.operating == 'margin' ? true : false : false} id='margin' type='radio' name='operating' value='margin' />
                                                <label htmlFor='margin'>Margin</label>
                                            </div>
                                            <div>
                                                <input defaultChecked={props.edit ? props.user.operating == 'cash' ? true : false : false} id='cash' type='radio' name='operating' value='cash' />
                                                <label htmlFor='cash'>Cash</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ScrollArea>
                            <div className='action-form-submit' style={{ marginTop: "0px" }}>

                                <button className='lg-btn'>Submit</button>
                                {
                                    props.edit ? '' : <p className='text-floater'>Default Password is aspiriawheelie101#</p>
                                }
                            </div>
                        </div>
                    </form>
                </>

            }
            <style>
                {`
                    input {accent-color: var(--main-color)}
                `}
            </style>
        </>
    );
}

export default Manage_Modal;