import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';


const Transactions_Table = ({transactionType}) => {
    const navigate = useNavigate();
    const transactionTable = useSelector(state => state.mainapp.performanceSlice[transactionType])

    return (
        <>

            <div className='table-container' style={{ padding: "0px", marginTop: "20px" }}>
                <div className='table-header'>
                    <div style={{gridTemplateColumns: "45px 135px 190px"}} className='table-row'>
                        <span>Action</span>
                        <span>Amount</span>
                        <span>Date</span>
                  
                    </div>
                </div>
                <div className='table-body'>
                    {
                        transactionTable.map((item, index) => {
                            return (
                                <div className='table-row table-active-row' style={{gridTemplateColumns: "45px 135px 190px"}} key={index}>
                                    <div className='rack-right table-icon'>
                                        <Menu position='right-start' shadow="md" width={200}>
                                            <Menu.Target>
                                                <button><i className='material-icons'>more_horiz</i></button>
                                            </Menu.Target>
                                            <Menu.Dropdown>


                                                <Menu.Label>Actions Unavailable</Menu.Label>


                                               

                                            </Menu.Dropdown>
                                        </Menu>
                                    </div>



                                    <span>{item.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                                    <span>{item.date}</span>
                                 


                                </div>
                            )
                        })
                    }
                </div>

            </div>

        </>
    );

}

export default Transactions_Table;