import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    tastyAccountInfo: {
        purchasingPower: null
    },
    dataFilter: null,
    dataFilterInput: null,
    dataFilterOrderTracking: null,
    dataFilterInputOrderTracking: null,
    orderTracking: [
        // {
        //     id: 'CSP32732',
        //     date: '08/24/2021',
        //     status: 'Processing...',
        //     statusTrack: [4, 20]
        // },
        // {
        //     id: 'CSP32732',
        //     date: '08/24/2021',
        //     status: 'Completed',
        //     statusTrack: null,
        // },
        // {
        //     id: 'CSP32732',
        //     date: '08/24/2021',
        //     status: 'Completed',
        //     statusTrack: null,
        // },
        // {
        //     id: 'CSP32732',
        //     date: '08/24/2021',
        //     status: 'Processing...',
        //     statusTrack: [4, 20]
        // },
    ],

    wheelRack: {
       time:{
        seconds:0,
        minutes:0,
        hours:0,
        days:0
       },
       refresh:false,
    },
    modalWheelRack: {
        stockName: 'AMC',
        description: 'AMC Entertainment Holdings, Inc',
        Trade: {
            eventType: 'Trade',
            eventSymbol: 'MSFT',
            eventTime: 0,
            time: 1695240000427,
            timeNanoPart: 0,
            sequence: 414844,
            exchangeCode: 'Q',
            price: 320.77,
            change: 8.88,
            size: 2472825,
            dayId: 19620,
            dayVolume: 21436525,
            dayTurnover: 6955174714.293966,
            tickDirection: 'ZERO_UP',
            extendedTradingHours: true
        },
        atMaturity: {
            id: 'CSP32732',
            csp: 0.00,
            netPremium: 0.00,
            apr: 212,
        },
        closedNow: {
            id: 'CSP32732',
            csp: 0.00,
            netPremium: 0.00,
            apr: 212,
        },
    }




})


const dataSlice = createSlice({
    name: 'Data Slice',
    initialState: initialState(),
    reducers: {
        alterOrderTracking: (state, action) => {
            state.orderTracking = action.payload
        },
        alterMaturity: (state, action) => {
            state.wheelRack.Maturity = action.payload
        },
        alterClosedNow: (state, action) => {
            state.wheelRack.ClosedNow = action.payload
        },
        alterTastyPurchasePower: (state, action) => {
            state.tastyAccountInfo.purchasingPower = action.payload
        },
        alterDataFilter: (state, action) => {
            state.dataFilter = action.payload
        },
        alterDataFilterInput: (state, action) => {
            state.dataFilterInput = action.payload
        },

        alterDataFilterOrderTracking: (state, action) => {
            state.dataFilterOrderTracking = action.payload
        },
        alterDataFilterInputOrderTracking: (state, action) => {
            state.dataFilterInputOrderTracking = action.payload
        },


        alterWheelRackTime: (state, action) => {
            state.wheelRack.time = action.payload
        },
        alterRefreshTime: (state) => {
            state.wheelRack.refresh = !state.wheelRack.refresh
        }

    }
})

export const {
    alterOrderTracking,
    alterMaturity,
    alterClosedNow,
    alterTastyPurchasePower,
    alterDataFilter,
    alterDataFilterInput,
    alterDataFilterOrderTracking,
    alterDataFilterInputOrderTracking,
    alterWheelRackTime,
    alterRefreshTime
} = dataSlice.actions

export default dataSlice.reducer