import React, { useRef, useEffect, useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



const ExploreHome = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('lastTicker')){
            navigate(`/explore/${localStorage.getItem('lastTicker')}`)
        }
        else{
            localStorage.setItem('lastTicker', 'AMC')
            navigate(`/explore/AMC`)

        }
    }, [])



    return (
        <>
                <div className='empty-strip'>
                    <div className='empty-strip-text'>
                        <img src={window.location.origin + "/assets/empty-wallet.gif"}></img>
                        <h1>It’s Empty In Here</h1>
                        <p>Get started by <span>searching</span> for a stock.</p>
                    </div>
                </div>
        </>
    );

}

export default ExploreHome;