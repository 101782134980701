import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    orders:[],
    ordersMap:{}    
})


const trackingSlice = createSlice({
    name:'trackingSlice',
    initialState:initialState(),
    reducers: {
        resetOrderTracking: state => initialState(),
        updateTrackingOrders: (state, action) => {
            const orders = action.payload.orders
            let ordersArray = []
            for(let order of orders){
                ordersArray.push(order.orderId)
                state.ordersMap[order.orderId] = order
            }
            state.orders = ordersArray
        }
    }
})

export const {resetOrderTracking, updateTrackingOrders} = trackingSlice.actions

export default trackingSlice.reducer