import { createAsyncThunk } from '@reduxjs/toolkit'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { alterNavigate } from '../store/schemas/helperFunctionsSlice'
import { notifications } from '@mantine/notifications';
import { useNavigate } from "react-router-dom";
import { navigate } from '../helper_functions/thunkNavigate';
import { updateUsers } from '../store/schemas/managerSlice';

const server = process.env.REACT_APP_DOMAIN

export const getAllUsers = createAsyncThunk(
    'auth/getAllUsers',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/getAllUsers`, {
                method: 'GET',
                credentials: 'include'
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(updateUsers(results.message))
            console.log(results.message)
        } catch (error) {
            console.log(error)
        }
    }
)

export const getUser = createAsyncThunk(
    'auth/getUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/getUser`, {
                method: 'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(userInformation(results.message))
            thunkApi.dispatch(isLogin())
            return 'success'

        } catch (error) {
            console.log(error)
        }
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=false&model=auth&path=/signOut`, {
                method: 'POST',
                credentials: 'include'
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

            thunkApi.dispatch(resetAuth())
            navigate('/', { replace: true })
        } catch (error) {

            thunkApi.dispatch(resetAuth())
            notifications.show({ title: error.error, message: error.message })

        }
    }
)


export const signUpUser = createAsyncThunk(
    'auth/signUpUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}register`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: data.email, password: data.password, name: data.name })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(loginUser({ email: data.email, password: data.password }))
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })

        }
    }
)

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=false&model=auth&path=/login`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: data.email, password: data.password })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            return results.message
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
        }
    }
)

export const verifyCode = createAsyncThunk(
    'auth/verifyCode',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=false&model=auth&path=/verifyCode`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ session: data.session, code: data.code })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            await thunkApi.dispatch(getUser())
            return results.message
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
        }
    }
)


export const updatePassword = createAsyncThunk(
    'auth/updatePassword',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/updatePassword`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(getUser())
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
        }
    }
)


export const forgotPasswordSessionGeneration = createAsyncThunk(
    'auth/forgotPasswordSessionGeneration',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=false&model=auth&path=/forgotPasswordSessionGeneration`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: data.email, code: data.code })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            navigate(`/confirmResetPassword?s=${results.message}`)
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
        }
    }
)

export const forgotPasswordSessionEnd = createAsyncThunk(
    'auth/forgotPasswordSessionEnd',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=false&model=auth&path=/forgotPasswordSessionEnd`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ session: data.session, password: data.password })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            navigate('/', { replace: true })
            notifications.show({ title: "Password Reset Success", message: "The password for your account was rested successfully" })
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
        }
    }
)