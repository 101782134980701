import React, { useState, useEffect, useRef } from 'react';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useRefresh } from '../../../yourstocks/rack/components/w_refreshcontext';
import { getAccountBalance, getStockWidget } from '../../../../httpcalls/tastyThunk';
import W_Cash_Snap_Card from './w_cash_snap_card';

const W_Cash_Snap_Container = ({ id, themeColor, icon, name, removeWidget, canvas, removeCanvasWidget, uniqueId }) => {
    const dispatch = useDispatch()
    const accountBalance = useSelector(state => state.mainapp.dataSlice.tastyAccountInfo.purchasingPower)

    const { refresh } = useRefresh()

    const refreshTimer = useSelector(state => state.mainapp.dataSlice.wheelRack.refresh)
    useEffect(() => {
        dispatch(getAccountBalance())
    }, [refreshTimer])

    const { days, hours, minutes, seconds } = useSelector(state => state.mainapp.dataSlice.wheelRack.time);
    const renderElapsedTime = () => {
        if (days > 0) return `${days} day(s) ago`;
        if (hours > 0) return `${hours} hour(s) ago`;
        if (minutes > 0) return `${minutes} minute(s) ago`;
        return `${seconds} second(s) ago`;
    };

    const permaRemove = () => {
        removeWidget(id);
    }

    return (
        <>
            <div className={
                canvas ? 'widget vivify fadeIn duration-300 widget-canvas-item' : 'widget vivify fadeIn duration-300'
            }>

                <div className='widget-title'>
                    <div className='widget-title-left'>
                        <i style={
                            {
                                color: themeColor || 'var(--main-color)'
                            }
                        } className='material-icons'>{icon}</i>
                        <h3>{name}</h3>
                    </div>
                    <div className='widget-title-right'>
                        <button onClick={() => { refresh() }}><i className='material-icons'>refresh</i></button>

                        <Menu position='bottom-end' shadow="md" width={200}>
                            <Menu.Target>
                                <button><i className='material-icons'>more_horiz</i></button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Widget Settings</Menu.Label>
                                <Menu.Item
                                    icon={<i style={
                                        {
                                            color: themeColor || 'var(--main-color)'

                                        }
                                    } className='material-icons'>delete</i>}
                                    onClick={() => {
                                        canvas ? removeCanvasWidget(uniqueId) : permaRemove()
                                    }}
                                >
                                    Remove Widget
                                </Menu.Item>


                            </Menu.Dropdown>
                        </Menu>
                    </div>
                </div>
                <div className='widget-contents'>
                    <div className='widget-list'>
                        {accountBalance == undefined ?
                            <div className='widget-load-container'>
                                <div class="indeterminate-progress-bar"><div style={{ backgroundColor: themeColor, transition: "0.1s ease" }} class="indeterminate-progress-bar__progress"></div></div>
                                <span className='load-subtext' style={{ fontSize: "11px", textAlign: "center" }}>Pulling Data From Yahoo Finance...</span>
                            </div>
                            :
                            <W_Cash_Snap_Card />
                        }

                    </div>
                </div>
                <div className='widget-divider'></div>
                <div style={{ display: "flex", justifyContent: 'center' }}>
                    <p style={{
                        fontSize: '11px',
                        color: 'var(--text-gray)'
                    }}>Updated {renderElapsedTime()}</p>
                </div>

            </div>
        </>
    );
};

export default W_Cash_Snap_Container;
