import { useDispatch } from "react-redux";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import { useState } from "react";
import Mirror from "./s_mirror/mirror";

const Settings = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [activeNav, setActiveNav] = useState('mirror');  // Initialize with an empty string or a default value

    const handleNavigation = (nav) => {
        setActiveNav(nav);
    }

    return (
        <>

            <div id="modal" className="modal-container">
                <div className="modal-wrapper vivify fadeInBottom duration-300">
                    <h1 className='modal-hero-title'>
                        Settings
                    </h1>
                    <div className="modal-navbar">
                        <button
                            aria-label="Close Modal"
                            onClick={
                                () => {
                                    props.setSettingsToggle(false)
                                }
                            }
                            className="modal-close"
                        >
                            <i className="material-icons" aria-hidden="true">close</i>
                        </button>

                    </div>

                    <div className="split-modal">
                        <div className="modal-side-nav">
                            <div className='nav-btn-items'>

                            <div className={activeNav === 'widgets' ? "focused-nav activity-button" : "activity-button"}>
                                    <input
                                        type="radio"
                                        id="widgets"
                                        style={{ display: 'none' }}  // Hide the actual radio input
                                        onChange={() => setActiveNav('widgets')}
                                        name="nav-btn"
                                    />
                                    <label htmlFor="widgets" className='nav-btn vert-nav-btn'>
                                        <i className='material-icons'>widgets</i><span>Widgets<p>Under Development</p></span>
                                    </label>
                                </div>

                                <div className={activeNav === 'mirror' ? "focused-nav activity-button" : "activity-button"}>
                                    <input
                                        type="radio"
                                        id="mirror"
                                        style={{ display: 'none' }}  // Hide the actual radio input
                                        onChange={() => setActiveNav('mirror')}
                                        name="nav-btn"
                                    />
                                    <label htmlFor="mirror" className='nav-btn vert-nav-btn'>
                                        <i className='material-icons'>swap_horizontal_circle</i><span>Mirror Trading</span>
                                    </label>
                                </div>

                               
                            

                                <div className={activeNav === 'themes' ? "focused-nav activity-button" : "activity-button"}>
                                    <input
                                        type="radio"
                                        id="themes"
                                        style={{ display: 'none' }}  // Hide the actual radio input
                                        onChange={() => setActiveNav('themes')}
                                        name="nav-btn"
                                    />
                                    <label htmlFor="themes" className='nav-btn vert-nav-btn'>
                                        <i className='material-icons'>dark_mode</i><span>Themes</span>
                                    </label>
                                </div>

                                <div className={activeNav === 'yourstocks' ? "focused-nav activity-button" : "activity-button"}>
                                    <input
                                        type="radio"
                                        id="yourstocks"
                                        style={{ display: 'none' }}
                                        onChange={() => setActiveNav('yourstocks')}
                                        name="nav-btn"
                                    />
                                    <label htmlFor="yourstocks" className='nav-btn vert-nav-btn'>
                                        <i className='material-icons'>info</i><span>About<p>Under Development</p></span>
                                    </label>
                                </div>



                            </div>
                        </div>
                      {
                            activeNav === 'mirror' ? <Mirror /> : <div></div>
                      }
                    </div>

                </div>
            </div>
            <style>
                {`
                    body {
                        overflow: hidden;
                    }

                    .modal-navbar > lord-icon {
                        width: 545px;
                        height: 495px;
                        opacity: 0.2;
                        margin-right: -105px;
                        margin-top: -80px;
                    }

                    .modal-title {
                        margin-top: -344px;
                    }

                    .submit-button {
                        background-color: #F14B00;
                    }

                    .modal-wrapper {
                     padding-left: 35px
                    }
                `}
            </style>
        </>
    );
}

export default Settings;
