import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Notifications from './notifications/notifications';
import Manager from './manager/manager';



const Alerts = (props) => {
    const navigate = useNavigate();


    return (
        <>
            <div className='alert-container'>

                <Notifications />
                <div className='alert-vert-divider'></div>
                <Manager />

            </div>
        </>
    );

}

export default Alerts;