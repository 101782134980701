import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import stockSlice from './schemas/stockSlice';
import dataSlice from './schemas/dataSlice';
import alertSlice from './schemas/alertSlice';
import trackingSlice from './schemas/trackingSlice';
import managerSlice from './schemas/managerSlice';
import performanceSlice from './schemas/performanceSlice';
import canvasSlice from './schemas/canvasSlice';
import settingSlice from './schemas/settingSlice';


export const store = configureStore({
  reducer: {
    auth: authSlice,
    mainapp: combineReducers({
      stockSlice: stockSlice,
      dataSlice: dataSlice,
      alertSlice: alertSlice,
      trackingSlice: trackingSlice,
      managerSlice: managerSlice,
      performanceSlice: performanceSlice,
      canvasSlice: canvasSlice,
      settingSlice: settingSlice
    })
  },
});
