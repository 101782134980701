import React, { createContext, useContext, useState } from 'react';

const ExploreRowContext = createContext();

export const useExploreRowContext = () => {
    return useContext(ExploreRowContext);
}

export const ExploreRowProvider = ({ children }) => {
    const [hasRendered, setHasRendered] = useState(false);

    return (
        <ExploreRowContext.Provider value={{ hasRendered, setHasRendered }}>
            {children}
        </ExploreRowContext.Provider>
    );
}