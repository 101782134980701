import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Menu } from '@mantine/core';
import { useExploreRowContext } from './explore_row_context';
import { calculateBreakeven, calculateChange, calculateEstimatedAPRForTable, calculatePOP, calculatePercentChange, calculateToBreakeven, truncateToTwoDecimalPlaces } from '../../helper_functions/stockCalculations';
import { useDisclosure } from '@mantine/hooks';
import E_Option_Buy_Modal from './modals/e_option_buy_modal';
import AuthContext from '../../components/authContext';
import { useLocalStorage } from 'usehooks-ts';
import { tableColumns } from './explore_table';



const ExploreRow = ({ stockId, eventSymbol, selectedDate, callPut, buySell, stockPrice, hiddenColumns }) => {
    const dataFilter = useSelector(state => state.mainapp.dataSlice.dataFilter)
    const item = useSelector((state) => state.mainapp.stockSlice.stocks[stockId].stockOptionsMap[eventSymbol.symbol])
    const [counter, setCounter] = useState(0);  // Using counter as a state to force a re-render
    const { hasRendered, setHasRendered } = useExploreRowContext();
    const [opened, { open, close }] = useDisclosure(false);

    const toggleModal = () => {
        opened ? close() : open();
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (counter == 1) {
                setCounter(0);
            }
            else {
                setCounter(1);
            }
        }, 30000);

        return () => clearInterval(intervalId);  // Clean up the interval when the component is unmounted
    }, []);


    // console.log(callPut)

    const renderItem = (() => {

        let finalItem = {}
        let TASTYSYMBOL = item.tastySymbol
        let BIDPRICE = item.eventTypes?.Quote?.bidPrice || 0
        let ASKPRICE = item.eventTypes?.Quote?.askPrice || 0
        let MARK = 0
        if (BIDPRICE != 0 || ASKPRICE != 0) {
            MARK = truncateToTwoDecimalPlaces((parseFloat(BIDPRICE) + parseFloat(ASKPRICE)) / 2, 3)
        }
        let STRIKEPRICE = parseFloat(item.strikePrice)
        let ID = eventSymbol.symbol
        let DATE_VALUE = item.expirationDate || 'N/A'
        let BREAKEVEN = (calculateBreakeven(item.strikePrice, (item.eventTypes?.Quote?.askPrice || 0), callPut))
        let POP = calculatePOP(item.eventTypes?.Greeks?.delta, callPut, buySell)
        let CHANGE = calculateChange(MARK, item.eventTypes?.Summary?.prevDayClosePrice)
        let PERCENTCHANGE = calculatePercentChange(MARK, item.eventTypes?.Summary?.prevDayClosePrice)
        let INTRADAYLOW = item.eventTypes?.Summary?.dayLowPrice == "NaN" ? "N/A" : item.eventTypes?.Summary?.dayLowPrice || "N/A"
        let INTRADAYHIGH = item.eventTypes?.Summary?.dayHighPrice == "NaN" ? "N/A" : item.eventTypes?.Summary?.dayHighPrice || "N/A"
        let APR = calculateEstimatedAPRForTable(MARK, STRIKEPRICE, DATE_VALUE)
        let TBE = calculateToBreakeven(BREAKEVEN, stockPrice || 0)
        let VOLUME = item.eventTypes?.Trade?.dayVolume == "NaN" ? "N/A" : item.eventTypes?.Trade?.dayVolume
        let VOLATILITY = truncateToTwoDecimalPlaces(item.eventTypes?.Greeks?.volatility * 100)
        let STOCKPRICE = stockPrice || 0  // This is called by the eval
        let DISTANCE = truncateToTwoDecimalPlaces((STRIKEPRICE - STOCKPRICE) / STOCKPRICE * 100)

        // const isClosestToStrike = item === closestItem;
        const DATE = (overload = DATE_VALUE) => {
            return new Date(overload)
        }
        finalItem = {
            strikePrice: STRIKEPRICE,
            tastySymbol: TASTYSYMBOL,
            id: ID,
            stockId: stockId,
            breakeven: BREAKEVEN,
            pop: POP,
            toBreakEven: TBE,
            percentChange: PERCENTCHANGE,
            change: CHANGE,
            intradayHigh: INTRADAYHIGH,
            intradayLow: INTRADAYLOW,
            apr: APR,
            bidPrice: BIDPRICE,
            askPrice: ASKPRICE,
            mark: MARK,
            date: DATE_VALUE,
            volume: VOLUME,
            volatility: VOLATILITY,
            distance: DISTANCE,
            active: eval(dataFilter) || dataFilter == null,
            formatted: {
                strikePrice: STRIKEPRICE.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                id: ID,
                breakeven: BREAKEVEN.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                pop: POP == 'N/A' ? 'N/A' : POP + "%",
                toBreakEven: TBE == 'N/A' ? 'N/A' : TBE + "%",
                percentChange: PERCENTCHANGE == 'N/A' ? 'N/A' : PERCENTCHANGE.toFixed(2) + "%",
                change: CHANGE == "N/A" ? 'N/A' : CHANGE.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                intradayLow: INTRADAYLOW == "N/A" ? 'N/A' : INTRADAYLOW.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                intradayHigh: INTRADAYHIGH == "N/A" ? 'N/A' : INTRADAYHIGH.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                apr: APR == 'N/A' ? 'N/A' : APR.toFixed(2) + "%",
                mark: MARK == 'N/A' ? 'N/A' : MARK.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 3, maximumFractionDigits: 3 }),
                volume: VOLUME == "N/A" ? 'N/A' : VOLUME,
                volatility: VOLATILITY == "N/A" ? 'N/A' : VOLATILITY + "%",
                distance: DISTANCE == "N/A" ? 'N/A' : DISTANCE + "%",
                date: DATE_VALUE == 'N/A' ? 'N/A' : DATE_VALUE,
            }
            // closestToStrike:isClosestToStrike
        }

        if (selectedDate != 'All Dates') {
            delete finalItem.formatted.date
        }
        if (buySell == 'sell') {
            delete finalItem.formatted.toBreakEven
        }
        else {
            delete finalItem.formatted.pop
        }
        return finalItem
    })()


    useEffect(() => {
        if (!(renderItem.breakeven == renderItem.strikePrice)) {
            if (hasRendered == false) {
                console.log('render')
                setHasRendered(true)
            }
        }
    }, [renderItem.breakeven, renderItem.mark])



    const [columnOrder, setItems] = useLocalStorage('columnLayout', tableColumns.map((col, index) => ({
        id: `item-${index + 1}`,
        content: col.columnName
    })))

    
    const reverseColumnDictionary = {
        "Strike Price": "strikePrice",
        "ID #": "id",
        "Breakeven": "breakeven",
        "POP": "pop",
        "TBE": "toBreakEven",
        "% Change": "percentChange",
        "Change": "change",
        "Intraday Low": "intradayLow",
        "Intraday High": "intradayHigh",
        "APR at Maturity": "apr",
        "Mark": "mark",
        "Volume": "volume",
        "Volatility": "volatility",
        "Distance": "distance",
        "All Dates": "date"
    };


    return (
        (!(renderItem.breakeven == renderItem.strikePrice && renderItem.mark == 0)) ?
            <>


                {opened ? <E_Option_Buy_Modal buySell={buySell} callPut={callPut} optionSymbol={eventSymbol.symbol} stockSymbol={stockId} modalToggle={toggleModal} /> : null}


                <div className={renderItem.active ?
                    'table-row'
                    : 'table-row table-row-inactive'}>


                    <div className='rack-right table-icon'>
                        <AuthContext access={['admin', 'trader']}>
                            <Menu position='right-start' shadow="md" width={200}>
                                <Menu.Target>

                                    <button><i className='material-icons'>more_horiz</i></button>

                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Label>Execute Order</Menu.Label>

                                    <Menu.Item onClick={() => { toggleModal() }} icon={<i className='material-icons-outlined colored-icon'>offline_bolt</i>}>Good For Day</Menu.Item>

                                    <Menu.Item icon={<i className='material-icons colored-icon'>bolt</i>}>Good Till Canceled</Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </AuthContext>
                    </div>





                    {
                        (() => {
                            let arrayItems = []
                            columnOrder.forEach((item) => {
                                let returnType = false
                                               
                                                
                                if(item.content != null){
                                    if(!hiddenColumns.includes(item.content)){
                                        let tableItemColumn = tableColumns.find(tableCol => tableCol.columnName == item.content)
                                        if(tableItemColumn.dateCheck != undefined){
                                            if(selectedDate == 'All Dates'){
                                                returnType = true
                                            }
                                        }
                                        else if(tableItemColumn.buySellCheck != undefined){
                                            if(tableItemColumn.buySellCheck.includes(buySell)){
                                                returnType = true
                                            }
                                        }
                                        else{
                                            returnType = true
                                        }
                                    }
                                }
                                    
                                if(returnType == true ){
                                    arrayItems.push(
                                        <span className='vivify fadeIn duration-300 delay-100'  style={{ display: 'flex' }}>
                                           {renderItem.formatted[reverseColumnDictionary[item.content]]}
                                        </span>
                                    )
                                }
                            })
                            return arrayItems
                        })()
                    
                    }

                </div>
            </>

            :
            null
    )
}

export default React.memo(ExploreRow)

