import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { MultiSelect } from '@mantine/core';
import { getAllUsers } from '../../../httpcalls/authThunk';
import { useDispatch } from 'react-redux';
import { Modal } from '@mantine/core';
import Manage_Modal from './manage_modal';
import { refreshPassword } from '../../../httpcalls/managerThunk';
import { clearMFASession } from '../../../httpcalls/managerThunk';


const Manage_Table = (props) => {
    const navigate = useNavigate();
    const managerTable = useSelector(state => state.mainapp.managerSlice?.userTable);
    const [searchValue, onSearchChange] = useState('');
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllUsers()).then(res => {
            console.log(res)
        })
    }, [])

    const [opened, setOpened] = useState(false)
    const close = () => setOpened(false)
    const [userItemData, setUserItemData] = useState({})


    return (
        <>
            <Modal overlayProps={{

                opacity: 0.55,
                blur: 7,
            }} centered radius={"lg"} size="auto" withCloseButton={false} title={<div style={{ borderBottom: "none" }} className='modal-header'>
                <button style={
                    {
                        opacity: '0',
                        cursor: 'default'
                    }
                }></button>
                <h3></h3>
                <button onClick={
                    close
                }><i className='material-icons'>close</i></button>
            </div>} opened={opened} onClose={close} >

                <Manage_Modal onClose={close} user={userItemData} edit={true} />

            </Modal>
            {managerTable ? (
                <div className='table-container  modal-table' style={{ padding: "0px", marginTop: "0px !important", height: "fit-content" }}>
                    <div className='table-header'>
                        <div className='table-row manage-table-row '>
                            <span>Action</span>
                            <span>Name</span>
                            <span>Email</span>
                            <span>Org.</span>
                            <span>Operating</span>
                            <span>Roles</span>



                        </div>
                    </div>
                    <div className='table-body'>
                        {
                            managerTable.map((item, index) => {
                                return (
                                    <div className='table-row manage-table-row table-active-row' key={index}>
                                        <div className='rack-right table-icon'>
                                            <Menu position='right-start' shadow="md" width={200}>
                                                <Menu.Target>
                                                    <button><i className='material-icons'>more_horiz</i></button>
                                                </Menu.Target>
                                                <Menu.Dropdown>



                                                    <Menu.Item onClick={
                                                        () => {
                                                            dispatch(refreshPassword({ uid: item.uid })).then(res => {
                                                                notifications.show({
                                                                    title: 'Password Reset',
                                                                    message: 'Password reset successfully to aspiriawheelie101#',
                                                                    icon: <i style={{ backgroundColor: 'var(--main-color)' }} className='material-icons-outlined'>password</i>,

                                                                    autoClose: 10000
                                                                })
                                                            })
                                                        }
                                                    }>Reset Password</Menu.Item>

                                                    <Menu.Item onClick={
                                                        () => {
                                                            dispatch(clearMFASession({ uid: item.uid })).then(res => {
                                                                notifications.show({
                                                                    title: 'MFA Reset',
                                                                    message: 'MFA reset successfully',
                                                                    icon: <i style={{ backgroundColor: 'var(--main-color)' }} className='material-icons-outlined'>qr_code_scanner</i>,

                                                                    autoClose: 10000
                                                                })
                                                            })
                                                        }

                                                    }>Reset MFA</Menu.Item>

                                                    <Menu.Item onClick={
                                                        () => {
                                                            setUserItemData(item)
                                                            setOpened(true)
                                                        }
                                                    }>Edit User</Menu.Item>

                                                </Menu.Dropdown>
                                            </Menu>
                                        </div>

                                        <span>
                                            {item.first_name} {item.last_name}
                                        </span>

                                        <span>{item.email}</span>

                                        <span>{item.org}</span>
                                        <span style={{ textTransform: "capitalize" }}>{item.operating}</span>

                                        <span style={{ textTransform: "capitalize" }}>

                                            {item.roles && (item.roles).map((permission, index) => (
                                                <span style={{ textTransform: "capitalize" }}>{permission} </span>
                                            ))
                                            }
                                        </span>





                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            ) : (
                <p style={{ textAlign: "center", marginTop: "20px" }}>Loading...</p>
            )}
        </>
    );

}

export default Manage_Table;