import { createAsyncThunk } from '@reduxjs/toolkit'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { alterNavigate } from '../store/schemas/helperFunctionsSlice'
import { notifications } from '@mantine/notifications';
import { useNavigate } from "react-router-dom";
import { navigate } from '../helper_functions/thunkNavigate';
import { updateUsers } from '../store/schemas/managerSlice';
import { getAllUsers } from './authThunk';

const server = process.env.REACT_APP_DOMAIN


export const addNewUser = createAsyncThunk(
    'manager/addNewUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/addNewUser`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
            })

            thunkApi.dispatch(getAllUsers())
            return 'success'

        } catch (error) {
            console.log(error)
        }
    }
)

export const clearMFASession = async (data) => {
    try {
        const response = await fetch(`${server}?auth=true&model=user&path=/clearMFASession`, {
            method: 'POST',
            credentials: 'include',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
        const results = await response.json()
        if ("error" in results) {
            throw results
        }
        return 'success'
    } catch (error) {
        console.log(error)
    }
}

export const refreshPassword = createAsyncThunk(
    'manager/refreshPassword',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/refreshPassword`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
            })

            thunkApi.dispatch(getAllUsers())
            return 'success'

        } catch (error) {
            console.log(error)
        }
    }
)

export const updateUser = createAsyncThunk(
    'manager/updateUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/updateUser`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
            })

            thunkApi.dispatch(getAllUsers())
            return 'success'

        } catch (error) {
            console.log(error)
        }
    }
)
