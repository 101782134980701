
import React, { useRef, useEffect, useState } from 'react';
import { Drawer, SegmentedControl, Tooltip } from '@mantine/core';
import { Select } from '@mantine/core';
import Tracking_Table from './tracking_table';
import { useSelector, useDispatch } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { getOrderTracking } from '../../../httpcalls/tastyThunk';
import { alterDataFilterInputOrderTracking, alterDataFilterOrderTracking } from '../../../store/schemas/dataSlice';
import { useDisclosure } from '@mantine/hooks';
import TrackingLearn from './tracking_learn';
import W_RefreshButton from '../rack/components/w_refreshbutton';
import { useRefresh } from '../rack/components/w_refreshcontext';


const Tracking = () => {

    const [smartQueryOpen, setSmartQueryOpen] = useState(false)
    const dispatch = useDispatch()
    const smartActiveQuery = useSelector((state) => state.mainapp.dataSlice.dataFilterInputOrderTracking)
    const [smartQuery, setSmartQuery] = useState(smartActiveQuery || '')
    const refreshTimer = useSelector(state => state.mainapp.dataSlice.wheelRack.refresh)

    const [opened, { open, close }] = useDisclosure(false);


    const resetSmartQuery = () => {
        setSmartQuery('')
        dispatch(alterDataFilterInputOrderTracking(null))
        dispatch(alterDataFilterOrderTracking(null))
    }

    function preprocessInput(input) {
        let processedInput = input.toUpperCase();
        console.log(processedInput)
        processedInput = processedInput.replace(/,|\bAND\b/g, '&&'); // Replace "AND" with &&
        processedInput = processedInput.replace(/\bOR\b/g, '||'); // Replace "OR" with ||
        processedInput = processedInput.replace(/[$%\s]+/g, ''); // Remove $, %, and all white space

        processedInput = processedInput.replace(/(?<![<>=!])=+(?![<>=])/g, '==');
        processedInput = processedInput.replace(/N\/A/g, '"N/A"');
        processedInput = processedInput.replace(/DATE\(([^")]+?)\)/g, 'DATE("$1")');
        processedInput = processedInput.replace(/\bDATE\b(?![(])/g, 'DATE()');
        return processedInput;
    }

    const TOKEN_PATTERNS = {
        variable: /[A-z][A-z0-9]*/,
        operator: /(?:&&|\|\||>|<|>=|<=|==|!=)/,
        value: /-?\d+(\.\d+)?/,  // Match integers and decimals
        specialValue: /"N\/A"/,
        parentheses: /[()]/,
        functionCall: /DATE\("[0-9]{2}\/[0-9]{2}\/[0-9]{4}(?:_[0-9]{2}:[0-9]{2}:[0-9]{2})?"\)/,
        stringLiteral: /"[^"]*"/
    };

    const COMBINED_PATTERN = new RegExp(
        Object.values(TOKEN_PATTERNS).map(pattern => `(${pattern.source})`).join('|'),
        'g'
    );


    function tokenize(input) {

        return input.match(COMBINED_PATTERN) || [];
    }

    function sanitize() {
        let input = preprocessInput(smartQuery)
        const WHITELISTED_VARIABLES = ['"N/A"', "STOCKPRICE", 'VOLATILITY', 'DATE', 'DISTANCE', 'STRIKEPRICE', 'VOLUME', "BREAKEVEN", 'POP', "TBE", "PERCENTCHANGE", "CHANGE", "INTRADAYLOW", "INTRADAYHIGH", "APR", "MARK", "ORDERID", "STATUS", "SYMBOL", "TYPE", "ACTION", "PRICE", "CLOSEDBY", "FILLEDCONTRACTS", "TOTALCONTRACTS", "FEES", "DATE"];
        const WHITELISTED_OPERATORS = ['&&', '||', '>', '<', '>=', '<=', '==', '!='];
        const WHITELISTED_PARENS = ['(', ')'];
        const WHITELISTED_FUNCTIONS = [TOKEN_PATTERNS.functionCall];

        try {
            const tokens = tokenize(input);
            for (const token of tokens) {
                if (
                    !WHITELISTED_VARIABLES.includes(token) &&
                    !WHITELISTED_OPERATORS.includes(token) &&
                    !WHITELISTED_PARENS.includes(token) &&
                    !WHITELISTED_FUNCTIONS.some(func => token.match(func)) &&
                    !token.match(TOKEN_PATTERNS.value) &&  // Instead of a whitelist, just match against the value pattern
                    !token.match(TOKEN_PATTERNS.stringLiteral)
                ) {
                    throw token

                }
            }

            dispatch(alterDataFilterOrderTracking(input))
            dispatch(alterDataFilterInputOrderTracking(smartQuery))
            notifications.show({
                icon: <i style={{ color: "white" }} className='material-icons'>check</i>,
                title: 'Changes Applied',
                message: 'Your changes have been applied to the table.',
            });
            // setSmartQueryOpen(false)
        } catch (error) {
            notifications.show({
                title: 'Query Not Applied',
                message: 'The submitted query has the following bad token: ' + error,
                color: "red"
            })
            // I need this to be red @Jacoby
        }


    }

    useEffect(() => {
        dispatch(getOrderTracking())
    }, [refreshTimer])

    return (
        <>
            <Drawer opened={opened} onClose={close} position='right' withCloseButton={false}>
                <TrackingLearn />
            </Drawer>

            <header style={{ position: "sticky", top: "0px" }} className='activity-header'>

                <W_RefreshButton style={"head"} type={"Tracking"} />

            </header>

            <div className='activity-sort' style={
                smartQueryOpen ? { gap: '16px', } : {}
            }>

                {
                    smartQueryOpen ?
                        <button onClick={
                            () => {
                                setSmartQueryOpen(false)
                                setSmartQuery(smartActiveQuery)
                            }
                        } className='query-close'>
                            <i className='material-icons'>close</i>
                        </button>
                        : ""
                }


                <div className={
                    smartQueryOpen ? 'activity-query smart-query-open' : 'activity-query'
                }
                    style={smartQueryOpen ? {borderRadius: '50px'} : { width: '250px', borderRadius: '50px' }}
                >

                    <input value={smartQuery || (smartQueryOpen == false ? smartActiveQuery : undefined) || ''} onChange={
                        (e) => {
                            setSmartQuery(e.target.value)
                        }
                    } placeholder='Enter Smart Query...' onFocus={
                        () => {
                            setSmartQueryOpen(true)
                        }
                    } type='text' ></input>
                    {
                        smartQueryOpen ?
                            <div className='query-btn'>
                                <button onClick={resetSmartQuery}><i className='material-icons'>refresh</i><span>Reset</span></button>
                                <button onClick={sanitize}><i className='material-icons'>play_arrow</i><span>Run</span></button>
                            </div>
                            : ""
                    }
                    <Tooltip offset={
                        smartQueryOpen ? 20 : 5
                    } label="Query Guide">
                        <button style={{marginRight: "0px"}} onClick={open} className='resource-btn'>
                            <i className='material-icons-outlined'>auto_stories</i>
                        </button>
                    </Tooltip>

                </div>
            </div>

            {
                <div className='nav-page explore-page vivify fadeIn duration-300 delay-200'>


                    <Tracking_Table />
                </div>
            }
            <style>
                {`
            .table-row {
                grid-template-columns: 70px 88px 78px 130px 70px 100px 100px 89px 100px 70px 75px 95px;
                gap: 60px
            }

            .activity-segment {
                grid-template-columns: repeat(5, 1fr);
                width: 530px
            }

            .explore-page {
                grid-template-rows:1fr;
            }

            .activity-header {
                justify-content:center
            }

            .table-container {
                margin-top:75px
            }

            .smart-query-open {
                width: 30vw
            }

            .activity-sort {    position: fixed;
                right: 45px;
                top: 42px;
                z-index: 500;
                width: fit-content;}

                .smart-query-open>button{
                    right: -32px
                }

                .horizontal-navs {
                    margin-left: 17px
                }

                .activity-query {
                    border-radius: 10px
                }
            `}
            </style>
        </>

    );



}

export default Tracking;