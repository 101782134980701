import React, { useRef, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import MiniRack from './miniRack';
import { SpotlightProvider, spotlight } from '@mantine/spotlight';
import { SpotlightAction } from '@mantine/spotlight';
import { useNavigate } from 'react-router-dom';
import OrderTracking from './orderTracking';
import { useSelector } from 'react-redux';
import { Menu, Button, Text } from '@mantine/core';
import { logout } from '../httpcalls/authThunk';
import { useDispatch } from 'react-redux';
import { searchSymbol } from '../httpcalls/tastyThunk';
import DarkModeController from './themes/darkmodeController';
import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import WidgetPanel from '../mainapp/widgets/widgetPanel';
import AuthContext from './authContext';
import CompactModeController from './themes/compactmodeController';
import DuneKillerController from './themes/dunekillerController';


const SideNav = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    defineElement(lottie.loadAnimation);

    const [searchOpen, setSearchOpen] = useState(false)
    const user = useSelector(state => state.auth.userInfo)
    const [inputtedSearch, setInputtedSearch] = useState('')
    const [actions, setActions] = useState([]);
    const { stockid } = useParams()
    const stockData = useSelector(state => state.mainapp.stockSlice?.stocks[stockid]?.marketData)
    const activeStock = localStorage.getItem('lastTicker') || "AMC"


    useEffect(() => {
        if (inputtedSearch != '') {
            setActions([])
            dispatch(searchSymbol({ symbol: inputtedSearch })).then((res) => {
                if (res.payload.symbol == inputtedSearch) {
                    const resultActions = res.payload.results.map((item) => {
                        return { title: item.symbol, description: item.description, onTrigger: () => { navigate(`/explore/${item.symbol}`); } }
                    })
                    setActions(resultActions)
                }
            })
        }
        else {
            setActions([])
        }
    }, [inputtedSearch])

    const logoutUser = () => {
        dispatch(logout())
    }

    return (
        <>

            <SpotlightProvider
                radius={"md"}
                actions={actions}
                searchIcon={<i className='material-icons colored-icon'>search</i>}
                searchPlaceholder="Search..."
                shortcut="mod + shift + 1"
                nothingFoundMessage="Stock Not Found..."
                onQueryChange={setInputtedSearch}
            >

            </SpotlightProvider>
            <CompactModeController init={false} />
            <DarkModeController init={false} />

            <nav resizable className='side-navbar'>

                <div className='side-navbar-sub'>

                    <h1>
                        {
                            window.location.pathname == '/explore' ?
                                "Explore" : window.location.pathname.includes('/yourstocks') ?
                                    "Your Stocks" : window.location.pathname == '/notifications' ?
                                        "Notifications" : window.location.pathname == '/explore/' + activeStock ?
                                            "Explore" : window.location.pathname == '/tracking' ? "Orders" : window.location.pathname == '/canvas' ? "Canvas" : "Explore"


                        }


                    </h1>
                    <div className='side-nav-btns'>
                        <input readOnly onClick={() => { spotlight.open() }} placeholder='Search For Stock...' type='text' className='search-btn' />

                        <div className='stock-results'>
                            <button>
                                <div>AAPL</div>
                                <span>Apple Inc.</span>
                            </button>
                            <button>
                                <div>DSNY</div>
                                <span>Disney Inc.</span>
                            </button>
                            <button>
                                <div>AMZN</div>
                                <span>Amazon Inc.</span>
                            </button>
                        </div>
                        <div className='nav-btn-items'>


                            <NavLink to={
                                `/explore/${activeStock}`
                            } className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>
                                <button className='nav-btn'><i className='material-icons'>explore</i><span>Explore</span></button>
                            </NavLink>


                            {/* 
                            <NavLink to="/rack" className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>
                                <button className='nav-btn'><i className='material-icons'>track_changes</i><span>Wheel Rack</span></button>
                            </NavLink> */}

                            <NavLink to="/yourstocks" className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>
                                <button className='nav-btn'><i className='material-icons'>data_exploration</i><span>Your Stocks</span></button>
                            </NavLink>

                            <NavLink to="/canvas" className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>
                                <button className='nav-btn'><i className='material-icons'>space_dashboard</i><span>Research Canvas<p style={{ display: 'none' }}>Beta</p></span></button>
                            </NavLink>

                            <AuthContext access={['admin']}>
                                <NavLink to="/notifications" className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>
                                    <button className='nav-btn'><i className='material-icons'>notifications</i><span>Notifications</span></button>
                                </NavLink>
                            </AuthContext>

                        </div>

                    </div>
                </div>

                <div className='widget-panel-container'>
                    <WidgetPanel />
                </div>


                {/*   <div className='mini-rack'>
                    
                  <MiniRack /> 
                </div> */}

                {/*      <OrderTracking />
 */}


            </nav>

        </>
    );

}

export default SideNav;