
export const truncateToTwoDecimalPlaces = (num, places = 2) => {
    if (num >= 0) {
        return Math.floor(num * 10 ** places) / 10 ** places;
    } else {
        return Math.ceil(num * 10 ** places) / 10 ** places;
    }
}


export const calculatePercentageChange = (originalValue, newValue) => {
    if(originalValue == "0"){
        return "Infinity"
    }
    const change = newValue - originalValue;
    const percentage = (change / originalValue) * 100;
    return percentage.toFixed(2);
}

export const calculateMark = (bidPrice = 0, askPrice = 0) => {
    let mark = 0
    if (bidPrice != 0 || askPrice != 0) {
        mark = truncateToTwoDecimalPlaces((parseFloat(bidPrice) + parseFloat(askPrice)) / 2, 3)
    }
    return mark
}

export const calculateTotalBTC = (contracts, mark) => {
    const totalBTC = contracts * mark * 100;
    return truncateToTwoDecimalPlaces(totalBTC)
}


export const calculateBreakeven = (strikePrice, premium, callPut) => {
    let breakeven;
    if (callPut.toLowerCase() === 'put') {
        breakeven = strikePrice - premium;
    } else {
        breakeven = strikePrice + premium;
    }

    return truncateToTwoDecimalPlaces(breakeven)
}

export const calculatePOP = (delta, callPut, buySell) => {
    if (delta === undefined) {
        return "N/A";
    }

    let pop = 0;

    if (callPut.toLowerCase() === 'put') {
        if (buySell === 'buy') {
            return 'N/A'
        } else {
            pop = (1 + delta) * 100;
        }
    } else {
        if (buySell === 'buy') {
            return 'N/A'
        } else {
            pop = (1 - delta) * 100;
        }
    }

    return truncateToTwoDecimalPlaces(pop)
}


export const calculateToBreakeven = (breakeven, stockPrice) => {
    if (breakeven == undefined || stockPrice == undefined) {
        return "N/A";
    }

    const toBreakEven = (breakeven - stockPrice) / stockPrice


    return truncateToTwoDecimalPlaces(toBreakEven * 100)
}

export const calculateChange = (mark, prevDayClosePrice) => {
    const prevClosePrice = parseFloat(prevDayClosePrice);

    if (isNaN(prevClosePrice)) {
        return "N/A";
    }

    const change = mark - prevDayClosePrice;

    return truncateToTwoDecimalPlaces(change)
}


export const calculatePercentChange = (mark, prevDayClosePrice) => {
    const prevClosePrice = parseFloat(prevDayClosePrice);

    if (prevDayClosePrice == undefined && mark) {
        return "N/A";
    }
    const percentChange = ((mark / prevClosePrice) - 1) * 100;

    if (isNaN(percentChange)) {
        return 0
    }

    return truncateToTwoDecimalPlaces(percentChange);
}


export const calculateEstimatedAPRForTable = (mark, strike, date)  => {
    const tastyFees = .5;
    const premium = mark * 100 - tastyFees
    const strikePrice = strike * 100;

    if (premium === undefined) {
        return "N/A";
    }

    const absoluteRoi = premium / strikePrice * 100

    // Calculate number of hours until expiration
    const expirationDate = new Date(date + " 15:00:00");
    const currentDate = new Date()
    const timeDifference = expirationDate - currentDate;
    const hoursUntilExpiration = timeDifference / (1000 * 3600);
    const hoursInYear = 8760
    const projectedAPR = absoluteRoi * hoursInYear / hoursUntilExpiration
    return truncateToTwoDecimalPlaces(projectedAPR)
}

export const calculateEstimatedAPRForRack = (premium, strike, contracts, date = null, boughtDate = null)  => {
    const strikePrice = strike * 100 * contracts;

    if (premium === undefined) {
        return "N/A";
    }

    const absoluteRoi = premium / strikePrice * 100

    // Calculate number of hours until expiration
    const expirationDate = (date == null) ? new Date() : new Date(date + " 15:00:00");
    const currentDate = boughtDate == null ? new Date() : new Date(boughtDate);
    const timeDifference = expirationDate - currentDate;
    const hoursUntilExpiration = timeDifference / (1000 * 3600);
    const hoursInYear = 8760
    // console.log(date, boughtDate)
    const projectedAPR = absoluteRoi * hoursInYear / hoursUntilExpiration
    return truncateToTwoDecimalPlaces(projectedAPR)
}

export const calculateEstimatedAPRInstanceForRack = (premium, strike, contracts, sto)  => {
    const strikePrice = strike * 100 * contracts;
    
    if (premium === undefined) {
        return "N/A";
    }

    const absoluteRoi = premium / strikePrice * 100
    // Calculate number of hours until expiration
    const expirationDate = new Date()
    const currentDate = new Date(sto)
    const timeDifference = expirationDate - currentDate;
    const hoursUntilExpiration = timeDifference / (1000 * 3600);
    const hoursInYear = 8760
  
    const projectedAPR = absoluteRoi * hoursInYear / hoursUntilExpiration
    return truncateToTwoDecimalPlaces(projectedAPR)
}

export const convertSymbol = (symbol) => {
    const [ticker, rest] = symbol.trim().split(/\s+/);
    console.log(symbol, rest)
    const date = rest.slice(0, 6);
    const type = rest.slice(6, 7);
    let strikePrice = rest.slice(7);
    const mainPrice = strikePrice.slice(0, -3);
    const decimalPrice = strikePrice.slice(-3);
    strikePrice = parseFloat(`${parseInt(mainPrice)}.${parseInt(decimalPrice)}`);

    return `.${ticker}${date}${type}${strikePrice}`;
}