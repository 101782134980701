import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Manager_Table from './manager_table';
import { Modal } from '@mantine/core';
import Manager_Add from './manager_add';
import { notifications } from '@mantine/notifications';
import { updateTodayNotifications } from '../../../store/schemas/alertSlice';


const Manager = (props) => {
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false)
    const dispatch = useDispatch()



    const alertTable = useSelector(state => state.mainapp.alertSlice?.alertTable)
    const [smartQueryOpen, setSmartQueryOpen] = useState(false)
    const [currentRack, setCurrentRack] = useState('maturity')
    const close = () => setOpened(false)


    return (
        <>

            <Modal centered radius={"lg"} size="auto" withCloseButton={false} title={<div className='modal-header'>
                <button style={
                    {
                        opacity: '0',
                        cursor: 'default'
                    }
                }></button>
                <h3></h3>
                <button onClick={
                    close
                }><i className='material-icons'>close</i></button>
            </div>} opened={opened} onClose={close} >

                <Manager_Add />
            </Modal>
            {
                alertTable ?
                    <div className='nav-page explore-page vivify fadeIn duration-300 delay-200'>
                        <div style={
                            {
                                marginLeft: "35px",
                            }
                        } className='alert-strip'>
                            <h3>Alert Manager</h3>
                            <div className='alert-actions'>
                                <button onClick={
                                    () => {
                                        setOpened(true)
                                    }
                                } style={{
                                    width: "fit-content",
                                    padding: "0px 10px",
                                    paddingRight: "14px",
                                    marginRight: "30px"
                                }}><i className='material-icons-outlined'>notification_add</i> <span>New Alert</span></button>


                            </div>
                           
                        </div>
                        <Manager_Table />
                    </div>
                    : <h3>Data Unavailable</h3>
            }
            <style>
                {`
            .table-row {
                grid-template-columns: 45px 195px 187px 120px 90px 72px;
            }

            .activity-sort {
                justify-content: flex-start
            }

            .explore-page {
                display: grid;
    grid-template-rows: 102px 1fr;
  
    gap: 0px;
}
            }

            .activity-header {
                justify-content:center
            }

            .table-container {
                margin-top: 33px
                
            }

            .table-body {margin-left: 35px}

            .table-header {margin-left: 35px}

            `}
            </style>
        </>

    );


}

export default Manager;