
import React, { useRef, useEffect, useState } from 'react';
import { Accordion, Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { cancelOrder } from '../../../httpcalls/tastyThunk';
import Tracking_Accordion from './tracking_accordion';
import { calculateBreakeven, convertSymbol, calculatePOP, calculateChange, calculatePercentChange, calculateEstimatedAPRForTable, calculateToBreakeven, truncateToTwoDecimalPlaces } from '../../../helper_functions/stockCalculations';
import AuthContext from '../../../components/authContext';



const Tracking_Row = ({ item }) => {
    const dataFilter = useSelector(state => state.mainapp.dataSlice.dataFilterOrderTracking)

    const dispatch = useDispatch()
    const trackingMapData = useSelector(state => state.mainapp.trackingSlice.ordersMap[item])
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const month = date.toLocaleString('en-US', { month: '2-digit' });
        const day = date.toLocaleString('en-US', { day: '2-digit' });
        const year = date.toLocaleString('en-US', { year: 'numeric' });
        const time = date.toLocaleTimeString('en-US', { hour12: false });

        return `${month}/${day}/${year} - ${time}`;

    }
    const cancel = (orderId) => {
        dispatch(cancelOrder({ orderId: orderId }))
    }

    const getStatusColor = (status) => {
        return {
            'Filled': { backgroundColor: "#00C805" },
            'Cancelled': { backgroundColor: "#D82121" },
            'Expired': { backgroundColor: "#D81EBE" },
            'Routed': { backgroundColor: "#00BBF6" },
            'Live': { backgroundColor: "#F6DE00" }
        }[status]

    }

    const dxSymbol = (tastySymbol) => {
        if (tastySymbol.includes(' ')) {
            const symbol = convertSymbol(tastySymbol)
            return symbol
        }
        else {
            return tastySymbol
        }
    }

    const isOption = (dxSymbol) => {
        if (dxSymbol.includes('.')) {
            const callPutMatch = dxSymbol.match(/(\d{6})([CP])([\d.]+)$/);
            return callPutMatch[2] == 'P' ? 'Put' : 'Call';
        }
        else {
            return 'Stock'
        }
    }



    const finalRenderItem = (() => {
        const ISCANCELED = trackingMapData.status == 'Cancelled' || trackingMapData.status == 'Filled'
        const STATUS = trackingMapData.status.toUpperCase()
        const STATUSCOLOR = getStatusColor(trackingMapData.status)
        const ORDERID = trackingMapData.orderId
        const SYMBOL = dxSymbol(trackingMapData.legs[0].symbol)
        const TYPE = isOption(SYMBOL).toUpperCase()
        const ACTION = trackingMapData.legs[0].action.toUpperCase()
        const PRICE = trackingMapData.averagePrice == undefined ? parseFloat(trackingMapData.price) : parseFloat(trackingMapData.averagePrice)
        const CLOSEDBY = trackingMapData.closedBy == undefined ? 'N/A' : trackingMapData.closedBy
        const FILLEDCONTRACTS = trackingMapData.legs[0].quantity - trackingMapData.legs[0]['remaining-quantity']
        const TOTALCONTRACTS = trackingMapData.legs[0].quantity
        const FEES = parseFloat(trackingMapData.fees['total-fees'])


        // Accordian

        let optionData, stockPrice, BIDPRICE, ASKPRICE, MARK, STRIKEPRICE, OPTIONEXPIRATION, BREAKEVEN, POP, CHANGE,
            PERCENTCHANGE, INTRADAYLOW, INTRADAYHIGH, APR, TBE, VOLUME, VOLATILITY, STOCKPRICE, DISTANCE;


        stockPrice = trackingMapData.stockData.marketData.Trade.price

        if (TYPE != 'STOCK') {
            optionData = trackingMapData.optionData
            const buySell = ACTION.includes('BUY') ? 'buy' : 'sell'
            const callPut = TYPE == 'CALL' ? 'call' : 'put'
            STOCKPRICE = stockPrice || 0  // This is called by the eval


            BIDPRICE = optionData.eventTypes?.Quote?.bidPrice || 0
            ASKPRICE = optionData.eventTypes?.Quote?.askPrice || 0
            MARK = 0
            if (BIDPRICE != 0 || ASKPRICE != 0) {
                MARK = truncateToTwoDecimalPlaces((parseFloat(BIDPRICE) + parseFloat(ASKPRICE)) / 2, 3)
            }

            STRIKEPRICE = parseFloat(optionData.strikePrice)
            OPTIONEXPIRATION = optionData.expirationDate || 'N/A'
            BREAKEVEN = (calculateBreakeven(optionData.strikePrice, (optionData.eventTypes?.Quote?.askPrice || 0), callPut))
            POP = calculatePOP(optionData.eventTypes?.Greeks?.delta, callPut, buySell)
            CHANGE = calculateChange(MARK, optionData.eventTypes?.Summary?.prevDayClosePrice)
            PERCENTCHANGE = calculatePercentChange(MARK, optionData.eventTypes?.Summary?.prevDayClosePrice)
            INTRADAYLOW = optionData.eventTypes?.Summary?.dayLowPrice == "NaN" ? "N/A" : optionData.eventTypes?.Summary?.dayLowPrice || "N/A"
            INTRADAYHIGH = optionData.eventTypes?.Summary?.dayHighPrice == "NaN" ? "N/A" : optionData.eventTypes?.Summary?.dayHighPrice || "N/A"
            APR = calculateEstimatedAPRForTable(MARK, STRIKEPRICE, OPTIONEXPIRATION)
            TBE = calculateToBreakeven(BREAKEVEN, stockPrice || 0)
            VOLUME = optionData.eventTypes?.Trade?.dayVolume == "NaN" ? "N/A" : optionData.eventTypes?.Trade?.dayVolume
            VOLATILITY = truncateToTwoDecimalPlaces(optionData.eventTypes?.Greeks?.volatility * 100)
            DISTANCE = truncateToTwoDecimalPlaces((STRIKEPRICE - STOCKPRICE) / STOCKPRICE * 100)
        }
        else {
            STOCKPRICE = stockPrice || 0  // This is called by the eval

        }


        let DATE_VALUE = trackingMapData.creation_time || 'N/A'
        const DATE = (overload = DATE_VALUE) => {
            return new Date(overload.replace('_', ' '))
        }

        console.log(dataFilter)
        if (eval(dataFilter) || dataFilter == null) {
            return {
                isCanceled: ISCANCELED,
                status: STATUS,
                statusColor: STATUSCOLOR,
                orderId: ORDERID,
                symbol: SYMBOL,
                type: TYPE,
                action: ACTION,
                price: PRICE,
                closedBy: CLOSEDBY,
                filledContracts: FILLEDCONTRACTS,
                totalContracts: TOTALCONTRACTS,
                totalFees: FEES,
                date: DATE_VALUE,

                formatted: {
                    status: trackingMapData.status,
                    statusColor: STATUSCOLOR,
                    orderId: '#' + ORDERID,
                    symbol: SYMBOL,
                    type: isOption(SYMBOL),
                    action: trackingMapData.legs[0].action,
                    price: PRICE.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 }),
                    closedBy: CLOSEDBY == "N/A" ? CLOSEDBY : "#" + CLOSEDBY,
                    filledContracts: FILLEDCONTRACTS,
                    totalContracts: TOTALCONTRACTS,
                    contracts: FILLEDCONTRACTS + ' / ' + TOTALCONTRACTS,
                    totalFees: FEES.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 }),
                    accordion:

                        TYPE != 'STOCK' ? {

                            stock_price: parseFloat(STOCKPRICE).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 }),
                            strike_price: STRIKEPRICE !== 'N/A' ? STRIKEPRICE.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : STRIKEPRICE,
                            breakeven: BREAKEVEN !== 'N/A' ? BREAKEVEN.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : BREAKEVEN,
                            pop: POP !== 'N/A' ? POP + "%" : POP,
                            tbe: TBE !== 'N/A' ? TBE + "%" : TBE,
                            percent_change: PERCENTCHANGE !== 'N/A' ? PERCENTCHANGE.toFixed(2) + "%" : PERCENTCHANGE,
                            change: CHANGE !== 'N/A' ? CHANGE.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : CHANGE,
                            intraday_low: INTRADAYLOW !== 'N/A' ? INTRADAYLOW.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : INTRADAYLOW,
                            intraday_high: INTRADAYHIGH !== 'N/A' ? INTRADAYHIGH.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : INTRADAYHIGH,
                            apr: APR !== 'N/A' ? APR.toFixed(2) + "%" : APR,
                            mark: MARK !== 'N/A' ? MARK.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 }) : MARK,
                            volume: VOLUME,
                            volatility: VOLATILITY !== 'N/A' ? VOLATILITY + "%" : VOLATILITY,
                            distance: DISTANCE !== 'N/A' ? DISTANCE + "%" : DISTANCE,
                            fee: trackingMapData.fees,
                            option_expiration: OPTIONEXPIRATION
                             
                        }
                            :
                            {
                                stock_price: parseFloat(STOCKPRICE).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 }),
                                fee: trackingMapData.fees,
                            }
                    ,
                    date: formatTimestamp(DATE_VALUE),
                }
                // closestToStrike:isClosestToStrike
            }
        }
    })()



    useEffect(() => {
        console.log(item)
    }, [])


    return (
        finalRenderItem != undefined ?
            <>
                <div className='table-row-accordion'>
                <AuthContext access={['admin', 'trader']}>
                    <div className='rack-right table-icon'>
                        {
                            // TABLE ROW MENU
                        }
                    
                            <Menu position='right-start' shadow="md" width={200}>
                                <Menu.Target>
                                    <button><i className='material-icons'>more_horiz</i></button>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Label>Danger Zone</Menu.Label>
                                    {
                                        finalRenderItem.status == 'Cancelled' || finalRenderItem.status == 'Filled' ?
                                            <Menu.Item >Cancel Unavailable</Menu.Item> :
                                            <Menu.Item color="red" onClick={() => { cancel(finalRenderItem.orderId) }} icon={<i className='material-icons remove-icon'>cancel</i>}>Cancel Order</Menu.Item>
                                    }
                                </Menu.Dropdown>
                            </Menu>
              
                    </div>
                    </AuthContext>
                    <Accordion.Item value={finalRenderItem.orderId}>
                        {
                            // TABLE ROW OUTPUT
                        }
                        <Accordion.Control> <div className='table-row' style={{ borderBottom: 'none' }}>
                            <div></div>
                            <span className='status'>
                                <i style={finalRenderItem.statusColor} className='status-dot'></i>
                                {finalRenderItem.formatted.status}
                            </span>
                            <span className='orderId'>{finalRenderItem.formatted.orderId}</span>
                            <span className='symbol'>{finalRenderItem.formatted.symbol}</span>
                            <span className='type'>{finalRenderItem.formatted.type}</span>
                            <span className='action'>{finalRenderItem.formatted.action}</span>

                            <span className='averagePrice'>
                                {finalRenderItem.formatted.price}
                            </span>
                            <span className='closedBy'>{finalRenderItem.formatted.closedBy}</span>
                            <span className='contracts'>
                                {finalRenderItem.formatted.contracts}
                            </span>
                            <span className='fees'>
                                {finalRenderItem.formatted.totalFees}
                            </span>
                            <span className='date'>
                                {finalRenderItem.formatted.date}
                            </span>
                        </div></Accordion.Control>

                        {
                            // TABLE ROW ACCOR`DION
                        }
                        <Accordion.Panel>
                            <Tracking_Accordion data={finalRenderItem.formatted.accordion} type={finalRenderItem.type} />
                        </Accordion.Panel>
                    </Accordion.Item>
                </div>
            </>
            :
            null
    );



}

export default Tracking_Row;