import React, { useRef, useEffect, useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Accordion } from '@mantine/core';
import { Table } from '@mantine/core';


const TrackingLearn = (props) => {
    const navigate = useNavigate();

    const elements = [
        { position: 6, mass: 12.011, symbol: 'C', name: 'Carbon' },
        { position: 7, mass: 14.007, symbol: 'N', name: 'Nitrogen' },
        { position: 39, mass: 88.906, symbol: 'Y', name: 'Yttrium' },
        { position: 56, mass: 137.33, symbol: 'Ba', name: 'Barium' },
        { position: 58, mass: 140.12, symbol: 'Ce', name: 'Cerium' },
    ];

    const rows = elements.map((element) => (
        <tr key={element.name}>
            <td>{element.position}</td>
            <td>{element.name}</td>

        </tr>
    ));

    //"STOCKPRICE", 'VOLATILITY', 'DATE', 'DISTANCE', 'STRIKEPRICE', 'VOLUME', "BREAKEVEN", 'POP', "TBE", "PERCENTCHANGE", "CHANGE", "INTRADAYLOW", "INTRADAYHIGH", "APR", "MARK", "ORDERID", "STATUS", "SYMBOL", "TYPE", "ACTION", "PRICE", "CLOSEDBY", "FILLEDCONTRACTS", "TOTALCONTRACTS", "FEES", "DATE"
    const tradingData = [
        { columnName: 'Status', id: 'status' },
        { columnName: 'Order ID', id: 'orderid' },
        { columnName: 'Symbol', id: 'symbol' },
        { columnName: 'Type', id: 'type' },
        { columnName: 'Action', id: 'action' },
        { columnName: 'Price', id: 'price' },
        { columnName: 'Closed By', id: 'closedby' },
        { columnName: 'Filled Contracts', id: 'filledcontracts' },
        { columnName: 'Total Contracts', id: 'totalcontracts' },
        { columnName: 'Fees', id: 'fees' },
        { columnName: 'Stock Price', id: 'stockprice' },
        { columnName: 'Strike Price', id: 'strikeprice' },
        { columnName: 'Breakeven', id: 'breakeven' },
        { columnName: 'PoP', id: 'pop' },
        { columnName: 'TBE', id: 'tbe' },
        { columnName: 'Percent Change', id: 'percentchange' },
        { columnName: 'Change', id: 'change' },
        { columnName: 'Intraday Low', id: 'intradaylow' },
        { columnName: 'Intraday High', id: 'intradayhigh' },
        { columnName: 'APR', id: 'apr' },
        { columnName: 'Mark', id: 'mark' },
        { columnName: 'Volume', id: 'volume' },
        { columnName: 'Volatility', id: 'volatility' },
        { columnName: 'Distance', id: 'distance' },
        { columnName: 'Date - Column', id: 'date' },
        { columnName: 'Date - Func', id: 'date(mm/dd/yyyy_hh:mm:ss)' },
    ];

    const tradingRows = tradingData.map((data) => (
        <tr key={data.id}>
            <td>{data.columnName}</td>
            <td>{data.id}</td>
        </tr>
    ));

    const relationalOperators = [
        { name: 'Greater Than', syntax: '>' },
        { name: 'Less Than', syntax: '<' },
        { name: 'Equal To', syntax: '=' },
        { name: 'Not Equal To', syntax: '!=' },
        { name: 'Greater Than or Equal To', syntax: '>=' },
        { name: 'Less Than or Equal To', syntax: '<=' }
    ];

    const operatorRows = relationalOperators.map((operator) => (
        <tr key={operator.syntax}>
            <td>{operator.name}</td>
            <td>{operator.syntax}</td>
        </tr>
    ));

    const logicalOperators = [
        { name: 'AND', syntax: '&&', description: 'True if both conditions are true' },
        { name: 'OR', syntax: '||', description: 'True if at least one condition is true' },
        { name: 'NOT', syntax: '!', description: 'True if the condition is false' }
    ];

    const logicalOperatorRows = logicalOperators.map((operator) => (
        <tr key={operator.syntax}>
            <td>{operator.name}</td>
            <td>{operator.syntax}</td>

        </tr>
    ));

    return (
        <>
            <div className='learn-blocks'>
                <h1>Query Guide</h1>



                <div className='learn-block'>
                    <h2>Trading Data Columns</h2>
                    <p>This section details the column names and their corresponding IDs.</p>
                    <Accordion variant="contained" radius="md" >
                        <Accordion.Item value="customization">
                            <Accordion.Control>View All Trading Data Columns</Accordion.Control>
                            <Accordion.Panel>
                                <Table captionSide="bottom" verticalSpacing="lg">
                                    <thead>
                                        <tr>
                                            <th>Column Name</th>
                                            <th>ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>{tradingRows}</tbody>
                                    <caption>Trading Data Column IDs</caption>
                                </Table>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </div>



                <div className='learn-block'>
                    <h2>Relational Operators</h2>
                    <p>Relational operators are used to compare values using symbols like greater than ,less than, and more.</p>
                    <Accordion variant="contained" radius="md" >
                        <Accordion.Item value="customization">
                            <Accordion.Control>View All Relational Operators</Accordion.Control>
                            <Accordion.Panel>
                                <Table captionSide="bottom" verticalSpacing="lg">
                                    <thead>
                                        <tr>
                                            <th>Operator (Description)</th>
                                            <th>ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>{operatorRows}</tbody>
                                    <caption>Relational Operators with their Descriptions</caption>
                                </Table>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </div>



                <div className='learn-block'>
                    <h2>Logical Operators</h2>
                    <p>Logical operators are used to combine multiple conditions to derive a single result.</p>
                    <Accordion variant="contained" radius="md" >
                        <Accordion.Item value="customization">
                            <Accordion.Control>View All Logical Operators</Accordion.Control>
                            <Accordion.Panel>
                                <Table captionSide="bottom" verticalSpacing="lg">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Syntax</th>

                                        </tr>
                                    </thead>
                                    <tbody>{logicalOperatorRows}</tbody>
                                    <caption>Logical Operators with their Syntax</caption>
                                </Table>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </div>


                <div className='learn-block'>
                    <h2>Example Queries & Instructions</h2>
                    <p>Below are examples and instructions based on the trading data provided:</p>

                    <h3>1. Relational Operators:</h3>
                    <p>Relational operators help you compare specific data points within the trading information.</p>
                    <ul>
                        <li><b>strikeprice {">"} 100.00</b>: This will select all options with a strike price greater than $100.00.</li>
                        <li><b>percentchange {"<="} 10%</b>: This will select all options with a percent change of 10% or less.</li>
                        <li><b>id = .A240119P60</b>: This will select the specific option with ID '.A240119P60'.</li>
                    </ul>

                    <h3>2. Logical Operators:</h3>
                    <p>Logical operators help you combine multiple conditions in your queries.</p>
                    <ul>
                        <li><b>strikeprice {">"} 100.00 && percentchange {"<"} 5%</b>: This will select options with a strike price greater than $100.00 and a percent change less than 5%.</li>
                        <li><b>strikeprice {"<"} 90.00 || percentchange {">="} 20%</b>: This will select options with a strike price less than $90.00 or a percent change of 20% and above.</li>
                    </ul>

                    <h3>3. String Comparison</h3>
                    <p>String comparison allows you to compare text to text within columns.</p>
                    <ul>
                        <li><b>action {"="} "Buy To Close"</b>: This will select all orders that are are closed by buying back the object.</li>
                    </ul>

                    <h3>4. Date Comparison</h3>
                    <p>Date comparison allows you to sort by date and time. Time is in military.</p>
                    <ul>
                        <li><b>date {">"} date(10/25/2023_09:09:27)</b>: This will select all orders that occurred after 10/25/2023 09:09:27.</li>
                        <li><b>date {">"} date(10/25/2023)</b>: This will select all orders that occurred after 10/25/2023 00:00:00.</li>
                    </ul>

                </div>

            </div>
        </>
    );

}

export default TrackingLearn;