import { useDispatch } from "react-redux";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import { useState } from "react";
import Management from "./management/management";
const ModalController = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState(false);
    

    return (
        <>
            <div id="modal" className="modal-container">
                <div className="modal-wrapper vivify fadeInBottom duration-300">
                    <div className="modal-navbar">
                        <button
                            aria-label="Close Modal"
                            onClick={
                                () => {
                                    props.setModalToggle(false)
                                }
                            }
                            className="modal-close"
                        >
                            <i className="material-icons" aria-hidden="true">close</i>
                        </button>
                      
                    </div>
                 
                    <Management />
                </div>
            </div>
            <style>
                {`
                    body {
                        overflow: hidden;
                    }

                    .modal-navbar > lord-icon {
                        width: 545px;
                        height: 495px;
                        opacity: 0.2;
                        margin-right: -105px;
                        margin-top: -80px;
                    }

                    .modal-title {
                        margin-top: -344px;
                    }

                    .submit-button {
                        background-color: #F14B00;
                    }
                `}
            </style>
        </>
    );
}

export default ModalController;
