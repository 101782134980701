import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Manage_Table from './manage_table';
import { Modal } from '@mantine/core';
import Manage_Modal from './manage_modal';
import { Menu } from '@mantine/core';

const Management = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [opened, setOpened] = useState(false)
    const close = () => setOpened(false)



    return (
        <>

            <Modal overlayProps={{

                opacity: 0.55,
                blur: 7,
            }} centered radius={"lg"} size="auto" withCloseButton={false} title={<div style={{ borderBottom: "none" }} className='modal-header'>
                <button style={
                    {
                        opacity: '0',
                        cursor: 'default'
                    }
                }></button>
                <h3></h3>
                <button onClick={
                    close
                }><i className='material-icons'>close</i></button>
            </div>} opened={opened} onClose={close} >

                <Manage_Modal edit={false} />

            </Modal>

            <div style={{ gridTemplateRows: "1fr !important" }} className='nav-page user-page vivify fadeIn duration-300 delay-200'>

                <h1 className='modal-hero-title modal-hero-group'>
                    User Management

                    <div className='modal-hero-actions'>
                        <button onClick={
                            () => {
                                setOpened(true)
                            }
                        }><i className='material-icons'>person_add</i>Add User</button>

                        <Menu position='bottom-end' shadow="md" width={200}>
                            <Menu.Target>
                            <button><i className='material-icons'>flip_camera_android</i>Swap Organization</button>
                            </Menu.Target>
                            <Menu.Dropdown>



                                <Menu.Item >Edrbreak</Menu.Item>

                                <Menu.Item >Aspiria</Menu.Item>

                            

                            </Menu.Dropdown>
                        </Menu>
                     
                    </div>
                </h1>

                <Manage_Table />
            </div>


        </>

    );


}

export default Management;