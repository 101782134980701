import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Explore from './explore';
import { ExploreRowProvider } from './explore_row_context';

const ExploreWrapper = () => {
    const { stockid } = useParams();
    return (
    <ExploreRowProvider>    
        <Explore key={stockid} />

    </ExploreRowProvider>
    )

}


export default ExploreWrapper;