import React, { useRef, useEffect, useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { getAccountPositions, getOrders } from '../../../httpcalls/tastyThunk';
import Masonry from 'react-masonry-css';
import { Menu } from '@mantine/core';
import W_RackCard from './components/w_rackcard';
import W_RefreshButton from './components/w_refreshbutton';
import { useRefresh } from './components/w_refreshcontext';
import { store } from '../../../store/store';
import { sendMessage } from '../../../websocket/websocket';


const Wheel = () => {
    const dispatch = useDispatch()

    const refreshTimer = useSelector(state => state.mainapp.dataSlice.wheelRack.refresh)
    const {refresh} = useRefresh()



    const stocks = useSelector((state) => state.mainapp.stockSlice.stocks.activeStocks)
    const images = [
        { src: 'https://media.wired.com/photos/5a55457ef41e4c2cd9ee6cb5/master/w_2560%2Cc_limit/Doggo-TopArt-104685145.jpg', height: 100 },
        { src: 'https://outwardhound.com/furtropolis/wp-content/uploads/2020/03/Doggo-Lingo-Post.jpg', height: 200 },
        { src: 'https://i.redd.it/7turwx10r3k61.png', height: 250 },
        { src: 'https://i.redd.it/7turwx10r3k61.png', height: 250 },
        { src: 'https://i.redd.it/7turwx10r3k61.png', height: 250 },
        { src: 'https://i.redd.it/7turwx10r3k61.png', height: 250 },
        // etc
    ];

    const [totalBtcId, setTotalBtcId] = useState([]);
    const [totalBtc, setTotal] = useState(0);


    const updateTotal = (totalBtcIdCopy) => {
        let total = 0
        for (let stockId in totalBtcIdCopy) {
            total += totalBtcIdCopy[stockId]
        }
        setTotal(total)
    }

    useEffect(() => {
        const state = store.getState()
        const stockData = state.mainapp.stockSlice.stocks
        
        setTotalBtcId(state => {
            let totalBtcIdCopy = state
            
            stocks.map((stockId) => {
                if(stockData[stockId] != undefined){
                    if (stockData[stockId].activeListeners.includes('Rack') && totalBtc[stockId] == undefined) {
                        totalBtcIdCopy[stockId] = 0
                    }
                }
                
            })
            updateTotal(totalBtcIdCopy)
            return totalBtcIdCopy
        })
    }, [stocks])

    const handleBtcChange = (stockId, value) => {

        setTotalBtcId(state => {
            let totalBtcIdCopy = state
            totalBtcIdCopy[stockId] = value
            updateTotal(totalBtcIdCopy)
            return totalBtcIdCopy
        });
    };

    useEffect(() => {
        dispatch(getAccountPositions({listenType:"Rack"}))
    }, [refreshTimer])



    useEffect(() => {
        refresh()
        return (() => {
            const state = store.getState();
            const activeStocks = state.mainapp.stockSlice.stocks.activeStocks;
            let optionsToRemove = [];

            for (let stockid of activeStocks) {
                const currentStock = state.mainapp.stockSlice.stocks[stockid];
                if (currentStock && currentStock.activeListeners.includes('Rack')) {
                    for (let item of currentStock.stockOptions) {
                        const option = currentStock.stockOptionsMap[item.symbol];
                        if (option && option.activeListeners.includes('Reack')) {
                            optionsToRemove.push({
                                symbol: item.symbol,
                                stockSymbol: stockid
                            });
                        }
                    }
                    optionsToRemove.push(stockid);
                }
            }
            sendMessage({ action: "removeStock", listenType: 'Rack', item: optionsToRemove });
        })


    }, [])

    return (
        <>
            <div className='action-menu'>
                <p>Total BTC: {totalBtc.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 })}</p>
            </div>
            <div className='wheel-rack-container'>
                <header style={{ position: "sticky", top: "0px" }} className='activity-header'>

                    <W_RefreshButton style={"head"} type={"Rack"} />

                </header>
                <Masonry className="my-masonry-grid" breakpointCols={3} columnClassName="my-masonry-grid_column">

                    {
                    (() => {
                        const state = store.getState()
                        const stockData = state.mainapp.stockSlice.stocks
                        return (
                            stocks.map((stockId) => {
                                if (stockData[stockId].activeListeners.includes('Rack')) {
                                    return (
                                        <W_RackCard style={"default"} stockId={stockId} onBtcChange={handleBtcChange} />
                                    )
                                }
                            })
                        )

                    })()
                       
                    }



                </Masonry>
            </div>
        </>


    );


}

export default React.memo(Wheel);