import React, { useState, useEffect, useRef } from 'react';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { calculatePercentChange, calculatePercentageChange } from '../../../../helper_functions/stockCalculations';
import W_RefreshButton from '../../../yourstocks/rack/components/w_refreshbutton';
import { useRefresh } from '../../../yourstocks/rack/components/w_refreshcontext';
import { store } from '../../../../store/store';
import { sendMessage } from '../../../../websocket/websocket';
import { useFetch } from 'usehooks-ts';
import { notifications } from '@mantine/notifications';
import W_Single_Card from './w_single_card';
import { useLocalStorage } from 'usehooks-ts';

const W_Single_Stock_Container = ({ id, themeColor, icon, name, removeWidget, uniqueId, canvas, removeCanvasWidget }) => {
    const localStorageKey = `stockTracking-${uniqueId}`;

    const [stockTracking, setStockTracking] = useLocalStorage(localStorageKey, '');

    const stockData = useSelector((state) => state.mainapp.stockSlice?.stocks[stockTracking]?.marketData)
    const stockName = useSelector((state) => state.mainapp.stockSlice?.stocks[stockTracking]?.description)
    const stockTrackingRef = useRef(stockTracking);

    useEffect(() => {
        stockTrackingRef.current = stockTracking;  // Update the ref whenever stockTracking changes
        console.log(themeColor)
    }, [stockTracking]);

    useEffect(() => {
        return () => {
            if (stockTrackingRef.current !== '') {
                sendMessage({ action: "removeStock", listenType: uniqueId, item: [stockTrackingRef.current] });
            }
        };
    }, [])

    const permaRemove = () => {
        removeWidget(id);
        localStorage.removeItem(`stockTracking-${uniqueId}`)
    }







    useEffect(() => {
        if (stockTracking == '') return
        sendMessage({ action: "addStock", item: [{ streamer: stockTracking }], listenType: uniqueId })
    }, [stockTracking])

    const handleEditStock = () => {
        let stockName = window.prompt('Enter Stock Ticker');
        if (stockName && stockName !== '') {
            let stockItem = stockName.replace(/[^a-zA-Z]/g, '');
            stockItem = stockItem.toUpperCase()
            if (stockTracking != '') {
                sendMessage({ action: "removeStock", listenType: uniqueId, item: [stockTracking] });
            }
            setStockTracking(stockItem);

        }
    };


    return (
        <>
            <div className='widget vivify fadeIn duration-300 '>

                <div className='widget-title'>
                    <div className='widget-title-left'>
                        <i style={
                            {
                                color: themeColor || 'var(--main-color)'
                            }
                        } className='material-icons'>{icon}</i>
                        {
                            stockData != undefined ? (
                                <>
                                    <h3>{stockName}</h3>
                                </>) : <h3>{name}</h3>
                        }
                    </div>
                    <div className='widget-title-right'>
                        <Menu position='top-end' shadow="md" width={200}>
                            <Menu.Target>
                                <button><i className='material-icons'>more_horiz</i></button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Widget Settings</Menu.Label>
                                <Menu.Item
                                    icon={<i style={
                                        {
                                            color: themeColor || 'var(--main-color)'

                                        }
                                    } className='material-icons'>delete</i>}
                                    onClick={() => {
                                        canvas ? removeCanvasWidget(uniqueId) : permaRemove()
                                    }}
                                >
                                    Remove Widget
                                </Menu.Item>
                                <Menu.Label>Edit Stock</Menu.Label>
                                <Menu.Item
                                    icon={<i style={
                                        {
                                            color: themeColor || 'var(--main-color)'

                                        }
                                    } className='material-icons'>edit</i>}
                                    onClick={() => {
                                        handleEditStock()
                                    }}
                                >
                                    Edit Stock
                                </Menu.Item>



                            </Menu.Dropdown>
                        </Menu>
                    </div>
                </div>
                <div className='widget-contents'>

                    {
                        stockData != undefined ? (
                            <W_Single_Card color={themeColor} name={stockData.stockName == undefined ? 'Name Not Found' : stockData.stockName} stockMarketData={stockData} />
                        ) :

                            (stockTracking != '') ?

                                <div className='widget-load-container'>
                                    <div class="indeterminate-progress-bar"><div style={{ backgroundColor: themeColor, transition: "0.1s ease" }} class="indeterminate-progress-bar__progress"></div></div>
                                    <span className='load-subtext' style={{ fontSize: "11px", textAlign: "center" }}>Pulling Data From Tasty Trade...</span>
                                </div>

                                :

                                <div className='widget-setup-btn-wrapper'>
                                    <button className='widget-setup-btn' onClick={
                                        () => {
                                            handleEditStock()
                                        }
                                    }><i style={
                                        {
                                            color: themeColor || 'var(--main-color)'
                                        }
                                    } className='material-icons'>add</i>Add Stock</button>
                                    <p>Add Stock Ticker To Setup Widget</p>
                                </div>

                    }

                </div>
                {/*   {
                    stockData && stockData.stockName ? (
                        <>
                            <div className='widget-divider'></div>
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                <W_RefreshButton color={themeColor} style={'immersive'} type={id} />
                            </div>
                        </>

                    ) : null
                } */}

            </div>
        </>
    );
};

export default W_Single_Stock_Container;
