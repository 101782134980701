import { notifications } from "@mantine/notifications";
import { alterWebSocketClientId } from "../store/schemas/authSlice";
import { store } from "../store/store";
import { addStockOptionsMap, addStockOptionsMapAndArray, addStockSymbol, alterStockBothMapAndArray, alterStockOptions, alterStockOptionsMap, alterStockSocketData, overWriteOrders, removeStockOptions, removeStockSymbol, updateStockBothMapAndArray, updateStockOptions, updateStockOptionsMap, updateStockSocketData, updateStockSymbolDescription } from "../store/schemas/stockSlice";
import { v4 as uuidv4 } from 'uuid';
import { getSymbolOptionsOverview, searchSymbol } from "../httpcalls/tastyThunk";

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // Delay in milliseconds

let ws;
let clientId = null;

let retries = 0



let dataQueue = [];
let debounceTimer = null;
const DEBOUNCE_DELAY = 500; // 500ms for example

function onDataReceived(newData) {
    // Accumulate incoming data
    dataQueue.push(...structuredClone(newData));

    // If the timer isn't running, start it
    if (!debounceTimer) {
        debounceTimer = setInterval(processAccumulatedData, DEBOUNCE_DELAY);
    }
}

function processAccumulatedData() {
    // If there's no data, just return
    if (dataQueue.length === 0) return;

    // Move the dataQueue content to a processing array and clear dataQueue
    const dataToProcess = [...structuredClone(dataQueue)];
    dataQueue = [];

    manageStockData(dataToProcess)
    // Process the data in dataToProcess
    // ... Your logic here ...

    // After processing, check if new data has arrived during the processing time.
    if (dataQueue.length === 0) {
        // Clear the timer if no new data is left to process
        clearInterval(debounceTimer);
        debounceTimer = null;
    } 
}



const manageStockData = (data) => {
        const state = store.getState()
        let stockOptionsMapToUpdate = {}
        let stocksToUpdate = {}
        for (let option of data) {
           
            if(!option.eventSymbol.includes('.')){
                if(stocksToUpdate[option.eventSymbol] == undefined){
                    stocksToUpdate[option.eventSymbol] = {
                        Quote:null,
                        Trade:null,
                        Profile:null,
                        Greeks:null,
                        Summary:null
                    }
                }
                stocksToUpdate[option.eventSymbol][option.eventType] = option
            }
            else{
                const stockSymbol = option.eventSymbol.match(/\.([A-Za-z]+)/)[1];
                const existingItem = state.mainapp.stockSlice?.stocks[stockSymbol]?.stockOptionsMap[option.eventSymbol];
                if (existingItem) {
                    if(!stockOptionsMapToUpdate[option.eventSymbol]){
                        stockOptionsMapToUpdate[option.eventSymbol] = {}
                    }
                  
                    stockOptionsMapToUpdate[option.eventSymbol][option['eventType']] = option
                    stockOptionsMapToUpdate[option.eventSymbol].stockSymbol = stockSymbol
                } 
            }
            

        }
        if( Object.keys(stocksToUpdate).length != 0){
            store.dispatch(updateStockSocketData({stocksToUpdate:stocksToUpdate}))
        }
        if(Object.keys(stockOptionsMapToUpdate).length != 0){
            store.dispatch(updateStockOptionsMap({toUpdate:stockOptionsMapToUpdate}))
        }
}



const initializeWebSocket = () => {
    const wsURL = process.env.REACT_APP_WSS;
    const state = store.getState()
    if(state.auth.webSocketClientId == null){
        clientId = state.auth.userInfo.uid + uuidv4()
        store.dispatch(alterWebSocketClientId(clientId));
    }

    ws = new WebSocket(`${wsURL}?clientId=${clientId}`);
  
    ws.onopen = () => {
        notifications.show({
            title: "Realtime Connection Established", message: "Realtime Connection Established", icon: <i className='material-icons-outlined' style={{ backgroundColor: 'var(--main-color)' }}>cloud_done</i>,
            withCloseButton: false
        });

    };

    ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.ping) {
            ws.send(JSON.stringify({ pong: true }));
            return;
        }
        if (Array.isArray(data)) {
            onDataReceived(data)
        }
    };

    ws.onerror = (error) => {
        // notifications.show({ title: "Realtime Streaming Down", message: "The connection to realtime data is down." });
        // You might want to reject the promise here to go into retry logic
        // reject(error);
    };

    ws.onclose = (event) => {
        notifications.show({ title: "Realtime Connection Closed", message: "The connection to realtime data was closed." });
        if (!event.wasClean) {
            if (MAX_RETRIES > retries)
                setTimeout(() => {
                    retries++;
                    initializeWebSocket();
                }, RETRY_DELAY);
        }
    };

};


const killWebSocket = () => {
    if (ws) {
        ws.close();
    }
};

const checkReduxThenSendMessage = async (messageObj) => {
    let stockOptions = []
    let stocks = []
    let stocksWs = []
    let stockOptionsWs = []

    const state = store.getState()
    const stockState =  state.mainapp.stockSlice.stocks

    if(messageObj.action == 'addStock'){
        // Make code better @damion
        if(messageObj.item){
            for(let item of messageObj.item){
                if(item.streamer.includes('.')){  // Stock Option
                    stockOptionsWs.push(item.streamer)
                    stockOptions.push(item)
                }
                else{ // Stock
                    if(stockState[item.streamer] == undefined){
                        const results = await Promise.allSettled([store.dispatch(searchSymbol({ symbol: item.streamer })), store.dispatch(getSymbolOptionsOverview({ symbol: item.streamer }))])
                        // const res = await store.dispatch(searchSymbol({ symbol: item.streamer }))
                        stocksWs.push(item.streamer)
                        if(results[0].value.type == "auth/searchSymbol/fulfilled"){
                            stocks.push({ symbol: results[0].value.payload.symbol, description: results[0].value.payload.results[0].description, dates: results[1].value.payload.dates, optionsContracts:results[1].value.payload.optionsContracts })
                        }
                        else{
                            stocks.push({ symbol: results[1].value.payload.symbol, description: results[1].value.payload.results[0].description, dates: results[0].value.payload.dates, optionsContracts:results[0].value.payload.optionsContracts })
                        }
                      
                    }
                    else{
                        // stocksWs.push(item.streamer)
                        stocks.push({ symbol: item.streamer})
                    }
                }
            }
        }
       

        if(messageObj.stockShares != undefined){
            for(let stockSymbol in messageObj.stockShares){
                if(stockState[stockSymbol] == undefined){
                    const results = await Promise.allSettled([store.dispatch(searchSymbol({ symbol: stockSymbol })), store.dispatch(getSymbolOptionsOverview({ symbol: stockSymbol }))])
                    // const res = await store.dispatch(searchSymbol({ symbol: item.streamer }))
                    stocksWs.push(stockSymbol)
                    if(results[0].value.type == "auth/searchSymbol/fulfilled"){
                        stocks.push({ symbol: results[0].value.payload.symbol, description: results[0].value.payload.results[0].description, dates: results[1].value.payload.dates, optionsContracts:results[1].value.payload.optionsContracts })
                    }
                    else{
                        stocks.push({ symbol: results[1].value.payload.symbol, description: results[1].value.payload.results[0].description, dates: results[0].value.payload.dates, optionsContracts:results[0].value.payload.optionsContracts })
                    }
                  
                }
            }
        }

        console.log(messageObj)
        if(stocks.length != 0){
           await store.dispatch(addStockSymbol({stocks:stocks, listenType:messageObj.listenType}))
        }
        if(messageObj.orders != undefined || messageObj.stockShares != undefined){
            store.dispatch(overWriteOrders({orders:messageObj.orders, stockShares:messageObj.stockShares, listenType:messageObj.listenType}))
        }
        if(stockOptions.length != 0){
            store.dispatch(addStockOptionsMap({optionsMap:stockOptions, listenType:messageObj.listenType}))
        }
    }
    else if('removeStock'){
        for(let symbol of messageObj.item){
            if(symbol.stockSymbol != undefined){  // Stock Option
                if(stockState[symbol.stockSymbol].stockOptionsMap[symbol.symbol].activeListeners.length == 1){
                    stocksWs.push(symbol.symbol)
                }
                stockOptions.push({stockSymbol:symbol.stockSymbol, symbol:symbol.symbol })
            }
            else{ // Stock
                if(stockState[symbol] != undefined){
                    if(stockState[symbol].activeListeners.length == 1){
                        stocksWs.push(symbol)
                    }
                    stocks.push(symbol)
                }
            }
        }
        if(stockOptions.length != 0 ){
            store.dispatch(removeStockOptions({options:stockOptions, listenType:messageObj.listenType}))
        }
        if(stocks.length != 0){
            store.dispatch(removeStockSymbol({stocks:stocks, listenType:messageObj.listenType}))
        }

    }
    ws.send(JSON.stringify({action:messageObj.action, item:[...stockOptionsWs, ...stocksWs]}));
}

const sendMessage = (messageObj) => {
    const sendWhenReady = (retry = false) => {
        if (ws.readyState === WebSocket.OPEN) {
            checkReduxThenSendMessage(messageObj)
        } else if (retry) {
            setTimeout(() => sendWhenReady(), 1000);
        } else {
            console.error('WebSocket is not open. Can\'t send message.');
        }
       
    };

    if (typeof ws !== 'undefined') {
        sendWhenReady(true);
    } else {
        setTimeout(() => sendMessage(messageObj), 1000);
    }
};


export { initializeWebSocket, killWebSocket, sendMessage };
