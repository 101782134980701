import { useEffect, useState } from "react";
import '../App.css';
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { finalizeResetPassword, forgotPasswordSessionEnd, logout, updatePassword } from "../httpcalls/authThunk";

const ConfirmResetPassword = () => {
  const userActive = useSelector(state => state.auth.userActive)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const session = searchParams.get('s')
  useEffect(() => {
    if(userActive == false && session == undefined){
      navigate('/', {replace:true})
    }
  })

  const submitPasswordReset = (e) => {
    const password = e.target.elements['password'].value
    dispatch(forgotPasswordSessionEnd({session:session, password:password}))
  }

  const updatePasswordReset = (e) => {
    const password = e.target.elements['password'].value
    dispatch(updatePassword({password:password}))
  }

  const logoutUser = () => {
    dispatch(logout())
  }

  return (

    <>

      <div className="login-container login-back " >
      {userActive == false ?
        <div className="login vivify fadeIn duration-300">
          <div className="login-title vivify fadeIn duration-300">
            <h1>Create New Password</h1>
            <p>Recover your account password</p>
          </div>

          <div className="login-form vivify fadeIn duration-300">
            <form onSubmit={(e) => { e.preventDefault(); submitPasswordReset(e) }}>
              <div className="login-form-input">
                <label htmlFor="password">Password</label>
                <input type="password" placeholder="Enter Your New Password" name="password" id="password" />
              </div>



              <button type="submit">Reset Password</button>

              <p>By Clicking “Reset Password” You Agree To Our <span>Terms & Conditions</span></p>
            </form>

          </div>

          <div className="login-footer vivify fadeIn duration-300">
              <p>Don't have an account? <Link to={"/signup"}> Sign Up</Link></p>
          </div>


        </div>
      :

      <div className="login vivify fadeIn duration-300">
          <div className="login-title vivify fadeIn duration-300">
            <h1>Update Password</h1>
            <p>Create a new secure password</p>
          </div>

          <div className="login-form vivify fadeIn duration-300">
            <form onSubmit={(e) => { e.preventDefault(); updatePasswordReset(e) }}>
              <div className="login-form-input">
                <label htmlFor="password">Password</label>
                <input type="password" placeholder="Enter Your New Password" name="password" id="password" />
              </div>



              <button type="submit">Update Password</button>

              <p>By Clicking “Update Password” You Agree To Our <span>Terms & Conditions</span></p>
            </form>

          </div>

          <div className="login-footer vivify fadeIn duration-300">
              <button type="button" onClick={() => {logoutUser()}}>Logout</button> 
          </div>


        </div>

      }
        
      </div>

    </>
  );
}

export default ConfirmResetPassword;