import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { calculateMark, calculateTotalBTC } from '../../../../helper_functions/stockCalculations';

const W_Btc = ({orderId, stockId, setBtcUpdate }) => {
    const order = useSelector(state => state.mainapp.stockSlice.stocks[stockId].orderData[orderId])
    const optionData = useSelector(state => state.mainapp.stockSlice.stocks[stockId].stockOptionsMap[order.dxSymbol])

    const calculateDelta = () => {
        setBtcUpdate(state => !state)
    }

    useEffect(() => {
        calculateDelta()
    }, [order, optionData])
};

export default React.memo(W_Btc);

