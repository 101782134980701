import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Checkbox, Select } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { getMirrorInfo, updateMirrorInfo } from '../../../httpcalls/mirrorThunk';
import { updateMirror } from '../../../store/schemas/settingSlice';

const Mirror = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const mirrorInfo = useSelector(state => state.mainapp.settingSlice.mirror)
    const [mirrorCopy, setMirrorCopy] = useState(null)

    useEffect(() => {
        if(mirrorCopy == null){
            setMirrorCopy(mirrorInfo)
        }
    }, [mirrorInfo])

    
    useEffect(() => {
        dispatch(getMirrorInfo())
    }, [])


    const updateStatus = (e) => {
        if(e.target.checked){
            setMirrorCopy(state => {
                return {...mirrorInfo, status:e.target.value}
            })
        }
    }

    const saveMirror = () => {
        let mirrorFix = {...mirrorCopy}

        if(mirrorFix.blacklist == ''){
            mirrorFix.blacklist = []
        }else{
            mirrorFix.blacklist = mirrorFix.blacklist.toUpperCase()
            mirrorFix.blacklist = mirrorFix.blacklist.replace(/\s/g, '')
            mirrorFix.blacklist = mirrorFix.blacklist.split(',')
        }

        if(mirrorFix.status == 'leader'){
            if(mirrorFix.leader_name == null || mirrorFix.leader_name == ''){
                notifications.show({
                    title: 'Error',
                    message: 'Please enter a leader name.',
                    icon: <i style={{ backgroundColor: 'red', color: "black" }} className='material-icons-outlined'>error</i>,
                    withCloseButton: false,
                    autoClose: 5000,
                })
                return
            }
        }
        // console.log(mirrorInfo.follower_selection.find((item) => item.mirror_uid  == mirrorFix.follow_mirror_uid) == undefined)
        if(mirrorFix.status == 'follower'){
            if(mirrorFix.follow_mirror_uid == null || mirrorInfo.follower_selection.find((item) => item.mirror_uid  == mirrorFix.follow_mirror_uid) == undefined){
                notifications.show({
                    title: 'Error',
                    message: 'Please select a leader to follow.',
                    icon: <i style={{ backgroundColor: 'red', color: "black" }} className='material-icons-outlined'>error</i>,
                    withCloseButton: false,
                    autoClose: 5000,
                })
                return

            }
        }
           

        dispatch(updateMirrorInfo({mirror:mirrorFix})).then(() => {
            notifications.show({
                title: 'Saved Successfully',
                message: 'Your changes have been saved.',
                icon: <i style={{ backgroundColor: 'var(--main-color)', color: "black" }} className='material-icons-outlined'>done</i>,
                withCloseButton: false,
                autoClose: 5000,
            })
        })
        
    }

    const followUser = (value) => {
        setMirrorCopy(state => {
            return {...state, follow_mirror_uid:value}
        })
    }

    const updateBlacklist = (e) => {
        try {
            setMirrorCopy(state => {
                return {...state, blacklist:e.target.value}
            })
        } catch (error) {
            console.log(error)
        }
    }

    const updateMirrorBuyingPowerMatch = (e) => {
        setMirrorCopy(state => {
            return {...state, mirror_if_enough_buying_power:e.target.checked}
        })
    }

    const updateLeaderName = (e) => {
        setMirrorCopy(state => {
            return {...state, leader_name:e.target.value}
        })
    }

    const saveStatus = useMemo(() => {
        if(mirrorCopy != null && mirrorInfo != null){
            if(JSON.stringify(mirrorCopy) != JSON.stringify(mirrorInfo)){
                return true
            }
        }
    }, [mirrorCopy, mirrorInfo])



    const handleSubmit = (e) => {
        e.preventDefault()
    }


    return (
        mirrorCopy != null ?
        <>
            <div className='settings-page'>
                <form onSubmit={(e) => {handleSubmit(e)}} className='setting-chunk' autoComplete='off'>
                    <h3 className='settings-title'>
                        Mirror Trading Setup
                        <button style={saveStatus ? {} : {color:'var(--frosted-text)', pointerEvents:"none"}}  onClick={
                            () => {
                                saveMirror()
                            }
                        }  className={
                            'minimal-btn'
                        }>Save Changes</button>
                    </h3>

                    <div className='settings-item'>
                        <div className='check-bind'>
                            <input
                                type="radio"
                                id="leader"
                                name="mirror"
                                value="leader"
                                onChange={(e) => { updateStatus(e) }}
                                checked={mirrorCopy.status == 'leader' ? true : false}
                            />
                            <label htmlFor="leader">Leader: Permit other traders to mirror my trades.</label>
                        </div>
                        <div style={{ flexDirection: "column", gap: "10px" }} className='action-form-input'>
                            <input
                                type="text"
                                name="mirror"
                                defaultValue={mirrorCopy.leader_name}
                                placeholder='Create Trader User Name*'
                                onChange={(e) => { updateLeaderName(e) }}
                            />
                        </div>
                    </div>

                    <div className='settings-item'>
                        <div className='check-bind'>
                            <input
                                type="radio"
                                id="follower"
                                name="mirror"
                                value="follower"
                                onChange={(e) => { updateStatus(e) }}
                                checked={mirrorCopy.status == 'follower' ? true : false}
                            />
                            <label htmlFor="follower">Follower: Mirror trades from other traders.</label>
                        </div>

                        <Select 
                            onChange={(e) => { followUser(e) }}
                            placeholder={mirrorCopy.follow_mirror_uid != null ? "Leader Not Active" : "Select Leader*"}
                            searchable
                            nothingFound="No options"
                            defaultValue={mirrorCopy.follow_mirror_uid}
                            data={mirrorInfo.follower_selection.map((item) => {
                                return {
                                    value: item.mirror_uid,
                                    label: item.leader_name
                                }
                            })}
                        />
                        <div className='settings-mirror-extra-options'>
                            <h4>Options</h4>
                            <div style={{ flexDirection: "column", gap: "10px" }} className='action-form-input'>
                                <label htmlFor="blacklist">Blacklist tickers</label>
                                <input
                                        onChange={(e) => { updateBlacklist(e) }}
                                        type="text"
                                        id="blacklist"
                                        defaultValue={mirrorCopy.blacklist}
                                        placeholder='EX: AMC, GME, BB'
                                    />
                            </div>
                            <div style={{ flexDirection: "row", gap: "10px",alignItems:'center', justifyContent:'flex-start' }} className='action-form-input'>
                                <Checkbox 
                                    onChange={(e) => { updateMirrorBuyingPowerMatch(e) }}
                                    size={'xs'} 
                                    defaultChecked={mirrorCopy.mirror_if_enough_buying_power}
                                    id='mirror-checkbox-enough-buying'
                                />
                                <label htmlFor="mirror-checkbox-enough-buying">Mirror only if exact buying power of a trade is available</label>
                            </div>
                        </div>
                           
                    </div>

                    <div className='settings-item'>
                        <div className='check-bind'>
                            <input
                                type="radio"
                                id="disabled"
                                name="mirror"
                                value="disabled"
                                onChange={(e) => { updateStatus(e) }}
                                checked={mirrorCopy.status == 'disabled' ? true : false}
                            />
                            <label htmlFor="disabled">Disabled</label>
                        </div>
                    </div>
                </form>
            </div>
        </>
        :
        null
    );
}

export default Mirror;