import React, { useState, useEffect, useRef } from 'react';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { calculatePercentageChange } from '../../../../helper_functions/stockCalculations';
import W_RefreshButton from '../../../yourstocks/rack/components/w_refreshbutton';
import { useRefresh } from '../../../yourstocks/rack/components/w_refreshcontext';
import W_Stocks_Card from './w_stocks_card';
import { store } from '../../../../store/store';
import { sendMessage } from '../../../../websocket/websocket';
import { getStockWidget } from '../../../../httpcalls/tastyThunk';

const W_Stocks_Container = ({ id, themeColor, icon, name, removeWidget, uniqueId, canvas, removeCanvasWidget }) => {
    const stockOverviews = useSelector(state => state.mainapp.stockSlice.stocks)
    const dispatch = useDispatch()
    const { refresh } = useRefresh()

    const refreshTimer = useSelector(state => state.mainapp.dataSlice.wheelRack.refresh)
    useEffect(() => {
        dispatch(getStockWidget({ listenType: 'yourstocks' }))
    }, [refreshTimer])

    const { days, hours, minutes, seconds } = useSelector(state => state.mainapp.dataSlice.wheelRack.time);
    const renderElapsedTime = () => {
        if (days > 0) return `${days} day(s) ago`;
        if (hours > 0) return `${hours} hour(s) ago`;
        if (minutes > 0) return `${minutes} minute(s) ago`;
        return `${seconds} second(s) ago`;
    };

    const permaRemove = () => {
        const state = store.getState();
        const activeStocks = state.mainapp.stockSlice.stocks.activeStocks;
        let optionsToRemove = [];

        for (let stockid of activeStocks) {
            const currentStock = state.mainapp.stockSlice.stocks[stockid];
            if (currentStock && currentStock.activeListeners.includes(id)) {
                for (let item of currentStock.stockOptions) {
                    const option = currentStock.stockOptionsMap[item.symbol];
                    if (option && option.activeListeners.includes(id)) {
                        optionsToRemove.push({
                            symbol: item.symbol,
                            stockSymbol: stockid
                        });
                    }
                }
                optionsToRemove.push(stockid);
            }
        }
        sendMessage({ action: "removeStock", listenType: id, item: optionsToRemove });
        removeWidget(id);
    }

    return (
        <>
            <div className='widget vivify fadeIn duration-300 '>

                <div className='widget-title'>
                    <div className='widget-title-left'>
                        <i style={
                            {
                                color: themeColor || 'var(--main-color)'
                            }
                        } className='material-icons'>{icon}</i>
                        <h3>{name}</h3>
                    </div>
                    <div className='widget-title-right'>
                        <button onClick={() => { refresh() }}><i className='material-icons'>refresh</i></button>

                        <Menu position='bottom-end' shadow="md" width={200}>
                            <Menu.Target>
                                <button><i className='material-icons'>more_horiz</i></button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Widget Settings</Menu.Label>
                                <Menu.Item
                                    icon={<i style={
                                        {
                                            color: themeColor || 'var(--main-color)'

                                        }
                                    } className='material-icons'>delete</i>}
                                    onClick={() => {
                                        canvas ? removeCanvasWidget(uniqueId) : permaRemove()
                                    }}
                                >
                                    Remove Widget
                                </Menu.Item>

                            </Menu.Dropdown>
                        </Menu>
                    </div>
                </div>
                <div className='widget-contents'>
                    <div className='widget-list'>

                        {(() => {
                            let cards = []
                            let count = 0
                            for (let id of stockOverviews.activeStocks) {
                                if (stockOverviews[id].shares > 0 && stockOverviews[id].activeListeners.includes('yourstocks')) {
                                    cards.push(<W_Stocks_Card color={themeColor} stockId={id} />)
                                    if (count >= 4) {
                                        break
                                    }
                                    else {
                                        count += 1
                                    }
                                }
                            }

                            if (cards.length == 0) {
                                return <p style={{ fontSize: 'var(--px13)', textAlign: "center" }}>No Stocks Available</p>
                            }
                            else {
                                return cards
                            }
                        })()}
                    </div>
                </div>
                <div className='widget-divider'></div>
                <div style={{ display: "flex", justifyContent: 'center' }}>
                    <p style={{
                        fontSize: '11px',
                        color: 'var(--text-gray)'
                    }}>Updated {renderElapsedTime()}</p>
                </div>

            </div>
        </>
    );
};

export default W_Stocks_Container;
