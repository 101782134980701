import React, { useState, useEffect, useRef } from 'react';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { calculatePercentageChange } from '../../../../helper_functions/stockCalculations';

const W_Stocks_Card = ({stockId, color}) => {
    const stockInfo = useSelector(state => state.mainapp.stockSlice.stocks[stockId]);

    const tradeInfo = stockInfo?.marketData?.Trade;
    const shares = stockInfo ? stockInfo.shares : null;
    const currentPrice = tradeInfo?.price;
    const previousPrice = currentPrice + tradeInfo?.change || 0; 
    const changePercentage = calculatePercentageChange(currentPrice, previousPrice);
    const changeColor = changePercentage.includes('-') ? 'red' : '#00C805';

    return (
        <>
                <div className='widget-list-item-column vivify fadeIn duration-300'>
                    <div className='widget-list-item-column-top'>
            
                            <h3>{stockId || 'N/A'} <p style={{color: color}}>{currentPrice ? currentPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD',  minimumFractionDigits: 2, maximumFractionDigits: 3}) : 'N/A'}</p> </h3>
                            
                            <h4 style={{ color: changePercentage !== 'N/A' ? changeColor : 'grey', marginLeft:'auto' }}>
                            {(tradeInfo?.change || 0).toString().includes('-') ? '' : '+'}
                            {(tradeInfo?.change || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' '}

                            (
                                {((changePercentage || 0).toString().includes('-')? null : '+')}{changePercentage}
                            %)
                        </h4>
                    </div>
                    <div className='widget-list-item-column-bottom'>
                    <p>{shares || '0'} Share(s) ACB {stockInfo.averageCostBasis.toLocaleString('en-US', { style: 'currency', currency: 'USD',  minimumFractionDigits: 2, maximumFractionDigits: 4})}</p>
                      
                    </div>
                </div>
        </>
    );
};

export default W_Stocks_Card;
