import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Button, Text } from '@mantine/core';
import { Tooltip } from '@mantine/core';
import { notifications } from '@mantine/notifications';

const DuneKillerController = (props) => {

    const [compactMode, setCompactMode] = useState(localStorage.getItem('duneKillerMode') == 'true' ? true : false)



    return (
        <>

            {
                props.init == true ? <Menu.Item icon={<i className='material-icons' style={{ fontSize: '17px' }
                }>favorite</i>}

                    onClick={() => {
                        localStorage.setItem('darkMode', true)
                        localStorage.setItem('duneKillerMode', !compactMode)
                        window.location.reload()


                    }}
                >
                    {
                        compactMode ? 'Disable "Dune Killer" Mode' : 'Enable "Dune Killer" Mode'
                    }
                </Menu.Item>

                    : null
            }



            {
                compactMode ? <style>{`
              
               body {
                background-image: url('https://images.unsplash.com/photo-1698612443776-105738d5e939?auto=format&fit=crop&q=80&w=1932&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
                background-repeat: no-repeat;
                background-size: cover;
               }

               .side-navbar {
                backdrop-filter:blur(40px);
                background-color: rgba(0,0,0,0.5);
               }

               .widget-actions {position: static;backdrop-filter:none;margin-left: -20px}

               .widget {background-color: #0000002e;}

               .canvas-wrapper > .widget {background-color:#0000005c; backdrop-filter: blur(40px)}

               .focused-nav>button {background-color: #0000002e}

               .widget-canvas-inner {background-color: transparent}
            `}</style> : ''
            }
        </>
    );

}

export default DuneKillerController;