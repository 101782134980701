import { createContext, useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { alterRefreshTime, alterWheelRackTime } from "../../../../store/schemas/dataSlice";
import { useInterval } from 'usehooks-ts';  // <-- imported here
// ... other imports ...

const RefreshContext = createContext();

export const useRefresh = () => {
    return useContext(RefreshContext);
};

export const RefreshProvider = ({ children }) => {
    const dispatch = useDispatch();
    const startTimeRef = useRef(performance.now());

    const calculateTimeElapsed = () => {
        const elapsedTime = performance.now() - startTimeRef.current;
        let difference = elapsedTime;

        const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
        difference -= daysDifference * (1000 * 60 * 60 * 24);

        const hoursDifference = Math.floor(difference / (1000 * 60 * 60));
        difference -= hoursDifference * (1000 * 60 * 60);

        const minutesDifference = Math.floor(difference / (1000 * 60));
        difference -= minutesDifference * (1000 * 60);

        const seconds = Math.floor(difference / 1000);

        return {
            seconds,
            minutes: minutesDifference,
            hours: hoursDifference,
            days: daysDifference
        };
    };

    const updateTimeElapsed = () => {
        const { seconds, minutes, hours, days } = calculateTimeElapsed();
        dispatch(alterWheelRackTime({ seconds, minutes, hours, days }));

        if (minutes >= 1) {
            refresh("elapsed");
        }
    };

    // Using useInterval from usehooks-ts
    useInterval(updateTimeElapsed, 1000);

    const refresh = (type) => {
        startTimeRef.current = performance.now();
        dispatch(alterWheelRackTime({
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0
        }));
        dispatch(alterRefreshTime());
    };

    return (
        <RefreshContext.Provider value={{ refresh }}>
            {children}
        </RefreshContext.Provider>
    );
};
