import { render } from '@testing-library/react';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { calculatePercentageChange } from '../../../../helper_functions/stockCalculations';

const W_Item_Card = (props) => {
    const changeColor = (props.priceChange + " " + props.percentChange).includes('-') ? 'red' : '#00C805';
    const { days, hours, minutes, seconds } = useSelector(state => state.mainapp.dataSlice.wheelRack.time);
    const renderElapsedTime = () => {
        if (days > 0) return `${days} day(s) ago`;
        if (hours > 0) return `${hours} hour(s) ago`;
        if (minutes > 0) return `${minutes} minute(s) ago`;
        return `${seconds} second(s) ago`;
    };
    return (
        <>
            <div className='vivify fadeIn duration-300 delay-200'>

                <div className='ltr-widget'>
                    <div>



                        <h1>{

                        }{
                                props.id == 'treasury' ? props.currentPrice : (Number(props.currentPrice)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                            }{
                                props.id == 'treasury' ? '%' : ''
                            }</h1>
                        <p>Updated {renderElapsedTime()}</p>
                    </div>

                    <span style={{ color: changeColor !== 'N/A' ? changeColor : 'grey', }}>{(props.priceChange).toString().includes('-') ? '' : '+'}{props.priceChange + " "}

                        ({(props.percentChange).toString().includes('-') ? '-' : '+'}{
                            ((Math.abs(props.priceChange) / props.currentPrice) * 100).toFixed(2)
                        }%) </span>


                </div>


                {/*  <div className='large-widget-header'>
                    <div className='lg-widget-title'>
                        <span style={{ color: changeColor !== 'N/A' ? changeColor : 'grey', }}>{props.priceChange} {props.percentChange}</span>
                        <h1>${props.currentPrice}</h1>

                    </div>
                    <p>{props.name}</p>
                </div> */}

                {/*    <button onClick={
                    () => {
                        window.open('https://finance.yahoo.com/quote/BZ%3DF?p=BZ%3DF', '_blank')
                    }
                } className='widget-action-btn'>
                    <span>View On Yahoo Finance</span>
                    <i className='material-icons' style={{ transform: "rotate(-90deg)", color: props.color }}>expand_circle_down</i>
                </button> */}
            </div>

        </>
    );
};

export default W_Item_Card
