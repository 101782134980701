import React, { useRef, useEffect, useState } from 'react';
import SideNav from '../components/sideNav';
import { Outlet } from 'react-router';

const Homepage = () => {

  return (
    <>
      <div className='home-container'>
        <SideNav />

        <div className='viewer-outlet'>
          <Outlet />
        </div>
      </div>

    </>
  );

}

export default Homepage;