import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Button, Text } from '@mantine/core';
import { Tooltip } from '@mantine/core';

const CompactModeController = (props) => {

    const [compactMode, setCompactMode] = useState(localStorage.getItem('compactMode') == 'true' ? true : false)



    return (
        <>

            {
                props.init == true ? <Menu.Item

                    onClick={() => {

                        localStorage.setItem('compactMode', !compactMode)
                        window.location.reload()
                    }}
                >
                    {
                        compactMode ? 'Disable Compact Mode (Beta)' : 'Enable Compact Mode (Beta)'
                    }
                </Menu.Item>

                    : null
            }



            {
                compactMode ? <style>{`
              
                .widget-list {gap: 0px !important;}

               .widget-side > .widget {box-shadow:none;border:none;outline:none;padding:7px 0px;gap:0px !important;border-bottom: var(--frostedBorder);border-radius: 0px;padding-bottom: 13px;padding-righ: 10px;background-color: transparent !important}

              .widget-side > .widget > .widget-contents > div > .ltr-widget > div > h1 {font-size: 14px;}
 
              .widget-side > .widget > .widget-contents > div >  .ltr-widget > span {font-size: 13px;}

              .widget-side > .widget > .widget-divider {display: none;}

              .widget-side > .widget > .widget-contents > .widget-list > .widget-list-item-column > .widget-list-item-column-top > h3 {font-size: 13px;}

              .widget-side > .widget > .widget-contents > .widget-list > .widget-list-item-column > .widget-list-item-column-top > h4 {font-size: 13px;}

                .widget-title-left>h3 {font-size: 13px;}

                .widget-title-left>i {font-size: 17px;}

                .widget-panel {margin-bottom: 97px}

                .widget-panel-container {padding: 0px 30px}
            `}</style> : ''
            }
        </>
    );

}

export default CompactModeController;