import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const AuthContext = ({ children, access }) => {
    const roles = useSelector(state => state.auth.userInfo.roles);
    const isAllowed = access.some(role => roles.includes(role));
    if (isAllowed) {
        return <>{children}</>;
    }
    return null; 
};

export default AuthContext;

