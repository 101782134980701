
import { createAsyncThunk } from '@reduxjs/toolkit'
import { notifications } from '@mantine/notifications';
import { navigate } from '../helper_functions/thunkNavigate';
import { updateStockExpiration } from '../store/schemas/stockSlice';
import { alterMaturity, alterTastyPurchasePower } from '../store/schemas/dataSlice';
import { sendMessage } from '../websocket/websocket';
import { updateTrackingOrders } from '../store/schemas/trackingSlice';

const server = process.env.REACT_APP_DOMAIN
const model = (process.env.REACT_APP_DOMAIN.includes('localhost') ? 'tasty_dev' : 'tasty')
export const searchSymbol = createAsyncThunk(
    'auth/searchSymbol',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=${model}&path=/searchSymbol`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({symbol:data.symbol})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            return {symbol:data.symbol, results:results.message}
        } catch (error) {
            console.log(error)
        }
    }
  )


  export const getSymbolOptionsOverview = createAsyncThunk(
    'auth/getSymbolOptionsOverview',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=${model}&path=/getSymbolOptionsOverview`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({symbol:data.symbol})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            // thunkApi.dispatch(updateStockExpiration({symbol:data.symbol, dates:results.message.dates, optionsContracts:results.message.optionsContracts}))
            return {symbol:data.symbol, dates:results.message.dates, optionsContracts:results.message.optionsContracts}
        } catch (error) {
            console.log(error)
        }
    }
  )

  export const getAccountBalance = createAsyncThunk(
    'auth/getAccountBalance',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=${model}&path=/getAccountBalance`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(alterTastyPurchasePower(results.message))
            // return {symbol:data.symbol, results:results.message}
        } catch (error) {
            console.log(error)
        }
    }
  )

  export const getAccountPositions = createAsyncThunk(
    'auth/getAccountPositions',
    async (data, thunkApi) => {
        try {
            const {listenType} = data
            const response = await fetch(`${server}?auth=true&model=${model}&path=/getAccountPositions`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            const state = thunkApi.getState()
            const stocks = state.mainapp.stockSlice.stocks
            let rackStocks = stocks.activeStocks.filter((stockId) => stocks[stockId].activeListeners.includes(listenType))
            let rackStockOptions = []

            for(let stockId of rackStocks){
                for(let option in stocks[stockId].stockOptionsMap){
                    if(stocks[stockId].stockOptionsMap[option].activeListeners.includes("Rack")){
                        rackStockOptions.push(option)
                    }
                }
            }

            
            let stocksFromOrders = []
            let stocksOptionsFromOrders = []

            let tempOptionData = {}
            for(let stockId in results.message){
                if(!stocksFromOrders.includes(stockId)){
                    stocksFromOrders.push(stockId)
                }
                for(let orderId in results.message[stockId]){
                    if(!stocksOptionsFromOrders.includes(results.message[stockId][orderId].dxSymbol)){
                        stocksOptionsFromOrders.push(results.message[stockId][orderId].dxSymbol)
                    }
                    tempOptionData[results.message[stockId][orderId].dxSymbol] = results.message[stockId][orderId]
                }

            }

            let stocksToAdd = []
            let stocksToRemove = []

            let stockOptionsToAdd = []
            let stockOptionsToRemove = []

            // Stocks to add are those present in orders but not yet active.
            stocksToAdd = stocksFromOrders.filter(stock => !rackStocks.includes(stock));

            // Stocks to remove are those currently active but not in the orders.
            let stocksToRemoveWithOptions = rackStocks.filter(stock => !stocksFromOrders.includes(stock));
            stocksToRemove = stocksToRemoveWithOptions.filter(stock => results.stockData[stock] == undefined)
            // Stock options to add are those present in orders but not yet active.
            stockOptionsToAdd = stocksOptionsFromOrders.filter(option => !rackStockOptions.includes(option));

            // Stock options to remove are those currently active but not in the orders.
            stockOptionsToRemove = rackStockOptions.filter(option => !stocksOptionsFromOrders.includes(option));

        let finialDataToAdd = stocksToAdd.map((item) => {return {streamer:item}})
        finialDataToAdd.push(...stockOptionsToAdd.map(item => { return {streamer:item, tastySymbol: tempOptionData[item].tastySymbol, expiration:tempOptionData[item].expirationDate,  strikePrice:tempOptionData[item].strikePrice, stockSymbol:tempOptionData[item].stockSymbol} }))
        let finalDataToRemove = stockOptionsToRemove.map((item) => {return{symbol:item, stockSymbol: item.match(/\.([A-Za-z]+)/)[1]}})
        finalDataToRemove.push(...stocksToRemove)

        sendMessage({ action: 'addStock', item: finialDataToAdd, orders:results.message, stockShares:results.stockData, listenType:listenType })
        if(finalDataToRemove.length != 0){
            sendMessage({ action: 'removeStock', item: finalDataToRemove,  listenType:listenType  })
        }

        

            // let stocksToAdd = []
            // let stocksOptionsToAdd = []


            // for(let stockId in results.message){
            //     if(stocks[stockId] == undefined){
            //         stocksToAdd.push(stockId)
            //     }
            //     for(let orderId in results.message[stockId]){
            //         if(stocks[stockId].stockOptionsMap[results.message[stockId][orderId].dxSymbol] == undefined){
            //             stocksOptionsToAdd.push(results.message[stockId][orderId].dxSymbol)
            //         }

                    
            //     }
            // }
            // const removeRejected = results.message.items.filter((item) => !('reject-reason' in item))
            let orderData 
            // for(let order of results.message.items){
            //     if(!('reject-reason' in item)){

            //     }

            // }
            // thunkApi.dispatch(alterTastyPurchasePower(results.message))
            // return {symbol:data.symbol, results:results.message}
        } catch (error) {
            console.log(error)
        }
    }
  )

  export const getStockWidget = createAsyncThunk(
    'auth/getStockWidget',
    async (data, thunkApi) => {
        try {
            const {listenType} = data
            const response = await fetch(`${server}?auth=true&model=${model}&path=/getStockWidget`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            const state = thunkApi.getState()
            const stocks = state.mainapp.stockSlice.stocks.activeStocks
            let currentActiveStocks = []
            for(let stockId of stocks){
                if(state.mainapp.stockSlice.stocks[stockId].activeListeners.includes(listenType)){
                    currentActiveStocks.push(stockId)
                }
            }

            const stocksToRemove = currentActiveStocks.filter(stockId => results.message[stockId] == undefined)
            console.log(stocksToRemove)

        sendMessage({ action: 'addStock', stockShares:results.message, listenType:listenType })
        if(stocksToRemove.length != 0){
            sendMessage({ action: 'removeStock', item: stocksToRemove,  listenType:listenType  })
        }

        

            // let stocksToAdd = []
            // let stocksOptionsToAdd = []


            // for(let stockId in results.message){
            //     if(stocks[stockId] == undefined){
            //         stocksToAdd.push(stockId)
            //     }
            //     for(let orderId in results.message[stockId]){
            //         if(stocks[stockId].stockOptionsMap[results.message[stockId][orderId].dxSymbol] == undefined){
            //             stocksOptionsToAdd.push(results.message[stockId][orderId].dxSymbol)
            //         }

                    
            //     }
            // }
            // const removeRejected = results.message.items.filter((item) => !('reject-reason' in item))
            let orderData 
            // for(let order of results.message.items){
            //     if(!('reject-reason' in item)){

            //     }

            // }
            // thunkApi.dispatch(alterTastyPurchasePower(results.message))
            // return {symbol:data.symbol, results:results.message}
        } catch (error) {
            console.log(error)
        }
    }
  )


  export const estimateFeesOpenOptions = createAsyncThunk(
    'auth/estimateFeesOpenOptions',
    async (data, thunkApi) => {
        try {
            // {stockId, optionsId, buySell:"buy" || "sell", price, tastySymbol, quantity" }
            const response = await fetch(`${server}?auth=true&model=${model}&path=/estimateFeesOpenOptions`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({buySell:data.buySell, price:data.price, symbol:data.symbol, quantity:data.quantity})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            if("error" in results.message){
                throw results.message
            }
            const newResults = await thunkApi.dispatch(placeOrderOpenOptions(data))
            if(newResults.payload != 'success'){
                if("error" in newResults.payload){
                    throw newResults
                }
                if("error" in newResults.payload.message){
                    throw newResults.message
                } 
            }
            else{
                return 'success'
            }
        } catch (error) {
            console.log(error)

            for(let err of error.error.errors){
                let report = []
                for(let item in err){
                    report.push(item)
                }
                notifications.show({title: err[report[0]],message: err[report[1]]})

            }
            return 'fail'
        }
    }
  )

  
  export const placeOrderOpenOptions = createAsyncThunk(
    'auth/placeOrderOpenOptions',
    async (data, thunkApi) => {
        try {
            // {stockId, optionsId, buySell:"buy" || "sell", price, tastySymbol, quantity" }

            const state = thunkApi.getState()
            const stock = state.mainapp.stockSlice.stocks[data.stockId]

            let dataToSend = {
                stock:{
                    stockName:stock.stockName,
                    description:stock.description,
                    marketData:stock.marketData
                },
                option:{
                    optionId:data.optionsId,
                    ...structuredClone(stock.stockOptionsMap[data.optionsId])
                }
            }

            delete dataToSend.stock.activeListeners

            const response = await fetch(`${server}?auth=true&model=${model}&path=/placeOrderOpenOptions`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({tradingData:dataToSend, buySell:data.buySell, price:data.price, symbol:data.symbol, quantity:data.quantity})  
            })
            const results = await response.json()
            console.log(results)
            if("error" in results){
                return results
            }
            if(results.message != 'success'){
                return results
            }
         
            return 'success'
        } catch (error) {
           console.log(error)
        }
    }
  )

  
  export const estimateFeesCloseOptions = createAsyncThunk(
    'auth/estimateFeesCloseOptions',
    async (data, thunkApi) => {
        try {
            // {orderId, stockId, optionsId, buySell:"Sell to Open" || "Buy to Open", price, tastySymbol, quantity" }
          
            const response = await fetch(`${server}?auth=true&model=${model}&path=/estimateFeesCloseOptions`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({buySell:data.buySell, price:data.price, symbol:data.symbol, quantity:data.quantity})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            if("error" in results.message){
                throw results.message
            }
            const newResults = await thunkApi.dispatch(placeOrderCloseOptions(data))
            if(newResults.payload != 'success'){
                if("error" in newResults.payload){
                    throw newResults
                }
                if("error" in newResults.payload.message){
                    throw newResults.message
                } 
            }
            else{
                return 'success'
            }
        } catch (error) {
            console.log(error)

            for(let err of error.error.errors){
                let report = []
                for(let item in err){
                    report.push(item)
                }
                notifications.show({title: err[report[0]],message: err[report[1]]})

            }
            return 'fail'
        }
    }
  )

  
  export const placeOrderCloseOptions = createAsyncThunk(
    'auth/placeOrderCloseOptions',
    async (data, thunkApi) => {
        try {

            // {orderId, stockId, optionsId, buySell:"Sell to Open || Buy to Open, price, tastySymbol, quantity" }
            const state = thunkApi.getState()
            const stock = state.mainapp.stockSlice.stocks[data.stockId]

            let dataToSend = {
                stock:{
                    stockName:stock.stockName,
                    description:stock.description,
                    marketData:stock.marketData
                },
                option:{
                    optionId:data.optionsId,
                    ...structuredClone(stock.stockOptionsMap[data.optionsId])
                }
            }

            delete dataToSend.stock.activeListeners

            const response = await fetch(`${server}?auth=true&model=${model}&path=/placeOrderCloseOptions`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({tradingData:dataToSend, orderId:data.orderId, buySell:data.buySell, price:data.price, symbol:data.symbol, quantity:data.quantity})  
            })
            const results = await response.json()

            if("error" in results){
                return results
            }
            if(results.message != 'success'){
                return results
            }
         
            return 'success'
        } catch (error) {
           console.log(error)
        }
    }
  )

  export const estimateFeesOpenStocks = createAsyncThunk(
    'auth/estimateFeesOpenStocks',
    async (data, thunkApi) => {
        try {
            // {stockId, price,  quantity" }
            const response = await fetch(`${server}?auth=true&model=${model}&path=/estimateFeesOpenStocks`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({price:data.price, symbol:data.stockId, quantity:data.quantity})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            if("error" in results.message){
                throw results.message
            }
            const newResults = await thunkApi.dispatch(placeOrderOpenStocks(data))
            if(newResults.payload != 'success'){
                if("error" in newResults.payload){
                    throw newResults
                }
                if("error" in newResults.payload.message){
                    throw newResults.message
                } 
            }
            else{
                return 'success'
            }
        } catch (error) {
            console.log(error)

            for(let err of error.error.errors){
                let report = []
                for(let item in err){
                    report.push(item)
                }
                notifications.show({title: err[report[0]],message: err[report[1]]})

            }
            return 'fail'
        }
    }
  )

  
  export const placeOrderOpenStocks = createAsyncThunk(
    'auth/placeOrderOpenStocks',
    async (data, thunkApi) => {
        try {
            // {stockId, price,  quantity" }

            const state = thunkApi.getState()
            const stock = state.mainapp.stockSlice.stocks[data.stockId]

            let dataToSend = {
                stock:{
                    stockName:stock.stockName,
                    description:stock.description,
                    marketData:stock.marketData
                }
            }

            delete dataToSend.stock.activeListeners

            const response = await fetch(`${server}?auth=true&model=${model}&path=/placeOrderOpenStocks`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({tradingData:dataToSend, price:data.price, symbol:data.stockId, quantity:data.quantity})  
            })
            const results = await response.json()
            console.log(results)
            if("error" in results){
                return results
            }
            if(results.message != 'success'){
                return results
            }
         
            return 'success'
        } catch (error) {
           console.log(error)
        }
    }
  )

  
  export const estimateFeesCloseStocks = createAsyncThunk(
    'auth/estimateFeesCloseStocks',
    async (data, thunkApi) => {
        try {
            // {stockId, price,  quantity" }
            const response = await fetch(`${server}?auth=true&model=${model}&path=/estimateFeesCloseStocks`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({price:data.price, symbol:data.stockId, quantity:data.quantity})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            if("error" in results.message){
                throw results.message
            }
            const newResults = await thunkApi.dispatch(placeOrderCloseStocks(data))
            if(newResults.payload != 'success'){
                if("error" in newResults.payload){
                    throw newResults
                }
                if("error" in newResults.payload.message){
                    throw newResults.message
                } 
            }
            else{
                return 'success'
            }
        } catch (error) {
            console.log(error)

            for(let err of error.error.errors){
                let report = []
                for(let item in err){
                    report.push(item)
                }
                notifications.show({title: err[report[0]],message: err[report[1]]})

            }
            return 'fail'
        }
    }
  )

  
  export const placeOrderCloseStocks = createAsyncThunk(
    'auth/placeOrderCloseStocks',
    async (data, thunkApi) => {
        try {
            // {stockId, price,  quantity" }

            const state = thunkApi.getState()
            const stock = state.mainapp.stockSlice.stocks[data.stockId]

            let dataToSend = {
                stock:{
                    stockName:stock.stockName,
                    description:stock.description,
                    marketData:stock.marketData
                }
            }

            delete dataToSend.stock.activeListeners

            const response = await fetch(`${server}?auth=true&model=${model}&path=/placeOrderCloseStocks`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({tradingData:dataToSend, price:data.price, symbol:data.stockId, quantity:data.quantity})  
            })
            const results = await response.json()
            console.log(results)
            if("error" in results){
                return results
            }
            if(results.message != 'success'){
                return results
            }
         
            return 'success'
        } catch (error) {
           console.log(error)
        }
    }
  )


  export const getOrderTracking = createAsyncThunk(
    'auth/getOrderTracking',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=${model}&path=/getOrderTracking`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(updateTrackingOrders({orders:results.message}))
            
       
        } catch (error) {
            console.log(error)
        }
    }
  )

  export const cancelOrder = createAsyncThunk(
    'auth/cancelOrder',
    async (data, thunkApi) => {
        try {
            // {stockId, price,  quantity" }
            const response = await fetch(`${server}?auth=true&model=${model}&path=/cancelOrder`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({orderId:data.orderId})  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            if("error" in results.message){
                throw results.message
            }

            thunkApi.dispatch(getOrderTracking())

            notifications.show({
                icon: <i style={{ color: "white" }} className='material-icons'>check</i>,
                title: 'Cancel Order Success',
                message: 'Your order has been canceled.',
            })
        } catch (error) {
          
            if(error.error.errors != undefined){
                for(let err of error.error.errors){
                    let report = []
                    for(let item in err){
                        report.push(item)
                    }
                    notifications.show({title: err[report[0]],message: err[report[1]]})
    
                }
    
            }
            else{
                notifications.show({title: error.error.code,message:  error.error.message})
            }
            
            return 'fail'
        }
    }
  )