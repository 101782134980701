import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    mirror:null
})


const settingSlice = createSlice({
    name:'settingSlice',
    initialState:initialState(),
    reducers: {
        resetSettingSlice: state => initialState(),
    
        updateMirror:(state, action)=>{            
            state.mirror = action.payload
        }
    }
})

export const {resetSettingSlice, updateMirror} = settingSlice.actions

export default settingSlice.reducer