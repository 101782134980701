import React, { useRef, useEffect, useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import ExploreTable from './explore_table';
import { useDispatch, useSelector } from 'react-redux';
import { notifications } from "@mantine/notifications";

import { getAccountBalance, getSymbolOptionsOverview, searchSymbol } from '../../httpcalls/tastyThunk';
import { useParams } from 'react-router-dom';
import { sendMessage } from '../../websocket/websocket';
import { alterStockOptions, alterStockOptionsMap, addStockSymbol, removeStockSymbol, resetCurrentStock, resetStockOptionsMap, updateStockSymbolDescription } from '../../store/schemas/stockSlice';
import { NativeSelect } from '@mantine/core';
import { navigate } from '../../helper_functions/thunkNavigate';
import { alterDataFilter, alterDataFilterInput } from '../../store/schemas/dataSlice';
import { Drawer } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Tooltip } from '@mantine/core';
import moment from 'moment';
import ExploreLearn from './explore_learn';
import { store } from "../../store/store";
import { ExploreRowProvider, useExploreRowContext } from './explore_row_context';
import { calculatePercentageChange } from '../../helper_functions/stockCalculations';
import { Menu } from '@mantine/core';
import E_Stock_Buy_Modal from './modals/e_stock_buy_modal';
import { Modal } from '@mantine/core';
import AuthContext from '../../components/authContext';


const Explore = () => {
    const { stockid } = useParams()

    const stockData = useSelector(state => state.mainapp.stockSlice?.stocks[stockid])
    const stockMarketData = useSelector(state => state.mainapp.stockSlice?.stocks[stockid]?.marketData)

    const stockView = useSelector(state => state.mainapp.stockSlice?.stocks[stockid]?.stockOptions)
    // const dataFilter = useSelector(state => state.mainapp.dataSlice.dataFilter)

    const dispatch = useDispatch()

    const [smartQueryOpen, setSmartQueryOpen] = useState(false)

    const [buySell, setBuySell] = useState('sell')
    const [callPut, setCallPut] = useState('put')
    const [sortDate, setSortDate] = useState('')
    const smartActiveQuery = useSelector((state) => state.mainapp.dataSlice.dataFilterInput)
    const [smartQuery, setSmartQuery] = useState('')
    const [selectedDate, setSelectedDate] = useState(null)
    const [status, setStatus] = useState('Checking...');
    const [timeUntilClose, setTimeUntilClose] = useState('0');
    const [opened, { open, close }] = useDisclosure(false);
    const { setHasRendered } = useExploreRowContext()
    const [openedBuy, { open: openBuy, close: closeBuy }] = useDisclosure(false);
    const accountBalance = useSelector(state => state.mainapp.dataSlice.tastyAccountInfo.purchasingPower)

    const toggleModal = () => {
        openedBuy ? closeBuy() : openBuy()
    }



    function preprocessInput(input) {
        let processedInput = input.toUpperCase();
        processedInput = processedInput.replace(/[$%\s]+/g, ''); // Remove $, %, and all white space
        processedInput = processedInput.replace(/,|AND/g, '&&'); // Replace "AND" with &&
        processedInput = processedInput.replace(/OR/g, '||'); // Replace "OR" with ||
        processedInput = processedInput.replace(/(?<![<>=!])=+(?![<>=])/g, '==');
        processedInput = processedInput.replace(/N\/A/g, '"N/A"');
        processedInput = processedInput.replace(/DATE\(([^")]+?)\)/g, 'DATE("$1")');
        processedInput = processedInput.replace(/\bDATE\b(?![(])/g, 'DATE()');
        return processedInput;
    }

    const TOKEN_PATTERNS = {
        variable: /[A-z][A-z0-9]*/,
        operator: /(?:&&|\|\||>|<|>=|<=|==|!=)/,
        value: /-?\d+(\.\d+)?/,  // Match integers and decimals
        specialValue: /"N\/A"/,
        parentheses: /[()]/,
        functionCall: /DATE\("[0-9]{2}\/[0-9]{2}\/[0-9]{4}"\)/
    };

    const COMBINED_PATTERN = new RegExp(
        Object.values(TOKEN_PATTERNS).map(pattern => `(${pattern.source})`).join('|'),
        'g'
    );


    function tokenize(input) {

        return input.match(COMBINED_PATTERN) || [];
    }

    function sanitize() {
        let input = preprocessInput(smartQuery)
        const WHITELISTED_VARIABLES = ['"N/A"', "STOCKPRICE", 'VOLATILITY', 'DATE', 'DISTANCE', 'STRIKEPRICE', 'VOLUME', 'ID', "BREAKEVEN", 'POP', "TBE", "PERCENTCHANGE", "CHANGE", "INTRADAYLOW", "INTRADAYHIGH", "APR", "MARK"];
        const WHITELISTED_OPERATORS = ['&&', '||', '>', '<', '>=', '<=', '==', '!='];
        const WHITELISTED_PARENS = ['(', ')'];
        const WHITELISTED_FUNCTIONS = [TOKEN_PATTERNS.functionCall];

        try {
            const tokens = tokenize(input);
            for (const token of tokens) {
                if (
                    !WHITELISTED_VARIABLES.includes(token) &&
                    !WHITELISTED_OPERATORS.includes(token) &&
                    !WHITELISTED_PARENS.includes(token) &&
                    !WHITELISTED_FUNCTIONS.some(func => token.match(func)) &&
                    !token.match(TOKEN_PATTERNS.value)  // Instead of a whitelist, just match against the value pattern
                ) {
                    throw token

                }
            }
            dispatch(alterDataFilter(input))
            dispatch(alterDataFilterInput(smartQuery))
            notifications.show({
                icon: <i style={{ color: "white" }} className='material-icons'>check</i>,
                title: 'Changes Applied',
                message: 'Your changes have been applied to the table.',
            });
            // setSmartQueryOpen(false)
        } catch (error) {
            notifications.show({
                title: 'Query Not Applied',
                message: 'The submitted query has the following bad token: ' + error,
                color: "red"
            })
            // I need this to be red @Jacoby
        }


    }



    useEffect(() => {
        localStorage.setItem('lastTicker', stockid)
    }, [stockid])


    const resetSmartQuery = () => {
        setSmartQuery('')
        dispatch(alterDataFilterInput(null))
        dispatch(alterDataFilter(null))
    }

    const setTable = async (date) => {
        setSelectedDate(date)
        setHasRendered(false)
        sendMessage({ action: "removeStock", listenType: 'Table', item: stockData.stockOptions.map(item => { return { symbol: item.symbol, stockSymbol: stockid } }) })
        let contracts = []
        let dataToCall = []
        if (date == 'All Dates') {
            let streamerToGrab = callPut == 'put' ? 'put-streamer-symbol' : 'call-streamer-symbol'
            for (let i = 0; i < stockData.optionsContracts.length; i++) {
                for (let j = 0; j < stockData.optionsContracts[i].strikes.length; j++) {
                    dataToCall.push({ streamer: stockData.optionsContracts[i].strikes[j][streamerToGrab], tastySymbol: stockData.optionsContracts[i].strikes[j][callPut], expiration: stockData.optionsContracts[i]['expiration-date'], strikePrice: parseFloat(stockData.optionsContracts[i].strikes[j]['strike-price']), stockSymbol: stockid })
                }

            }

        }
        else {
            const index = stockData.dates.findIndex(item => item == date)
            contracts = stockData.optionsContracts[index]
            if (callPut == 'put') {
                dataToCall = contracts.strikes.map(item => { return { streamer: item['put-streamer-symbol'], tastySymbol: item['put'], expiration: date, strikePrice: item['strike-price'], stockSymbol: stockid } })

            }
            else {
                dataToCall = contracts.strikes.map(item => { return { streamer: item['call-streamer-symbol'], tastySymbol: item['call'], expiration: date, strikePrice: item['strike-price'], stockSymbol: stockid } })
            }
        }
        sendMessage({ action: 'addStock', item: dataToCall, listenType: 'Table' })
    }


    useEffect(() => {
        const checkMarketStatus = () => {
            const now = new Date();

            // Convert to Eastern Time (ET) - New York timezone
            const nowInET = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));

            const day = nowInET.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
            const hours = nowInET.getHours();
            const minutes = nowInET.getMinutes();

            // Check if the market is open
            if (day >= 1 && day <= 5) { // Monday to Friday
                if ((hours * 60 + minutes >= 570) && (hours < 16 || (hours === 16 && minutes === 0))) {
                    setStatus('Open');

                    // Calculate time until market closes
                    const closingTime = new Date(nowInET);
                    closingTime.setHours(16);
                    closingTime.setMinutes(0);
                    closingTime.setSeconds(0);

                    const timeDifference = closingTime - nowInET; // in milliseconds
                    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
                    setTimeUntilClose(timeDifferenceInHours.toFixed(2));

                } else {
                    setStatus('Closed');
                    setTimeUntilClose(null);
                }
            } else {
                setStatus('Closed');
                setTimeUntilClose(null);
            }

        };

        // Check the market status initially
        checkMarketStatus();

        // Check the market status every minute
        const intervalId = setInterval(checkMarketStatus, 60000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {

        if (selectedDate != null) {
            setTable(selectedDate)
        }
    }, [callPut])

    useEffect(() => {
        dispatch(getAccountBalance())

        sendMessage({ action: "addStock", item: [{ streamer: stockid }], listenType: 'Table' })

        return (() => {
            const state = store.getState()
            if (state.mainapp.stockSlice.stocks[stockid] != undefined) {
                let optionsToRemove = state.mainapp.stockSlice.stocks[stockid].stockOptions.map(item => { return { symbol: item.symbol, stockSymbol: stockid } })
                optionsToRemove.push(stockid)
                sendMessage({ action: "removeStock", listenType: 'Table', item: optionsToRemove })
            }
        })
    }, [])

    const moment = require('moment');

    const currentDate = moment();

    const daysUntilExpiration = (current, expiration) => {
        const expirationDate = moment(expiration + "T00:00:00.000Z");  // Setting the expiration time to midnight
        const daysLeft = expirationDate.diff(current, 'days', true);  // This will give the difference in days, including fractions
        return Math.ceil(daysLeft);  // Rounding up to get the full days remaining
    }




    const formatDate = (dateString) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const [year, month, day] = dateString.split('-');
        const monthName = months[parseInt(month, 10) - 1];

        return `${monthName} ${day}`;
    };



    return (
        <>
            <Drawer opened={opened} onClose={close} position='right' withCloseButton={false}>
                <ExploreLearn />
            </Drawer>




            {openedBuy && stockData != undefined ? <E_Stock_Buy_Modal stockId={stockid} buySell={buySell} callPut={callPut} modalToggle={toggleModal} /> : null}

            {
                stockData ?
                    <div className='nav-page explore-page vivify fadeIn duration-300 '>
                        <header className='activity-header'>
                            <div className='activity-bar'>
                                <div className='left-activity'></div>
                                <div className='activity-status'>
                                    <h3>Market
                                        <button onClick={
                                            () => {
                                                window.open('https://www.nyse.com/markets/hours-calendars', '_blank')
                                            }
                                        } style={
                                            status == 'Open' ? { color: 'var(--main-color)' } : { color: 'var(--remove-color)' }
                                        }>{status}</button> {
                                            !status == 'Open' ? " " + { timeUntilClose } + " hours until close" : ""
                                        }
                                    </h3>
                                </div>
                                <div className='activity-icon-btn'>
                                    {/* <button></button> */}
                                    <AuthContext access={['admin', 'trader']}>
                                        <Menu position='bottom-end' shadow="md" width={200}>
                                            <Menu.Target>
                                                <button><i className='material-icons-outlined'>pending</i></button>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Label>Actions</Menu.Label>
                                                <Menu.Item onClick={
                                                    () => {
                                                        openBuy()
                                                    }
                                                } icon={<i className='material-icons-outlined colored-icon'>shopping_cart</i>}>Buy Stock</Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </AuthContext>

                                </div>
                            </div>

                            <div className='activity-title'>
                                <span>{(stockMarketData?.Trade?.price || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 })}</span>

                                <h1>{stockData.stockName}</h1>

                                <h4 className={(stockMarketData?.Trade?.change || 0).toString().includes('-')
                                    ? 'red-title' : 'green-title'

                                }> {' '}

                                    {(stockMarketData?.Trade?.change || 0).toString().includes('-')
                                        ? '' : '+'
                                    }{
                                        (stockMarketData?.Trade?.change || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' '

                                    }
                                    (
                                    {
                                        ((stockMarketData?.Trade?.change || 0).toString().includes('-')
                                            ? '-' : '+') +

                                        calculatePercentageChange(stockMarketData?.Trade?.price, stockMarketData?.Trade?.change.toString().includes('-') ? (stockMarketData?.Trade?.price - stockMarketData?.Trade?.change) : (stockMarketData?.Trade?.price + stockMarketData?.Trade?.change)) + '%'
                                    }
                                    )
                                </h4>
                                {/*         <p>{stockData.description}</p> */}
                            </div>

                            <div className='activity-sort' style={
                                smartQueryOpen ? { gap: '16px' } : {}
                            }>

                                {
                                    smartQueryOpen ?
                                        "" :
                                        <>
                                            <div className='activity-segment vivify fadeIn duration-300 delay-200'>
                                                <div className='native-control'>

                                                    <input onChange={
                                                        (e) => {
                                                            setBuySell(e.target.value)
                                                        }
                                                    } id='buyItem' type="radio" name='buy/sell' value={'buy'} />
                                                    <label for={'buyItem'}>
                                                        Buy
                                                    </label>
                                                </div>
                                                <div className='native-control'>

                                                    <input defaultChecked={
                                                        true
                                                    } onChange={
                                                        (e) => {
                                                            setBuySell(e.target.value)
                                                        }
                                                    } id='sellItem' type="radio" name='buy/sell' value={'sell'} />
                                                    <label for={'sellItem'}>
                                                        Sell
                                                    </label>
                                                </div>
                                            </div>


                                            <div className='activity-segment vivify fadeIn duration-300 delay-200'>
                                                <div className='native-control'>

                                                    <input onChange={
                                                        (e) => {
                                                            setCallPut(e.target.value)
                                                        }
                                                    } id='callItem' type="radio" name='call/put' value={'call'} />
                                                    <label for={'callItem'}>
                                                        Call
                                                    </label>
                                                </div>
                                                <div className='native-control'>

                                                    <input onChange={
                                                        (e) => {
                                                            setCallPut(e.target.value)
                                                        }
                                                    } id='putItem' defaultChecked={
                                                        true
                                                    } type="radio" name='call/put' value={'put'} />
                                                    <label for={'putItem'}>

                                                        Put
                                                    </label>
                                                </div>


                                                {/*  <SegmentedControl value={callPut} onChange={
                                                    setCallPut
                                                } fullWidth size="md"
                                                    data={[
                                                        { label: 'Call', value: 'call' },
                                                        { label: 'Put', value: 'put' }
                                                    ]}
                                                /> */}
                                            </div>



                                            <div className='activity-select vivify fadeIn duration-300 delay-200'>
                                                <Select
                                                    placeholder="Select date"
                                                    searchable
                                                    nothingFound="No options"
                                                    data={
                                                        (() => {
                                                            let data = stockData.dates.map(item => { return { value: (item), label: formatDate(item) + " (" + (daysUntilExpiration(currentDate, item)) + "d)" } })
                                                            data.push('All Dates')
                                                            return data
                                                        })()


                                                    }
                                                    value={selectedDate}
                                                    onChange={(e) => { setTable(e) }}
                                                />
                                            </div></>
                                }

                                {
                                    smartQueryOpen ?
                                        <button onClick={
                                            () => {
                                                setSmartQueryOpen(false)
                                                setSmartQuery(smartActiveQuery)
                                            }
                                        } className='query-close'>
                                            <i className='material-icons'>close</i>
                                        </button>
                                        : ""
                                }


                                <div className={
                                    smartQueryOpen ? 'activity-query smart-query-open' : 'activity-query'
                                }>

                                    <input value={smartQuery || (smartQueryOpen == false ? smartActiveQuery : undefined) || ''} onChange={
                                        (e) => {
                                            setSmartQuery(e.target.value)
                                        }
                                    } placeholder='Enter Smart Query...' onFocus={
                                        () => {
                                            setSmartQueryOpen(true)
                                        }
                                    } type='text' ></input>
                                    {
                                        smartQueryOpen ?
                                            <div className='query-btn'>
                                                <button onClick={resetSmartQuery}><i className='material-icons'>refresh</i><span>Reset</span></button>
                                                <button onClick={sanitize}><i className='material-icons'>play_arrow</i><span>Run</span></button>
                                            </div>
                                            : ""
                                    }
                                    <Tooltip offset={
                                        smartQueryOpen ? 20 : 5
                                    } label="Query Guide">
                                        <button onClick={open} className='resource-btn'>
                                            <i className='material-icons-outlined'>auto_stories</i>
                                        </button>
                                    </Tooltip>

                                </div>


                            </div>
                        </header>

                        {stockView.length > 0 ?


                            <ExploreTable stockView={stockView} stockPrice={stockMarketData?.Trade?.price || 0} stockid={stockid} selectedDate={selectedDate} callPut={callPut} buySell={buySell} />

                            :
                            null
                        }

                    </div>
                    : null
            }
            {


            }
        </>
    );

}

export default Explore;