import { useEffect, useState } from "react";
import '../App.css';
import { Link } from "react-router-dom";
import { loginUser, verifyCode} from "../httpcalls/authThunk";
import { useDispatch } from "react-redux";
import QRCode from 'qrcode'

const Login = () => {
  const dispatch = useDispatch()
  const [mfa, setMfa] = useState(null)
  const [qr, setQrCode] = useState(null)
  const loginPerson = (e) => {
    const email = e.target.elements['email'].value
    const password = e.target.elements['password'].value
    dispatch(loginUser({ email: email, password: password })).then((res) => {
      if(res.payload != undefined){
        e.target.reset()
        setMfa(res.payload)
        if(res.payload.mfa_status == 'setup'){
          generateQrCode(res.payload.qrCode)
        }
      }
    })
  }

  const launchVerifyCode = (e) => {
    const code = e.target.elements['verifyCode'].value
    dispatch(verifyCode({ session: mfa.session, code: code }))
  }

  const generateQrCode = async (urlToGenerate) => {
    const dataUri = await QRCode.toDataURL(urlToGenerate)
    setQrCode(dataUri)
  }

  return (


    <>

      <div className="login-container login-back " >
        <div className="login vivify fadeIn duration-300">
          <div className="login-title vivify fadeIn duration-300">
            <h1>Welcome</h1>
            {<p>This system is restricted to authorized users only.</p> }
          </div>

          <div className="login-form vivify fadeIn duration-300">
            {mfa == null ?
             <form onSubmit={(e) => {
              e.preventDefault()
              loginPerson(e)
            }}>
              <div className="login-form-input">
                <label htmlFor="email">Email</label>
                <input type="email" placeholder="Enter Your Email" name="email" id="email" />
              </div>

              <div className="login-form-input">
                <label htmlFor="password">Password <Link to={"/reset-password"}>Forgot Password?</Link></label>
                <input type="password" placeholder="Password" name="password" id="password" />
              </div>

              <button type="submit">Sign In</button>

              <p>By Clicking “Sign In” You Agree To Our <span>Terms & Conditions</span></p>
            </form>
            :
            <form onSubmit={(e) => {
              e.preventDefault()
              launchVerifyCode(e)
            }}>
              {
                qr != null ?
                <>
                  <img src={qr} /> 
                  <h2 style={{"fontSize":".7rem","textAlign":"center","fontWeight":"600"}}>The following qr code is designed for your authentication app. Examples (Microsoft Authenticator, Google Authenticator)</h2>
                </>
                  :
                   null
              }
              <div className="login-form-input">
                <label htmlFor="verifyCode">Verification Code</label>
                <input type="text" placeholder="Verification Code" name="verifyCode" id="verifyCode" />
              </div>

              <button type="submit">Verify</button>
            </form>
            }
           

          </div>

       


        </div>
      </div>

    </>
  );
}

export default Login;