
import { Popover, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';



const Tracking_Accordion = ({data, type}) => {
    const dispatch = useDispatch()
    // console.log(data)
    const [opened, { close, open }] = useDisclosure(false);

    return (
        <>
            <div className='tracking-accordion-wrapper '>
                <div className='tracking-accordion'>

                    {
                        (() => {
                            let html = []
                            for(let key in data){
                                let title = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

                                if(key == 'fee'){


                                    html.push (
                                        
                                        <div className='accord-chip'>
                                            <h4>{title}</h4>
                                                                                        
                                            <Popover width={300} position="bottom" shadow="md">
                                                <Popover.Target  onMouseEnter={open} onMouseLeave={close}>
                                                    <p>{parseFloat(data[key]['total-fees']).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 })} <p style={{ display:"inline-block", textAlign:'center', cursor:'pointer', width:'20px', height:'20px', border: 'var(--frostedBorder)', borderRadius:'25px'}}>?</p></p>
                                                </Popover.Target>
                                                
                                                <Popover.Dropdown style={{display:'flex', flexDirection:"column", gap:'5px'}}>
                                                    { (() => {
                                                        let items = []
                                                        for(let item in data[key]){
                                                        items.push(<p style={{fontSize:'.7rem'}}>{item + ": " + data[key][item]}</p>)
                                                        }
                                                        return items

                                                    })()}                                                  
                                                </Popover.Dropdown>
                                            </Popover>
                                           
                                        </div>
                                    )
                                }
                                else{
                                    if(key == 'pop'){
                                        if(type == 'PUT'){
                                            html.push (
                                                <div className='accord-chip'>
                                                    <h4>{title}</h4>
                                                    <p>{data[key]}</p>
                                                </div>
                                            )
                                        }
                                    }
                                    else if(key == 'tbe'){
                                        if(type == 'CALL'){
                                            html.push (
                                                <div className='accord-chip'>
                                                    <h4>{title}</h4>
                                                    <p>{data[key]}</p>
                                                </div>
                                            )
                                        }
                                    }
                                    else{
                                        html.push (
                                            <div className='accord-chip'>
                                                <h4>{title}</h4>
                                                <p>{data[key]}</p>
                                            </div>
                                        )
                                    }
                                   
                                }
                              
                            }
                            return html
                        })()
                    }
                </div>
            </div>

        </>

    );



}

export default Tracking_Accordion;