import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { Modal } from '@mantine/core';
import Transactions_Table from './transactions_table';
import { notifications } from '@mantine/notifications';
import { Select } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { getPerformanceData } from '../../../../httpcalls/reportThunk';



const Transactions = (props) => {
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false)
    const dispatch = useDispatch()



    const alertTable = useSelector(state => state.mainapp.alertSlice?.alertTable)
    const close = () => setOpened(false)
    const [transaction, setTransaction] = useState('deposit');
    const [dateRange, setDateRange] = useState([null, null]);

    useEffect(() => {
        if(dateRange.includes(null) == false){
            dispatch(getPerformanceData({minDate: dateRange[0], maxDate: dateRange[1]}))
        }
        else if (dateRange[0] == null && dateRange[1] == null){
            dispatch(getPerformanceData())
        }
       
    }, [dateRange])

    return (
        <>

            {
                alertTable ?
                    <div style={{ gridTemplateRows: "162px 50px 1fr" }} className='nav-page explore-page vivify fadeIn duration-300 delay-200'>
                        <div style={
                            {
                                marginLeft: "35px",
                            }
                        } className='alert-strip'>
                            <h3>Transactions</h3>
                            <div className='alert-actions'>

                            </div>

                        </div>

                        <div className='activity-header' style={{ padding: "8px 35px" }}>
                            <div className='activity-sort'>
                                <div className='activity-segment vivify fadeIn duration-300 delay-200'>
                                    <div className='native-control'>

                                        <input onChange={
                                            (e) => {
                                                setTransaction(e.target.value)
                                            }
                                        } id='deposit' type="radio" defaultChecked={true} name='transaction' value={'deposit'} />
                                        <label for={'deposit'}>
                                            Deposit
                                        </label>
                                    </div>
                                    <div className='native-control'>

                                        <input onChange={
                                            (e) => {
                                                setTransaction(e.target.value)
                                            }
                                        } id='withdraw'  type="radio" name='transaction' value={'withdraw'} />
                                        <label for={'withdraw'}>

                                            Withdraw
                                        </label>
                                    </div>
                                </div>

                                <div style={{width: "fit-content"}} className='activity-select vivify fadeIn duration-300 delay-200'>
                                   <DatePickerInput 
                                    type="range"
                                    onChange={setDateRange}
                                    placeholder="Select Date Range"
                                    rightSection = {<i className='material-icons date-picker-icon'>unfold_more</i>}
                                    />
                                </div>
                            </div>

                        </div>

                        <Transactions_Table transactionType={transaction} />


                    </div>
                    : <h3>Data Unavailable</h3>
            }
            <style>
                {`
            .table-row {
                grid-template-columns: 45px 195px 187px 120px 90px 72px;
            }

            .activity-sort {
                justify-content: flex-start;
                align-items: center
            }

            .explore-page {
                display: grid;
    grid-template-rows: 102px 1fr;
  
    gap: 0px;
}
            }

            .activity-header {
                justify-content:center
            }

            .table-container {
                margin-top: 33px
                
            }

            .table-body {margin-left: 35px}

            .table-header {margin-left: 35px}

            .alert-strip {
                padding-top: 95px
            }
            `}
            </style>
        </>

    );


}

export default Transactions;