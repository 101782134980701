import { createAsyncThunk } from '@reduxjs/toolkit'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { alterNavigate } from '../store/schemas/helperFunctionsSlice'
import { notifications } from '@mantine/notifications';
import { useNavigate } from "react-router-dom";
import { navigate } from '../helper_functions/thunkNavigate';
import { updateUsers } from '../store/schemas/managerSlice';
import { getAllUsers } from './authThunk';
import { updateMirror } from '../store/schemas/settingSlice';

const server = process.env.REACT_APP_DOMAIN


export const getMirrorInfo = createAsyncThunk(
    'manager/getMirrorInfo',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=mirror&path=/getMirrorInfo`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(updateMirror(results.message))

            return 'success'

        } catch (error) {
            console.log(error)
        }
    }
)



export const updateMirrorInfo = createAsyncThunk(
    'manager/updateMirrorInfo',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=mirror&path=/updateMirrorInfo`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({mirror:data.mirror})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(updateMirror(results.message))

            return 'success'

        } catch (error) {
            console.log(error)
        }
    }
)
