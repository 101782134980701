import React, { useRef, useEffect, useState } from 'react';
import { Menu } from '@mantine/core';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import R_Option_Close_Modal from '../modals/r_option_close_modal';
import { Drawer } from '@mantine/core';
import R_ViewAll_Modal from '../modals/r_viewall_modal';
import { calculateMark, calculatePercentageChange } from '../../../../helper_functions/stockCalculations';
import W_OptionCard from './w_optioncard';
import { Notification } from '@mantine/core';
import W_RefreshButton from './w_refreshbutton';
import { notifications } from '@mantine/notifications';
import R_Sell_Modal from '../modals/r_sell_modal';
import { calculateTotalBTC } from '../../../../helper_functions/stockCalculations';
import E_Stock_Buy_Modal from '../../../explore/modals/e_stock_buy_modal';
import AuthContext from '../../../../components/authContext';
import { store } from '../../../../store/store';
import W_Btc from './w_btc';

const W_RackCard = ({ stockId, style, onBtcChange }) => {

    const [openedSell, { open: openSell, close: closeSell }] = useDisclosure(false);
    const [buyStockModal, { open: openBuyStock, close: closeBuyStock }] = useDisclosure(false);
    const refreshTimer = useSelector(state => state.mainapp.dataSlice.wheelRack.refresh)


    const [sideOpened, { open: sideOpen, close: sideClose }] = useDisclosure(false);
    const stockMarketData = useSelector((state) => state.mainapp.stockSlice.stocks[stockId].marketData)
    const stockShares = useSelector((state) => state.mainapp.stockSlice.stocks[stockId].shares)
    const stockAverageCostBasis = useSelector((state) => state.mainapp.stockSlice.stocks[stockId].averageCostBasis)
    const stockOrders = useSelector((state) => state.mainapp.stockSlice.stocks[stockId].orders)
    const [btcUpdate, setBtcUpdate] = useState(0)

    const totalBtc = useMemo(() => {
        let total = 0
        let state = store.getState()
        let optionDataMap = state.mainapp.stockSlice.stocks[stockId].stockOptionsMap
        const orderMap = state.mainapp.stockSlice.stocks[stockId].orderData
        for (let orderId of stockOrders) {
            let order = orderMap[orderId]
            let optionData = optionDataMap[order.dxSymbol]
            const mark = calculateMark(optionData?.eventTypes?.Quote?.bidPrice || 0, optionData?.eventTypes?.Quote?.askPrice || 0)
            total += calculateTotalBTC(order.quantity, mark)
        }
        if(onBtcChange != undefined){
            onBtcChange(stockId, total)
        }
        return total
    }, [btcUpdate])

    const toggleModal = () => {
        openedSell ? closeSell() : openSell();
    };

    const toggleBuyStockModal = () => {
        buyStockModal ? closeBuyStock() : openBuyStock();
    };
    const colorControl = (stockMarketData?.Trade?.change || 0).toString().includes('-') ? 'red' : 'green'

    useEffect(() => {
        console.log(stockOrders)
    }, [stockOrders])




    return (
        <>

            {
                    (() => {
                        let cards = []

                        for (let item of stockOrders) {
                            cards.push(<W_Btc orderId={item} stockId={stockId} setBtcUpdate={setBtcUpdate} />)
                        }
                        return cards
                    })()

            }


            <Drawer opened={sideOpened} onClose={sideClose} position='right' withCloseButton={false}>
                <R_ViewAll_Modal stockId={stockId}/>
            </Drawer>


            {openedSell ? <R_Sell_Modal stockId={stockId} modalToggle={toggleModal} /> : null}
            {buyStockModal ? <E_Stock_Buy_Modal stockId={stockId} modalToggle={toggleBuyStockModal} /> : null}


            <div className={
                style == 'default' ? 'rack-card' : 'rack-card rack-card-immersive'
            }>
                <div className='rack-ambient-light-strip'><div className={'rack-ambient-inner ambient-' + colorControl}></div></div>



                <div className='rack-card-header'>
                    <div className='rack-left-item'>
                        <AuthContext access={['admin', 'trader']}>
                            <Menu position='right-start' shadow="md" width={200}>
                                <Menu.Target>
                                    <button style={{ padding: "0px" }} className='global-more'><i className='material-icons'>more_horiz</i></button>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Label>Actions</Menu.Label>
                                    <Menu.Item onClick={
                                        () => {
                                            toggleModal()
                                        }
                                    } icon={<i className='material-icons-outlined colored-icon'>sell</i>}>Sell Stock</Menu.Item>
                                    <Menu.Item onClick={
                                        () => {
                                            toggleBuyStockModal()
                                        }
                                    } icon={<i className='material-icons-outlined colored-icon'>shopping_cart</i>}>Buy Stock</Menu.Item>
                                </Menu.Dropdown>

                            </Menu>
                        </AuthContext>
                        <div className='rack-header-left'>
                            <span>{(stockMarketData?.Trade?.price || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 })}</span>
                            <h2>{stockId}</h2>
                            <p>BTC: {totalBtc.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 3 })}</p>
                        </div>
                    </div>

                    <div className='rack-header-right'>
                        <span>{stockShares} Share(s) ACB {stockAverageCostBasis.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 4 })}</span>
                        <h4 className={'status-' + colorControl}>
                            {(stockMarketData?.Trade?.change || 0).toString().includes('-') ? '' : '+'}
                            {(stockMarketData?.Trade?.change || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' '}

                            ({
                                ((stockMarketData?.Trade?.change || 0).toString().includes('-')
                                    ? '-' : '+') +

                                calculatePercentageChange(stockMarketData?.Trade?.price, stockMarketData?.Trade?.change.toString().includes('-') ? (stockMarketData?.Trade?.price - stockMarketData?.Trade?.change) : (stockMarketData?.Trade?.price + stockMarketData?.Trade?.change)) + '%'
                            })
                        </h4>
                    </div>

                </div>

                {
                    style == 'default' ? null :
                        <W_RefreshButton style={style} type={"Rack"} />


                }

                {
                    (() => {
                        let cards = []

                        for (let item of stockOrders) {
                            if (style != 'immersive') {
                                if (cards.length < 2) {
                                    cards.push(<W_OptionCard orderId={item} stockId={stockId}  />)
                                }
                                else {
                                    break
                                }
                            }
                            else {
                                cards.push(<W_OptionCard orderId={item} stockId={stockId} />)
                            }

                        }
                        return cards
                    })()

                }

                {
                    (() => {
                        if (stockOrders.length == 0) {
                            return (
                                <>
                                    <div style={{ display: "flex", margin: 'auto', flexDirection: "column" }}>
                                        <h5 style={{ "fontSize": "1rem", "fontWeight": "600", "textAlign": "center" }}>No Active Wheels Detected</h5>
                                        <p style={{ fontSize: ".8rem", textAlign: 'center' }}>There are no active wheels for this stock. If you bought an option contract, it may still be processing on Tasty.</p>
                                    </div>

                                </>
                            )
                        }
                        else {
                            if (style == 'default' && stockOrders.length > 2) {
                                return (
                                    <div className='rack-item-footer'>
                                        <button onClick={
                                            () => {
                                                sideOpen()
                                            }
                                        } className='view-more-btn'>View More </button>
                                    </div>
                                )
                            }
                        }

                    })()
                }


            </div>
            <style>
                {
                    `
                    .mantine-Drawer-content {
                        padding:0px
                    }

                    .mantine-Drawer-body {padding-top:3px}
                    `
                }

            </style>
        </>
    );


}

export default React.memo(W_RackCard)