import React, { useState, useEffect } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import frostedGrid from '../../assets/frostedgrid.svg';
import C_Nav from './c_nav';
import W_Single_Stock_Container from '../widgets/components/w_single_stock/w_single_stock_container';
import W_Item_Card_Container from '../widgets/components/w_item_stock/w_item_card_container';
import W_Cash_Snap_Container from '../widgets/components/w_cash_snap/w_cash_snap_container';
import Volatility_Container from '../widgets/components/w_volatility/volatility_container';
import W_Stocks_Container from '../widgets/components/w_stocks/w_stocks_container';
import ReactGridLayout from 'react-grid-layout';
import W_Indices_Container from '../widgets/components/w_indices/w_indices_container';
const ResponsiveGridLayout = WidthProvider(RGL);

function C_Board() {

    const [canvasSize, setCanvasSize] = useState(
        localStorage.getItem('largeCanvas') == 'true' ? 6 : 3
    );
    const [canvasWidth, setCanvasWidth] = useState(
        localStorage.getItem('largeCanvas') == 'true' ? 2000 : 960
    );

    const availableWidgets = [
        /*    { component: W_Stocks_Container, id: 'yourstocks', name: 'Your Stocks', themeColor: '#6aaeff', icon: 'data_exploration' }, */


        {
            component: W_Indices_Container, actionBtn: 'Indices', id: 'indices', name: 'Indices Tracker', themeColor: '#ff003f', icon: 'label_important', featuredStock: [
                {
                    Symbol: '^DJI',
                    Name: 'DJIA',
                },
                {
                    Symbol: '^IXIC',
                    Name: 'NASDAQ',
                },
                {
                    Symbol: '^GSPC',
                    Name: 'S&P 500',
                },
                {
                    Symbol: '^RUT',
                    Name: 'Russell 2000',
                },
            ]
        },
        { component: W_Cash_Snap_Container, id: 'cashsnap', name: 'Buying Power Tracker', themeColor: '#40D547', icon: 'payments' },

        { component: W_Single_Stock_Container, id: 'singlestock', name: 'Stock Tracker', themeColor: '#BD00FF', icon: 'leaderboard' },
        { component: Volatility_Container, id: 'vix', name: 'Volatility Index', themeColor: '#0055ff', icon: 'area_chart', },
        {
            component: W_Item_Card_Container, actionBtn: 'Oil Stock', id: 'oiltracker', name: 'Oil Tracker', themeColor: '#ff0071', icon: 'oil_barrel', featuredStock: [
                {
                    Symbol: 'BZ=F',
                    Name: 'Brent Crude Oil (BZ=F)',
                },
                {
                    Symbol: 'CL=F',
                    Name: 'WTI Crude Oil (CL=F)',
                },
            ]
        },
        {
            component: W_Item_Card_Container, id: 'treasury', name: 'Treasury Tracker', themeColor: '#00ffc1', icon: 'account_balance', custom: false, actionBtn: 'Treasury Time', featuredStock: [
                {
                    Symbol: '^IRX',
                    Name: '13 Week',
                },
                {
                    Symbol: '^FVX',
                    Name: '5 Year',
                },
                {
                    Symbol: '^TNX',
                    Name: '10 Year',
                },
                {
                    Symbol: '^TYX',
                    Name: '30 Year',
                },

            ]
        },
        {
            component: W_Item_Card_Container, actionBtn: 'Currency', custom: false, id: 'cryptotracker', name: 'Crypto Tracker', themeColor: '#ffc800', icon: 'currency_bitcoin', featuredStock: [
                {
                    Symbol: 'BTC-USD',
                    Name: 'Bitcoin (BTC-USD)',
                },
                {
                    Symbol: 'ETH-USD',
                    Name: 'Ethereum (ETH-USD)',
                },
                {
                    Symbol: 'BNB-USD',
                    Name: 'Binance Coin (BNB-USD)',
                },

            ]
        },

    ];

    const [layout, setLayout] = useState(
        () => JSON.parse(window.localStorage.getItem('layout')) || []
    );

    useEffect(() => {
        window.localStorage.setItem('layout', JSON.stringify(layout));
    }, [layout]);

    const onLayoutChange = (newLayout) => {
        setLayout(newLayout);
    };

    const calculateNewItemPosition = (layout) => {
        let maxY = 0;
        let cells = new Set(layout.map(item => `${item.x},${item.y}`));
        let newX = 0;
        let newY = 0;

        while (true) {
            let isSpaceFree = true;
            for (let x = newX; x < newX + 2; x++) {
                for (let y = newY; y < newY + 2; y++) {
                    if (cells.has(`${x},${y}`)) {
                        isSpaceFree = false;
                        break;
                    }
                }
            }

            if (isSpaceFree) {
                return { x: newX, y: newY };
            }
            newX++;
            if (newX + 2 > 12) {
                newX = 0;
                newY++;
            }
            maxY = Math.max(maxY, newY + 2);
        }
        return { x: 0, y: maxY };
    };

    const removeItem = (itemId) => {
        setLayout((prevLayout) => {
            const newLayout = prevLayout.filter((item) => item.i !== itemId);
            window.localStorage.setItem('layout', JSON.stringify(newLayout));
            return newLayout;
        });
    };

    const addWidget = (widgetPropsData) => {

        setLayout((prevLayout) => {
            const newItemPosition = calculateNewItemPosition(prevLayout);
            const newItem = {
                x: newItemPosition.x,
                y: newItemPosition.y,
                w: 1,
                h: 1.17,
                i: widgetPropsData.plainId + "-" + widgetPropsData.uniqueId,
            };
            const newLayout = [...prevLayout, newItem];
            window.localStorage.setItem('layout', JSON.stringify(newLayout));
            return newLayout;
        });
    };


    return (
        <>


            <C_Nav onAddWidget={addWidget} />
            <div className='grid-parent' id='gridParen' >
                <div id='frostedGrid' className='frosted-grid' style={{ backgroundImage: `url(${frostedGrid})` }}></div>


                <ReactGridLayout

                    layout={layout}
                    onLayoutChange={onLayoutChange}

                    draggableHandle=".drag-handle"
                    className="layout"
                    cols={
                        canvasSize
                    }
                    width={canvasWidth}
                    rowHeight={parseInt(870 / 6)}
                    maxRows={6}
                    containerPadding={[0, 0]}

                    verticalCompact={true}
                    isResizable={false}
                    allowOverlap={false}
                    useCSSTransforms={true}
                    onDrag={(layout, oldItem, newItem, placeholder, e, element) => {
                        document.getElementById('frostedGrid').style.opacity = "0.7";
                    }}
                    onDragStop={(layout, oldItem, newItem, placeholder, e, element) => {
                        document.getElementById('frostedGrid').style.opacity = "0";
                    }}
                >
                    {layout.map((item) => (


                        <div className='widget-canvas' key={item.i}>
                            <div className='widget-canvas-inner'>


                                {/* This is the part of the widget that can be used to drag the entire widget */}
                                <span className="drag-handle"><div className='drag-pill'></div></span>


                                {/*    <button onClick={() => removeItem(item.i)}>Remove</button>
 */}

                                <div key={
                                    availableWidgets.find(widget => widget.id === (item.i).split("-", 2)[0]).uniqueId
                                } className="widget-wrapper canvas-wrapper">
                                    {React.createElement(
                                        availableWidgets.find(widget => widget.id === (item.i).split("-", 2)[0]).component
                                        , {
                                            ...(availableWidgets.find(widget => widget.id === (item.i).split("-", 2)[0])),
                                            uniqueId: item.i,
                                            canvas: true,
                                            removeCanvasWidget: removeItem,
                                        })}


                                </div>
                            </div>
                        </div>
                    ))}
                </ReactGridLayout>
            </div>
        </>

    );
}

export default C_Board;
