import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Button, Text } from '@mantine/core';
import { Tooltip } from '@mantine/core';

const DarkModeController = (props) => {

    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') == 'true' ? true : false)



    return (
        <>

            {
                props.init == true ? <Menu.Item

                    onClick={() => {

                        localStorage.setItem('darkMode', !darkMode)
                        window.location.reload()
                        if (darkMode) {
                            localStorage.setItem('duneKillerMode', false)
                        }
                    }}
                >
                    {
                        darkMode ? 'Disable Dark Mode' : 'Enable Dark Mode'
                    }
                </Menu.Item>

                    : null
            }



            {
                darkMode ? <style>{`

                .react-grid-placeholder {
               border:  3px solid rgba(255, 255, 255, 0.12)
                }

                .frosted-grid {filter:none !important;}

               .mantine-PickerControl-pickerControl {
                    color: white !important;
               }

               .mantine-PickerControl-pickerControl:hover {
                    background-color: rgb(36, 36, 36);
                }

                .mantine-Popover-dropdown {
                    background-color: var(--base-gray);
                    color: white !important;
                    border:var(--frostedBorder);
                }

                .mantine-CalendarHeader-calendarHeaderLevel:hover {
                    background-color: rgb(36, 36, 36);
                }

                .mantine-7yisjr[data-in-range] {background-color:rgb(36, 36, 36)}

              .mantine-DatePickerInput-monthCell {color:white !important;}

              .mantine-DatePickerInput-day:hover {background-color:rgb(36, 36, 36) !important;}

              .mantine-DatePickerInput-day {
                    color: white !important;
              }

                .mantine-CalendarHeader-calendarHeaderLevel {
                    color: white !important;
                }
                
                .mantine-1bpldn3 {
                    color: white !important;
                }

                .mantine-DateInput-month {color: white !important;}
            
                .mantine-DateInput-day{color:white;}

                .modal-close {outline:none}
                .modal-close > i {color: white;}

.focused-nav > label {
    background-color: rgb(36, 36, 36);
    color: white;
}
     .focused-nav>button:hover {
         background-color: rgb(36, 36, 36);
         color: white;
     }
     .query-btn > button:hover {     background-color: rgb(36, 36, 36);}
     body {background-color: var(--base-gray);}
  
     .rack-right>button {
         background-color: var(--base-gray);
     }
     .focused-nav>button {
         background-color: rgb(36, 36, 36);
         color: white;
     }
     .mantine-Select-dropdown {
         background-color: var(--base-gray);
         border: var(--frostedBorder)
     }
     .mantine-Modal-body  {
         background-color: var(--base-gray)
     }
     .mantine-Spotlight-action:hover {
         background-color: rgb(36, 36, 36);
     }
     .mantine-1tc7bs, .mantine-1tc7bs:focus-within {
         background-color: var(--base-gray);
         color: white;
         border-bottom: 0.0625rem solid #e9ecef12;
     }

     *{
         color: white;
     }
     .login-title>h1 {
         color: white;
     }
     .focused-nav>button>span {
         color: white;
     }
     .login-form-input>label {
         color: white;
     }
     .mantine-y706ni {background-color: var(--base-gray);}
     .modal-header > h3 {
         color: white;
     }
     .mantine-Select-input {
         color: white;
     }
     .modal-header > button > i {
         color: white;
     }
     .action-form-input > input {
         background-color: transparent;
     }
     :root {
        
         --remove-color: #ff0000;
         --base-gray: #181818;
         --lightFrostedBackground: #1f1f1f;
         --darkFrostedBackground: #1a1a1a;
         --frostedBorder: 1px solid rgb(255 255 255 / 7%);
         --frostedText: rgb(255 255 255 / 54%);
         --text-gray: #b3b3b3;
         --main-text: white;
     }
     
     .login {
         background-color: rgb(36, 36, 36);
     }
     .mantine-ym7ws5::before {
         background-color: var(--base-gray)
     }
     .login-form-input>input {
         background-color: var(--base-gray) !important;
     }
     .mantine-Menu-item:hover {background-color: rgb(36, 36, 36);;}
     .mantine-SegmentedControl-label {color: white !important;}
     .mantine-1nbod7e {
         background-color: var(--base-gray);
         border: solid 1px #ffffff1c;
     }
     .mantine-891iys{
        background-color: var(--base-gray);
        border: solid 1px #ffffff1c;
     }
     .mantine-ecft5f {
         background-color: var(--base-gray)
     }
     .mantine-1k9itrp {
         background-color: var(--base-gray)
     }
     .mantine-731ldn {
         background-color: var(--base-gray)
     }
     .mantine-19pz3dh {
         background-color: var(--base-gray)
     }
     .mantine-qduq72[data-hovered] {
         background-color: var(--base-gray)
     }
     .mantine-kcpopt:focus,
     .mantine-kcpopt:focus-within {
         outline: solid 2px var(--base-gray)
     }
     .mantine-1k9itrp {
         background-color: var(--base-gray)
     }
     .mantine-ecft5f {
         background-color: var(--base-gray)
     }
     .mantine-1nbod7e {
         background-color: var(--base-gray)
     }
     .mantine-ym7ws5::before {
         background-color: var(--base-gray)
     }
     .mantine-ym7ws5 {background-color: rgb(36, 36, 36);}
     .mantine-Accordion-label {
         color: white;
     }
     .mantine-MultiSelect-input {color: white;}
     .mantine-b47ys6 {color: white;}
     .table-container {background-color: var(--base-gray);}
     .activity-query > input {color: white;}

     .mantine-MultiSelect-item {
         background-color: var(--base-gray);
         color: white !important;
     }
     .mantine-MultiSelect-label {color: white;}
     .mantine-Select-item {
         color: white !important;
     }

     .mantine-Select-item:hover {
         background-color: rgb(36, 36, 36) !important;
     }
     [data-hovered="true"] {
         background-color: rgb(36, 36, 36) !important;
     }
     .mantine-1qnf9fo:hover {
         background-color:rgb(36, 36, 36);
     }
  
     .mantine-Tooltip-tooltip {
         background-color:transparent;
      
     
     }

     .activity-title > h4 > span {
         color: white
     }
     .mantine-kcpopt:focus,
.mantine-kcpopt:focus-within {
    border-color: var(--main-color);
    border-width: 3px;
}

.learn-block > p {
    color: white;
}

.mantine-1s2yyo5 {
    border: var(--frostedBorder);
}

.learn-block > ul > li {
    color:white
}
 
.mantine-hp9ccp:hover {
    background-color: transparent
}
 
.mantine-1s2yyo5[data-active] {
    background-color: transparent
}

.query-close>i {
    color: black
}

.mantine-1s29mgf {
    border-top: var(--frostedBorder)
}

.rack-feed-item {
    background-color:transparent
}

.header-action-btn > button > i:hover {color: white;}

.widget {outline:none; background-color: rgb(36, 36, 36)}




.mantine-1rnkhfs {color: white}

.tracking-accordion-wrapper {
    background-color: rgb(36, 36, 36)
}

.modal-close {background-color: rgba(255, 255, 255, 0.106)}

.mantine-1bpldn3 {
    background-color: var(--base-gray);
}
.mantine-DatePickerInput-placeholder {
    color: white;
}

            `}</style> : ''
            }
        </>
    );

}

export default DarkModeController;