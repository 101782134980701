import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { logout } from "../httpcalls/authThunk";
import { useEffect } from "react";
import { initializeWebSocket, killWebSocket } from "../websocket/websocket";
import { notifications } from "@mantine/notifications";


const MainDirectory = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const webSocketClientId = useSelector((state) => state.auth.webSocketClientId)
    useEffect(() => {
      if(webSocketClientId == null){
        initializeWebSocket()
      }
    }, [])

    return (
      <>
    <Outlet />

    
      </>
    );
  }
  
export default MainDirectory;