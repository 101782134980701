import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';


const Manager_Table = (props) => {
    const navigate = useNavigate();
    const alertTable = useSelector(state => state.mainapp.alertSlice?.alertTable)

    return (
        <>

            <div className='table-container' style={{ padding: "0px" }}>
                <div className='table-header'>
                    <div className='table-row'>
                        <span>Action</span>
                        <span>Name</span>
                        <span>Last Trigger</span>
                        <span># Of Triggers</span>
                        <span>Status</span>
                    </div>
                </div>
                <div className='table-body'>
                    {
                        alertTable.map((item, index) => {
                            return (
                                <div className='table-row table-active-row' key={index}>
                                    <div className='rack-right table-icon'>
                                        <Menu position='right-start' shadow="md" width={200}>
                                            <Menu.Target>
                                                <button><i className='material-icons'>more_horiz</i></button>
                                            </Menu.Target>
                                            <Menu.Dropdown>


                                                <Menu.Label>Actions</Menu.Label>

                                                <Menu.Item icon={<i className='material-icons-outlined colored-icon'>edit_notifications</i>}>Edit Alert</Menu.Item>


                                                <Menu.Item onClick={() =>
                                                    notifications.show({
                                                        title: 'Alert Muted',
                                                        message: 'Your alert has been muted successfully',
                                                        icon: <i style={{backgroundColor: 'rgb(0, 187, 212)'}} className='material-icons-outlined'>do_not_disturb_on</i>,
                                                        withCloseButton: false,
                                                    })
                                                } icon={<i className='material-icons colored-icon'>notifications_paused</i>}>Mute Notifications</Menu.Item>


                                                <Menu.Divider />

                                                <Menu.Label>Danger Zone</Menu.Label>

                                                <Menu.Item onClick={() =>
                                                    notifications.show({
                                                        title: 'Alert Deleted',
                                                        message: 'Your alert has been deleted successfully',
                                                        icon: <i style={{backgroundColor: 'var(--remove-color)'}} className='material-icons-outlined'>delete_sweep</i>,
                                                        withCloseButton: false,
                                                    })
                                                } color="red" icon={<i className='material-icons-outlined remove-icon'>delete</i>}>Delete Alert</Menu.Item>


                                            </Menu.Dropdown>
                                        </Menu>
                                    </div>



                                    <span><span style={
                                        {
                                            backgroundColor: "#" + item.color,
                                        }
                                    } className='status-dot'></span>{item.name}</span>
                                    <span>{item.lastTrigger}</span>
                                    <span>{item.numberOfTriggers}</span>
                                    <span>{item.status}</span>


                                </div>
                            )
                        })
                    }
                </div>

            </div>

        </>
    );

}

export default Manager_Table;