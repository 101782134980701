import React, { useState } from 'react';
import { Menu } from '@mantine/core';
import DarkModeController from '../../components/themes/darkmodeController';
import W_Stocks from './components/w_stocks/w_stocks_card'; // Assuming you need it as it's in your imports
import W_Stocks_Container from './components/w_stocks/w_stocks_container';
import { useLocalStorage } from 'usehooks-ts';
import { logout } from '../../httpcalls/authThunk';
import { useDispatch, useSelector } from 'react-redux';
import W_Single_Stock_Container from './components/w_single_stock/w_single_stock_container';
import W_Item_Card_Container from './components/w_item_stock/w_item_card_container';
import W_Indices_Container from './components/w_indices/w_indices_container';
import W_Cash_Container from './components/w_availableCash/w_cash_container';
import W_Crypto_Container from './components/w_crypto/w_crypto_container';
import { navigate } from '../../helper_functions/thunkNavigate';
import ModalController from '../user/modalController';
import AuthContext from '../../components/authContext';
import W_Cash_Snap_Container from './components/w_cash_snap/w_cash_snap_container';
import Volatility_Container from './components/w_volatility/volatility_container';
import Settings from '../settings/settings';
import CompactModeController from '../../components/themes/compactmodeController';




const WidgetPanel = () => {
    const [addedWidgets, setAddedWidgets] = useLocalStorage('addedWidgets', []);
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.userInfo);
    const [ModalToggle, setModalToggle] = useState(false);
    const [SettingsToggle, setSettingsToggle] = useState(false);


    const addWidget = (id) => {
        const newWidget = {
            id,
            uniqueId: Math.random().toString(36).substr(2, 9),
        };
        setAddedWidgets(state => [...state, newWidget]);
    };

    const moveWidgetUp = (index) => {
        setAddedWidgets((prevWidgets) => {
            const newWidgets = [...prevWidgets];
            if (index === 0) return newWidgets;
            const tempWidget = newWidgets[index];
            newWidgets[index] = newWidgets[index - 1];
            newWidgets[index - 1] = tempWidget;
            return newWidgets;
        });
    };

    // New function to handle moving a widget down in the list
    const moveWidgetDown = (index) => {
        setAddedWidgets((prevWidgets) => {
            const newWidgets = [...prevWidgets];
            if (index === newWidgets.length - 1) return newWidgets;
            const tempWidget = newWidgets[index];
            newWidgets[index] = newWidgets[index + 1];
            newWidgets[index + 1] = tempWidget;
            return newWidgets;
        });
    };

    const removeWidget = (uniqueId) => {
        setAddedWidgets(state => state.filter(widget => widget.uniqueId !== uniqueId));
    };

    const availableWidgets = [
        { component: W_Stocks_Container, id: 'yourstocks', name: 'Your Stocks', themeColor: '#6aaeff', icon: 'data_exploration', canvas: false },
        { component: W_Cash_Snap_Container, id: 'cashsnap', name: 'Buying Power Tracker', themeColor: '#40D547', icon: 'payments', canvas: false },


        { component: W_Single_Stock_Container, id: 'singlestock', name: 'Stock Tracker', themeColor: '#BD00FF', icon: 'leaderboard', canvas: false },
        { component: Volatility_Container, id: 'vix', name: 'Volatility Index (Beta)', themeColor: '#0055ff', icon: 'area_chart', canvas: false },


        {
            component: W_Cash_Container, actionBtn: 'Indices', id: 'availableCash', name: 'Available To Withdraw', themeColor: '#9bff00', icon: 'monetization_on'
        },


        {
            component: W_Indices_Container, actionBtn: 'Indices', id: 'indices', name: 'Indices Tracker', themeColor: '#ff003f', icon: 'label_important', featuredStock: [
                {
                    Symbol: '^DJI',
                    Name: 'DJIA',
                },
                {
                    Symbol: '^IXIC',
                    Name: 'NASDAQ',
                },
                {
                    Symbol: '^GSPC',
                    Name: 'S&P 500',
                },
                {
                    Symbol: '^RUT',
                    Name: 'Russell 2000',
                },
            ]
        },
        {
            component: W_Item_Card_Container, actionBtn: 'Oil Stock', id: 'oiltracker', name: 'Oil Tracker', themeColor: '#ff0071', icon: 'oil_barrel', canvas: false, featuredStock: [
                {
                    Symbol: 'BZ=F',
                    Name: 'Brent Crude Oil (BZ=F)',
                },
                {
                    Symbol: 'CL=F',
                    Name: 'WTI Crude Oil (CL=F)',
                },
            ]
        },
        {
            component: W_Item_Card_Container, id: 'treasury', name: 'Treasury Tracker', themeColor: '#00ffc1', icon: 'account_balance', custom: false, canvas: false, actionBtn: 'Treasury Time', featuredStock: [
                {
                    Symbol: '^IRX',
                    Name: '13 Week',
                },
                {
                    Symbol: '^FVX',
                    Name: '5 Year',
                },
                {
                    Symbol: '^TNX',
                    Name: '10 Year',
                },
                {
                    Symbol: '^TYX',
                    Name: '30 Year',
                },

            ]
        },
        {
            component: W_Crypto_Container, actionBtn: 'Currency', custom: false, id: 'cryptotracker', name: 'Crypto Tracker', themeColor: '#ffc800', canvas: false, icon: 'currency_bitcoin', featuredStock: [
                {
                    Symbol: 'BTC-USD',
                    Name: 'Bitcoin (BTC-USD)',
                },
                {
                    Symbol: 'ETH-USD',
                    Name: 'Ethereum (ETH-USD)',
                },
                {
                    Symbol: 'BNB-USD',
                    Name: 'Binance Coin (BNB-USD)',
                },

            ]
        },

    ];

    const logoutUser = () => {
        dispatch(logout());
    };

    return (
        <>

            {
                ModalToggle ? <ModalController setModalToggle={setModalToggle} /> : null
            }
            {
                SettingsToggle ? <Settings setSettingsToggle={setSettingsToggle} /> : null
            }

            <div className="widget-panel">
                <div className="widget-list">
                    {
                        addedWidgets.map((widgetObj, index) => {
                            const widget = availableWidgets.find(widget => widget.id === widgetObj.id);
                            if (widget) {
                                const widgetProps = {
                                    ...widget,
                                    uniqueId: widgetObj.uniqueId,
                                    removeWidget: () => removeWidget(widgetObj.uniqueId),
                                };

                                return (
                                    <div key={widgetObj.uniqueId} className="widget-wrapper widget-side">
                                        {React.createElement(widget.component, widgetProps)}
                                        <div className='widget-updown vivify fadeIn duration-300'>
                                            <button onClick={() => moveWidgetUp(index)} disabled={index === 0}>
                                                <i className='material-icons'>expand_less</i>
                                            </button>
                                            <button onClick={() => moveWidgetDown(index)} disabled={index === addedWidgets.length - 1}>
                                                <i className='material-icons'>expand_more</i>
                                            </button>
                                        </div>
                                    </div>
                                );
                            } else {
                                console.log(`No widget found with id: ${widgetObj.id}`);
                                return null;
                            }
                        })
                    }
                </div>

                <div className="widget-actions">
                    <Menu openDelay={100} closeDelay={200} position='bottom-start' shadow="md" width={200}>
                        <Menu.Target>

                            <div className='account-strip'>
                                <i className='material-icons'>manage_accounts</i>

                            </div>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <AuthContext access={['admin']}>
                                <Menu.Label>Admin

                                </Menu.Label>

                                <Menu.Item onClick={
                                    () => {
                                        setModalToggle(true)
                                    }
                                } icon={<i className='material-icons-outlined colored-icon'>admin_panel_settings</i>}>User Management</Menu.Item>
                            </AuthContext>
                            <Menu.Label>{(user.email)}

                            </Menu.Label>

                            <Menu.Item onClick={logoutUser} icon={<i className='material-icons-outlined colored-icon'>logout</i>}>Logout</Menu.Item>
                            <Menu.Item onClick={
                                () => {
                                    setSettingsToggle(true)
                                }
                            } icon={<i className='material-icons-outlined colored-icon'>settings</i>}>Settings</Menu.Item>

                        </Menu.Dropdown>
                    </Menu>

                    <Menu position="bottom-center" shadow="md" width={230} offset={20} >
                        <Menu.Target>
                            <button className='add-widget'><i className='material-icons-outlined'>widgets</i>Customize</button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label>Customize</Menu.Label>
                            <DarkModeController init={true} />
                            <CompactModeController init={true} />
                            <Menu.Label>Add Widgets</Menu.Label>

                            {
                                availableWidgets.map(widget => (
                                    <Menu.Item
                                        key={widget.id}
                                        onClick={() => addWidget(widget.id)}
                                        icon={<i style={
                                            {
                                                color: widget.themeColor || 'var(--main-color)',
                                                fontSize: '17px'
                                            }
                                        } className='material-icons'>{widget.icon}</i>}
                                    >
                                        {widget.name}
                                    </Menu.Item>
                                ))
                            }
                        </Menu.Dropdown>
                    </Menu>

                    <div className='place-chunk'></div>
                </div>
            </div>

        </>
    );
};

export default React.memo(WidgetPanel);
