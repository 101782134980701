// Customize.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alterManagedWidgets } from '../../store/schemas/canvasSlice';


import W_Single_Stock_Container from '../widgets/components/w_single_stock/w_single_stock_container';
import W_Item_Card_Container from '../widgets/components/w_item_stock/w_item_card_container';
import W_Cash_Snap_Container from '../widgets/components/w_cash_snap/w_cash_snap_container';
import Volatility_Container from '../widgets/components/w_volatility/volatility_container';
import W_Stocks_Container from '../widgets/components/w_stocks/w_stocks_container';
import { Menu } from '@mantine/core';
import { useState } from 'react';
import DuneKillerController from '../../components/themes/dunekillerController';


const C_Nav = ({ onAddWidget }) => {
    const dispatch = useDispatch()
    const [canvasSize, setCanvasSize] = useState(localStorage.getItem('largeCanvas') || false);




    const availableWidgets = [
        /*       { component: W_Stocks_Container, id: 'yourstocks', name: 'Your Stocks', themeColor: '#6aaeff', icon: 'data_exploration' }, */
        { component: W_Cash_Snap_Container, id: 'cashsnap', name: 'Buying Power Tracker', themeColor: '#40D547', icon: 'payments' },

        { component: W_Single_Stock_Container, id: 'singlestock', name: 'Stock Tracker', themeColor: '#BD00FF', icon: 'leaderboard' },
        { component: Volatility_Container, id: 'vix', name: 'Volatility Index', themeColor: '#0055ff', icon: 'area_chart', },



        {
            component: W_Item_Card_Container, actionBtn: 'Indices', id: 'indices', name: 'Indices Tracker', themeColor: '#ff003f', icon: 'label_important', featuredStock: [
                {
                    Symbol: '^DJI',
                    Name: 'DJIA',
                },
                {
                    Symbol: '^IXIC',
                    Name: 'NASDAQ',
                },
                {
                    Symbol: '^GSPC',
                    Name: 'S&P 500',
                },
                {
                    Symbol: '^RUT',
                    Name: 'Russell 2000',
                },
            ]
        },
        {
            component: W_Item_Card_Container, actionBtn: 'Oil Stock', id: 'oiltracker', name: 'Oil Tracker', themeColor: '#ff0071', icon: 'oil_barrel', featuredStock: [
                {
                    Symbol: 'BZ=F',
                    Name: 'Brent Crude Oil (BZ=F)',
                },
                {
                    Symbol: 'CL=F',
                    Name: 'WTI Crude Oil (CL=F)',
                },
            ]
        },
        {
            component: W_Item_Card_Container, id: 'treasury', name: 'Treasury Tracker', themeColor: '#00ffc1', icon: 'account_balance', custom: false, actionBtn: 'Treasury Time', featuredStock: [
                {
                    Symbol: '^IRX',
                    Name: '13 Week',
                },
                {
                    Symbol: '^FVX',
                    Name: '5 Year',
                },
                {
                    Symbol: '^TNX',
                    Name: '10 Year',
                },
                {
                    Symbol: '^TYX',
                    Name: '30 Year',
                },

            ]
        },
        {
            component: W_Item_Card_Container, actionBtn: 'Currency', custom: false, id: 'cryptotracker', name: 'Crypto Tracker', themeColor: '#ffc800', icon: 'currency_bitcoin', featuredStock: [
                {
                    Symbol: 'BTC-USD',
                    Name: 'Bitcoin (BTC-USD)',
                },
                {
                    Symbol: 'ETH-USD',
                    Name: 'Ethereum (ETH-USD)',
                },
                {
                    Symbol: 'BNB-USD',
                    Name: 'Binance Coin (BNB-USD)',
                },

            ]
        },

    ];


    return (
        <>

            <div className='canvas-nav'>
                <Menu position="bottom-center" shadow="md" width={250} offset={20} >
                    <Menu.Target>
                        <button className='add-widget'><i className='material-icons-outlined'>widgets</i>Customize</button>
                    </Menu.Target>
                    <Menu.Dropdown>




                        {/* 
                        <Menu.Item onClick={() => {

                            window.localStorage.setItem('layout', JSON.stringify([]));
                            window.location.reload()
                        }} icon={<i style={
                            {
                                fontSize: '17px'
                            }
                        } className='material-icons'>delete</i>}>Clear Canvas</Menu.Item>
 */}


                        <Menu.Label>Add Widgets</Menu.Label>



                        {
                            availableWidgets.map(widget => (
                                <Menu.Item
                                    key={widget.id}
                                    onClick={() => {
                                        onAddWidget(
                                            {

                                                id: widget.id + new Date().getTime().toString(),
                                                name: widget.name,
                                                themeColor: widget.themeColor,
                                                icon: widget.icon,
                                                actionBtn: widget.actionBtn,
                                                featuredStock: widget.featuredStock,
                                                custom: widget.custom,
                                                uniqueId: widget.id + new Date().getTime().toString(),
                                                canvas: true,
                                                plainId: widget.id,
                                                component: widget.component,


                                            }
                                        )
                                    }}
                                    icon={<i style={
                                        {
                                            color: widget.themeColor || 'var(--main-color)',
                                            fontSize: '17px'
                                        }
                                    } className='material-icons'>{widget.icon}</i>}
                                >
                                    {widget.name}
                                </Menu.Item>
                            ))
                        }

                        <Menu.Label>Experimental</Menu.Label>

                        {
                            localStorage.getItem('largeCanvas') == 'true' ? (
                                <Menu.Item onClick={() => {
                                    {
                                        localStorage.setItem('largeCanvas', !canvasSize)
                                        window.location.reload()
                                    }
                                }} icon={<i className='material-icons' style={{ fontSize: '17px' }
                                }>fit_screen</i>}>{
                                        canvasSize == 'true' ? 'Disable Wider Canvas' : 'Enable Wider Canvas'
                                    }</Menu.Item>) : <Menu.Item onClick={() => {
                                        { localStorage.setItem('largeCanvas', true); window.location.reload() }
                                    }} icon={<i className='material-icons' style={{ fontSize: '17px' }
                                    }>fit_screen</i>}>{
                                    'Enable Wider Canvas'
                                }</Menu.Item>


                        }

                        <DuneKillerController init={true} />
                    </Menu.Dropdown>
                </Menu>
            </div>



        </>
    );
}

export default C_Nav;
