import React, { useRef, useEffect, useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import { Select } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ExploreLearn from '../../explore/explore_learn';
import { Drawer } from '@mantine/core';




const Manager_Add = ({ item, accountBalance, buySell, callPut, selectedDate, modalToggle }) => {
    const [loading, setLoading] = useState(false)
    const [formResults, setFormResults] = useState()
    const dispatch = useDispatch()
    const [opened, { open, close }] = useDisclosure(false);
    const [shares, setShares] = useState(1)
    const [limit, setLimit] = useState(0)
    /*     const [limit, setLimit] = useState(item.mark || 0) */


    const handleForm = (e) => {
        e.preventDefault()



    }
  

    return (
        <>
            <Drawer opened={opened} onClose={close} position='right' withCloseButton={false}>
                <ExploreLearn />
            </Drawer>
            {
                loading ?
                    <div className='loading-ind'>
                        <div class="indeterminate-progress-bar"><div class="indeterminate-progress-bar__progress"></div></div>
                    </div> :
                    <form onSubmit={
                        (e) => {
                            e.preventDefault()
                            handleForm(e)
                        }
                    }>    <div className='action-form'>
                            <h1>New Alert</h1>
                            <div className='action-form-inputs'>
                                <div style={{
                                    flexDirection: 'column',
                                    gap: '10px'
                                }} className='action-form-input'>

                                    <label>Name</label>

                                    <input placeholder='EX: amazon strike price change' type='text' />
                                </div>
                                <div style={{
                                    flexDirection: 'column',
                                    gap: '10px'
                                }} className='action-form-input'>
                                    <label>Alert Trigger</label>
                                    <div  className={
                                        'activity-query smart-query-open inline-query'
                                    }>

                                        <input onChange={
                                            (e) => { }
                                        } placeholder='Enter Smart Query...' type='text' ></input>


                                        <Tooltip offset={
                                            20
                                        } label="Query Guide">
                                            <button onClick={open} className='resource-btn' style={
                                                { marginRight: '50px' }
                                            }><i className='material-icons-outlined'>auto_stories</i>
                                            </button>
                                        </Tooltip>

                                    </div>
                                </div>

                            </div>
                            <div className='action-form-submit' style={{marginTop: "30px"}}>

                                <button className='lg-btn'>Confirm</button>
                            </div>


                        </div></form>
                
            }
            <style>
            {
                `
                .inline-query {
                        border-radius: 5px;
                        outline:none;
                        padding: 4px 5px;
                        grid-template-column: 1fr 40px
                }

                .inline-query > input::placeholder {
                    color:var(--text-gray)
                }


                `
            }
            </style>

        </>
    );

}

export default Manager_Add;