import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
     stocks:{
        activeStocks:[]
      
    }
})


const stockSlice = createSlice({
    name: 'Stock Slice',
    initialState: initialState(),
    reducers: {
        resetCurrentStock: state => initialState(),
        addStockSymbol:(state, action)=>{ 
            for(let stocks of action.payload.stocks){
                if(state.stocks[stocks.symbol] == undefined){
                    state.stocks[stocks.symbol] = {
                        activeListeners:[action.payload.listenType],
                        stockName:stocks.symbol,
                        shares:0,
                        averageCostBasis:0,
                        description: stocks.description,
                        marketData:{
                            Trade:null,
                            Summary:null,
                            Quote:null,
                            Profile:null,
                            Greeks:null
                        },
                        dates: stocks.dates,
                        optionsContracts:stocks.optionsContracts,
                        stockOptions: [],
                        stockOptionsMap:{},
                        orders:[],
                        orderData:{}
                    }
                    state.stocks.activeStocks.push(stocks.symbol)
                }
                else{
                    if(!state.stocks[stocks.symbol].activeListeners.includes(action.payload.listenType)){
                        state.stocks[stocks.symbol].activeListeners.push(action.payload.listenType)
                    }
                }
            }
            
        },

        updateStockSymbolDescription:(state, action)=>{ 
            if(state.stocks[action.payload.symbol] != undefined){
                state.stocks[action.payload.symbol].description = action.payload.description
            }
        },
        updateStockSocketData:(state, action)=>{   
            // console.log(action)

            for(let stockId in  action.payload.stocksToUpdate){
                for(let event in action.payload.stocksToUpdate[stockId]){
                    if(action.payload.stocksToUpdate[stockId][event] != null){
                        if(state.stocks[stockId] != undefined){
                            state.stocks[stockId].marketData[event] = action.payload.stocksToUpdate[stockId][event]
                        }
                        
                    }
                }
            }
        },

        updateStockExpiration:(state, action)=>{            
            state.stocks[action.payload.symbol].dates = action.payload.dates
            state.stocks[action.payload.symbol].optionsContracts = action.payload.optionsContracts
        },
        updateStockOptions:(state, action)=>{   
            state.currentStockView.stockOptions = action.payload
        },
        addStockOptionsMap:(state, action)=>{
            function insertUsingBinarySearch(stockOptions, newOption) {
                let low = 0, high = stockOptions.length - 1;
            
                while (low <= high) {
                    let mid = Math.floor((low + high) / 2);
            
                    if (newOption.strikePrice < stockOptions[mid].strikePrice) {
                        high = mid - 1;
                    } else if (newOption.strikePrice > stockOptions[mid].strikePrice) {
                        low = mid + 1;
                    } else {
                        // If strikePrice is the same, insert right after
                        stockOptions.splice(mid + 1, 0, newOption);
                        return;
                    }
                } 
                stockOptions.splice(low, 0, newOption); // If not found, insert at the right spot
            }
            for(let data of action.payload.optionsMap){
                if(!(data.streamer in state.stocks[data.stockSymbol].stockOptionsMap)){
                    state.stocks[data.stockSymbol].stockOptionsMap[data.streamer] = {
                        strikePrice:parseFloat(data.strikePrice),
                        expirationDate:data.expiration,
                        tastySymbol:data.tastySymbol,
                        activeListeners:[action.payload.listenType],
                        eventTypes:{
                            Trade:null,
                            Summary:null,
                            Quote:null,
                            Profile:null,
                            Greeks:null
                        }
                    }
                    insertUsingBinarySearch(state.stocks[data.stockSymbol].stockOptions, {
                        symbol: data.streamer,
                        strikePrice: parseFloat(data.strikePrice)
                    });
                }
                else{
                    if(!state.stocks[data.stockSymbol].stockOptionsMap[data.streamer].activeListeners.includes(action.payload.listenType)){
                        state.stocks[data.stockSymbol].stockOptionsMap[data.streamer].activeListeners.push(action.payload.listenType)
                    }
                }
               
            }
        },

        updateStockOptionsMap:(state, action)=>{   
            for(let symbol in action.payload.toUpdate){
                let stockOption = action.payload.toUpdate[symbol]
                for(let type in stockOption){
                    state.stocks[stockOption.stockSymbol].stockOptionsMap[symbol].eventTypes[type] = stockOption[type]
                }
            }
        },


        overWriteOrders:(state, action)=>{ 
            for(let stockId in action.payload.orders){
                    let orders = []
                    for(let orderId in action.payload.orders[stockId]){
                        orders.push(orderId)
                        state.stocks[stockId].orderData[orderId] = action.payload.orders[stockId][orderId]
                    }
                    state.stocks[stockId].orders = orders
            }
            for(let stockId in action.payload.stockShares){
                if(!state.stocks[stockId].activeListeners.includes(action.payload.listenType)){
                    state.stocks[stockId].activeListeners.push(action.payload.listenType)
                }
                state.stocks[stockId].shares = action.payload.stockShares[stockId].shares
                state.stocks[stockId].averageCostBasis = action.payload.stockShares[stockId].averageCostBasis
            }
            
        },

        
        removeStockOptions:(state, action)=>{ 
            for(let options of action.payload.options){
                if(state.stocks[options.stockSymbol].stockOptionsMap[options.symbol].activeListeners.length == 1){
                    const index = state.stocks[options.stockSymbol].stockOptions.findIndex(stockOption => stockOption.symbol == options.symbol);
                    if (index !== -1) {
                        state.stocks[options.stockSymbol].stockOptions.splice(index, 1);
                    }
                    delete state.stocks[options.stockSymbol].stockOptionsMap[options.symbol]
                }
                else{
                    let activeListeners = state.stocks[options.stockSymbol].stockOptionsMap[options.symbol].activeListeners
                    state.stocks[options.stockSymbol].stockOptionsMap[options.symbol].activeListeners = activeListeners.splice(activeListeners.findIndex(item => item != action.payload.listenType), 1); 
                }
            }
           
        },

        removeStockSymbol:(state, action)=>{ 
            for(let stock of action.payload.stocks){
                if(state.stocks[stock].activeListeners.length == 1){
                    delete state.stocks[stock]
                    const index = state.stocks.activeStocks.indexOf(stock);
                    if (index !== -1) {
                        state.stocks.activeStocks.splice(index, 1);
                    }
                }
                else{
                    state.stocks[stock].activeListeners.splice( state.stocks[stock].activeListeners.findIndex(item => item == action.payload.listenType), 1); 
                }
            }
           
        },
      
     
        resetStockOptionsMap:(state, action)=>{   
            state.currentStockView.stockOptionsMap = {}
        },
        alterStockBothMapAndArray:(state, action)=>{   
            state.currentStockView.stockOptions = action.payload.array
            for(let eventSymbol of action.payload.toUpdate || []){
                state.currentStockView.stockOptionsMap[eventSymbol] =  action.payload.map[eventSymbol]
            }
        },
    }
})

export const {
    resetCurrentStock,
    addStockSymbol,
    updateStockSymbolDescription,
    removeStockSymbol,
    removeStockOptions,
    updateStockSocketData,
    overWriteOrders,
    updateStockExpiration,
    addStockOptionsMap,
    updateStockOptions,
    updateStockOptionsMap,
    updateStockBothMapAndArray,
    resetStockOptionsMap
} = stockSlice.actions

export default stockSlice.reducer