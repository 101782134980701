import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Report = (props) => {
    const navigate = useNavigate();
    const report = useSelector(state => state.mainapp.performanceSlice?.report)

    return (
        <>

            <div className='report-feed'>
                <h3 style={{ marginBottom: "30px" }}>Report</h3>
                {
                    report.map((item, index) => {
                        return (
                            <div className='report-item'>
                                <div className='report-item-text'>
                                    <h3>{item.amount}</h3>
                                    <span>{item.description}</span>
                                    <p>
                                        {item.date}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </>
    );

}

export default Report;